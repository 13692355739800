import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { clarity } from 'react-microsoft-clarity'
import useCookie from './useCookie'

const useGaTracker = () => {
    const [cookiesPreferences] = useCookie(
        'cookiesPreferences',
        JSON.stringify({
            essential: 'yes',
            analytics: 'no',
            functional: 'no',
            marketing: 'no',
        })
    )
    const location = useLocation()
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            if (JSON.parse(cookiesPreferences).analytics === 'yes') {
                ReactGA.initialize('G-H3PHTX0D1D')
                clarity.init('j0pbji01fc')
            }
        }
        setInitialized(true)
    }, [cookiesPreferences])

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname })
        }
    }, [initialized, location])
}

export default useGaTracker
