import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './SelectInput.module.scss'
import icoDropdownArrow from '../../../assets/images/dropdown-arrow.svg'

import { useTranslation } from 'react-i18next'

export const SelectInput = React.memo(
    ({ label, error, onChangeInput, options, value }) => {
        const { t } = useTranslation()
        const [isOpen, setIsOpen] = useState(false)
        const selectRef = useRef(null)

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [])

        const handleClickOutside = (event) => {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }

        const handleSelect = (option) => {
            onChangeInput(option)
            setIsOpen(false)
        }

        return (
            <React.Fragment>
                <div
                    className={styles['select-input-container']}
                    ref={selectRef}
                >
                    <input
                        className={`${styles['form-control']} ${
                            styles['select-input-container__input']
                        } ${error ? styles['error'] : ''}`}
                        type="text"
                        value={value}
                        onChange={() => {}}
                        // onChange={(e) => onChangeInput(e.target.value)}
                        placeholder={label}
                        onClick={() => setIsOpen(!isOpen)}
                    />

                    <img
                        loading="lazy"
                        src={icoDropdownArrow}
                        alt="dropdown arrow"
                        className={`${styles['arrow-icon']} ${
                            isOpen ? styles['rotate'] : ''
                        }`}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                    {isOpen && (
                        <div
                            className={
                                styles['select-input-container__dropdown']
                            }
                        >
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleSelect(option)}
                                    className={
                                        styles[
                                            'select-input-container__dropdown__option'
                                        ]
                                    }
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {error && (
                    <div className={`${styles['select-input-error']}`}>
                        {t(`errors:${error}`)}
                    </div>
                )}
            </React.Fragment>
        )
    }
)

SelectInput.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    onChangeInput: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.string.isRequired,
}
