import React, { useMemo } from 'react'
import styles from './UseCaseHeader.module.scss'
import { useCasesAll } from '../../../constants/useCases'
import { useParams } from 'react-router-dom'
import { useCurrentSize } from '../../../providers/current-size-context'
import { useTranslation } from 'react-i18next'
import StarkdataLogoBig from '../../../assets/images/StarkdataLogoBig.png'

export const UseCaseHeader = () => {
    const { isWidthGreaterEqualThan800 } = useCurrentSize()
    const params = useParams()
    const { t } = useTranslation()
    const useCases = useMemo(
        () => [...useCasesAll].filter((el) => el.id === params.id),
        [params.id]
    )
    return (
        <div className="row mx-0 w-100 position-relative">
            <div
                className="col d-flex flex-column justify-content-center align-items-center"
                style={{
                    padding: `0rem 2rem`,
                    marginTop: '10rem',
                }}
            >
                <div
                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                    style={{ maxWidth: '128rem' }}
                >
                    <div
                        className={
                            isWidthGreaterEqualThan800
                                ? 'col d-flex flex-column justify-content-center align-items-center'
                                : 'col-12 d-flex flex-column justify-content-center align-items-center'
                        }
                        style={{
                            padding: `4rem 0`,
                            background: 'transparent',
                            zIndex: 0,
                        }}
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span
                                    className={`${styles['use-case-header-container__title']}`}
                                >
                                    {useCases[0].title}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '3rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['use-case-header-container__industry-text']}`}
                                >
                                    {t(`platform.${useCases[0].industry}`)}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span
                                    className={`${styles['use-case-header-container__subTitle']}`}
                                >
                                    {useCases[0].description}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '5rem' }}
                    ></div>
                    <div
                        className={
                            isWidthGreaterEqualThan800
                                ? 'col-6 px-0 d-flex justify-content-center align-items-center'
                                : 'col-12 px-0 d-flex justify-content-center align-items-center'
                        }
                        style={{
                            background: 'transparent',
                            zIndex: 0,
                            padding: `4rem 0`,
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '40rem',
                            }}
                        >
                            <img
                                src={useCases[0].imageBig}
                                alt=""
                                loading="lazy"
                                style={{
                                    zIndex: '1',
                                    width: '100%',
                                    position: 'relative',
                                    height: '40rem',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    boxShadow:
                                        'rgb(206, 226, 241) 0px 0px 10px 0px',
                                }}
                                className={`${styles['use-case-header-container__image']}`}
                            />
                            <img
                                src={StarkdataLogoBig}
                                alt=""
                                loading="lazy"
                                style={{
                                    zIndex: '0',
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    height: '50%',
                                    transform: 'translate(35%, -35%)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
