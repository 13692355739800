import React, { useState } from 'react';
import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import imageMockupDemo from '../../../assets/images/imageMockupDemo.png';
import styles from './ContactUs.module.scss';
import { useTranslation } from 'react-i18next';
import { TypeInput } from '../../common/TypeInput/TypeInput';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import { PrimaryButton } from '../../common/PrimaryButton/PrimaryButton';
import { useCurrentSize } from '../../../providers/current-size-context';
import { emailRegex } from '../../../utils/error';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { SelectInput } from '../../common/SelectInput/SelectInput';
import axios from 'axios';

export const ContactUs = () => {
    const { t } = useTranslation();
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize();
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [details, setDetails] = useState('');
    const [email, setEmail] = useState('');
    const [isContacted, setIsContacted] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [isCheckboxSelectedForMarketing, setIsCheckboxSelectedForMarketing] =
        useState(false);
    const handleContactUs = () => {
        if (!firstName.trim()) {
            setShowWarning('Fill in the first name');
            return false;
        } else if (!lastName.trim()) {
            setShowWarning('Fill in the last name');
            return false;
        } else if (!email.trim()) {
            setShowWarning('Fill in the email');
            return false;
        } else if (!emailRegex.test(email)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!details.trim()) {
            setShowWarning('Fill in the details');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms');
            return false;
        }

        setShowWarning(false);
        setIsContacted(true);
        return true;
    };
    const handleSendEmail = async (
        firstName,
        lastName,
        email,
        selectedValue,
        details
    ) => {
        var bodyFormData = new FormData();
        // Set the SendGrid API key
        // sgMail.setApiKey('YOUR_SENDGRID_API_KEY')

        // Prepare the email content
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Contact Us',
            text: `Hi, my name is ${firstName} ${lastName}, my email is ${email}, and I am interested in the industry of ${selectedValue}. ${details}`
        };
        bodyFormData.append('name', firstName + ' ' + lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        // Send the email
        // sgMail
        //     .send(msg)
        //     .then((response) => {
        //         console.log('Email sent successfully!', response)
        //     })
        //     .catch((error) => {
        //         console.error('There was an error sending the email.', error)
        //     })
    };

    const [showWarning, setShowWarning] = useState(false);
    return (
        <div className={`${styles['contact-us-container']}`}>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '2rem',
                    paddingTop: 'calc(8rem + 4rem)',
                    maxWidth: '128rem'
                }}
            >
                <MailchimpSubscribe
                    url={
                        'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                    }
                    render={({ subscribe }) => (
                        <>
                            {isContacted && (
                                <>
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col-auto px-0">
                                                <span
                                                    className={`${styles['contact-us-container__thank-you-title']}`}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitleThx'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-center">
                                            <div
                                                className="col-auto px-0"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span
                                                    className={`${styles['contact-us-container__thank-you']}`}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitle5'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isContacted && (
                                <>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={
                                                    isWidthGreaterEqualThan800
                                                        ? 'col px-0 d-flex flex-column justify-content-start align-items-center'
                                                        : 'col-12 px-0 d-flex flex-column justify-content-start align-items-center'
                                                }
                                                style={{
                                                    marginBottom:
                                                        isWidthGreaterEqualThan800
                                                            ? '0'
                                                            : '5rem'
                                                }}
                                            >
                                                <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                                    <div className="col-auto px-0">
                                                        <span
                                                            className={`${styles['contact-us-container__subTitle']}`}
                                                        >
                                                            {t(
                                                                'contactUs.title1'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['contact-us-container__subTitle']}`}
                                                            style={{
                                                                color: '#ff8900'
                                                            }}
                                                        >
                                                            {t(
                                                                'contactUs.title2'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['contact-us-container__industry-text']}`}
                                                        >
                                                            {t(
                                                                'contactUs.subTitle'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                                    <div className="col-auto px-0">
                                                        <span
                                                            className={`${styles['contact-us-container__description']}`}
                                                        >
                                                            {t(
                                                                'contactUs.description1'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['contact-us-container__description']}`}
                                                            style={{
                                                                color: '#2c3c8d',
                                                                fontFamily:
                                                                    'VartaBold'
                                                            }}
                                                        >
                                                            {t(
                                                                'contactUs.description2'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['contact-us-container__description']}`}
                                                        >
                                                            {t(
                                                                'contactUs.description3'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['contact-us-container__description']}`}
                                                            style={{
                                                                color: '#2c3c8d',
                                                                fontFamily:
                                                                    'VartaBold'
                                                            }}
                                                        >
                                                            {t(
                                                                'contactUs.description4'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['contact-us-container__description']}`}
                                                        >
                                                            {t(
                                                                'contactUs.description5'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <img
                                                            loading="lazy"
                                                            src={
                                                                imageMockupDemo
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                transform:
                                                                    'scale(1.4)'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {isWidthGreaterEqualThan800 && (
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '5rem' }}
                                                ></div>
                                            )}

                                            <div
                                                className={
                                                    isWidthGreaterEqualThan800
                                                        ? 'col px-0'
                                                        : 'col-12 px-0'
                                                }
                                            >
                                                <>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    ></div>
                                                    <div className="col px-0">
                                                        {showWarning && (
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    maxWidth:
                                                                        '50rem',
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0 d-flex">
                                                                    <span
                                                                        className={`${styles['contact-us-container__warning']}`}
                                                                    >
                                                                        {
                                                                            showWarning
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.firstName'
                                                                    )}
                                                                    name={
                                                                        'firstName'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        firstName
                                                                    }
                                                                    onChangeInput={
                                                                        setFirstName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.lastName'
                                                                    )}
                                                                    name={
                                                                        'lastName'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        lastName
                                                                    }
                                                                    onChangeInput={
                                                                        setLastName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.email'
                                                                    )}
                                                                    name={
                                                                        'email'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        email
                                                                    }
                                                                    onChangeInput={
                                                                        setEmail
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div
                                                                className="col px-0"
                                                                style={{
                                                                    width: '30rem'
                                                                }}
                                                            >
                                                                <SelectInput
                                                                    label={t(
                                                                        'bookingModal.inputs.industry'
                                                                    )}
                                                                    name={
                                                                        'industry'
                                                                    }
                                                                    onChangeInput={(
                                                                        value
                                                                    ) =>
                                                                        setSelectedValue(
                                                                            value
                                                                        )
                                                                    }
                                                                    options={[
                                                                        'Banking',
                                                                        'Insurance',
                                                                        'Healthcare',
                                                                        'Retail',
                                                                        'Manufacturing',
                                                                        'Other'
                                                                    ]}
                                                                    value={
                                                                        selectedValue
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem',
                                                                height: '30rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.details'
                                                                    )}
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    name={
                                                                        'details'
                                                                    }
                                                                    value={
                                                                        details
                                                                    }
                                                                    inputType={
                                                                        'container'
                                                                    }
                                                                    onChangeInput={
                                                                        setDetails
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100 d-flex align-items-start">
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    marginTop:
                                                                        '0.4rem'
                                                                }}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    className={
                                                                        styles[
                                                                            'contact-us-container__checkbox'
                                                                        ]
                                                                    }
                                                                    src={
                                                                        isCheckboxSelected
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    onClick={() =>
                                                                        setIsCheckboxSelected(
                                                                            !isCheckboxSelected
                                                                        )
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms1'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                            style={{
                                                                                color: 'rgba(6, 164, 231, 1)',
                                                                                cursor: 'pointer',
                                                                                textDecoration:
                                                                                    'underline'
                                                                            }}
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    privacyPolicy,
                                                                                    '_blank'
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms2'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        {/* <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms3'
                                                                            )}
                                                                        </span> */}
                                                                        {/* <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                            style={{
                                                                                color: 'rgba(6, 164, 231, 1)',
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    privacyPolicy,
                                                                                    '_blank'
                                                                                )
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms4'
                                                                            )}
                                                                        </span> */}
                                                                        {/* <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms5'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                            style={{
                                                                                color: 'rgba(6, 164, 231, 1)',
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    privacyPolicy,
                                                                                    '_blank'
                                                                                )
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms6'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms7'
                                                                            )}
                                                                        </span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100 d-flex align-items-start"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    marginTop:
                                                                        '0.4rem'
                                                                }}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    className={
                                                                        styles[
                                                                            'contact-us-container__checkbox'
                                                                        ]
                                                                    }
                                                                    src={
                                                                        isCheckboxSelectedForMarketing
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    onClick={() =>
                                                                        setIsCheckboxSelectedForMarketing(
                                                                            !isCheckboxSelectedForMarketing
                                                                        )
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['contact-us-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms8'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                                            <div className="col-auto px-0">
                                                                <PrimaryButton
                                                                    text={t(
                                                                        'button.submit'
                                                                    )}
                                                                    onClick={() => {
                                                                        if (
                                                                            handleContactUs()
                                                                        ) {
                                                                            subscribe(
                                                                                {
                                                                                    EMAIL: email,
                                                                                    FNAME: firstName,
                                                                                    LNAME: lastName,
                                                                                    MMERGE6:
                                                                                        selectedValue,
                                                                                    MMERGE3:
                                                                                        details,
                                                                                    MMERGE11:
                                                                                        'English',
                                                                                    'group[2089][1]':
                                                                                        undefined,
                                                                                    'group[2089][2]':
                                                                                        isCheckboxSelectedForMarketing
                                                                                            ? true
                                                                                            : undefined,
                                                                                    'group[2089][4]': true,
                                                                                    'group[2089][8]':
                                                                                        undefined,
                                                                                    'group[2089][16]':
                                                                                        undefined,
                                                                                    'group[2089][32]':
                                                                                        undefined
                                                                                }
                                                                            );
                                                                            handleSendEmail(
                                                                                firstName,
                                                                                lastName,
                                                                                email,
                                                                                selectedValue,
                                                                                details
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                ></MailchimpSubscribe>
            </div>
        </div>
    );
};
