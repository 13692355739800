import React, { useEffect, useState } from 'react'
import styles from './AboutUs.module.scss'
import StarkdataLogoBig from '../../../assets/images/StarkdataLogoBig.png'
import imagePaulo from '../../../assets/images/imagePaulo.png'
import imageAboutUs from '../../../assets/images/imageAboutUs.jpg'

import { useTranslation } from 'react-i18next'
import useOnScreen from '../../../hooks/useOnScreen'
import backgroundSection from '../../../assets/images/backgroundSection.svg'
import { useCurrentSize } from '../../../providers/current-size-context'

export const AboutUs = React.memo(() => {
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize()
    const { t } = useTranslation()
    const aboutUsRef = React.useRef()
    const aboutUsVisible = useOnScreen(aboutUsRef)
    const quoteRef = React.useRef()
    const quoteVisible = useOnScreen(quoteRef)
    const [showQuote, setShowQuote] = useState(false)

    useEffect(() => {
        if (aboutUsVisible) {
            setTimeout(() => {
                setShowQuote(true)
            }, 1500)
        }
    }, [aboutUsVisible])

    return (
        <div className={`${styles['aboutUsContainer']}`}>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '0 2rem',
                    paddingTop: 'calc(8rem + 4rem)',
                    maxWidth: '128rem',
                }}
            >
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col px-0 d-flex flex-column justify-content-center align-items-center'
                            : 'col-12 px-0 d-flex flex-column justify-content-center align-items-center'
                    }
                >
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div
                            className={
                                isWidthGreaterEqualThan800
                                    ? 'col px-0 d-flex justify-content-start align-items-center'
                                    : 'col px-0 d-flex flex-column justify-content-start align-items-start'
                            }
                        >
                            <span
                                className={`${styles['aboutUsContainer__subTitle']}`}
                                style={{
                                    whiteSpace: 'nowrap',
                                    color: '#2c3c8d',
                                }}
                            >
                                {t('aboutUs.title1')}
                            </span>
                            &nbsp; &nbsp;&nbsp;
                            <span
                                className={`${styles['aboutUsContainer__subTitle']}`}
                                style={{
                                    color: '#ff8900',
                                    opacity: 0,
                                    animation:
                                        'appearTextFromRight 0.3s ease-in-out forwards 0.3s',
                                }}
                            >
                                {t('aboutUs.title2')}
                            </span>
                        </div>
                    </div>

                    <div className="row mx-0 w-100">
                        <div className="col px-0 d-flex justify-content-start align-items-center">
                            <span
                                className={`${styles['aboutUsContainer__description']}`}
                            >
                                {t('aboutUs.values.content')}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="col-auto px-0 position-relative"
                    style={{ width: '5rem' }}
                ></div>
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col px-0 position-relative'
                            : 'col-12 px-0 position-relative'
                    }
                    style={{
                        height: '40rem',
                        marginTop: isWidthGreaterEqualThan800 ? '0' : '6rem',
                        borderRadius: '2rem',
                        background: `url(${imageAboutUs})`,
                        backgroundSize: 'cover',
                    }}
                >
                    <img
                        loading="lazy"
                        src={StarkdataLogoBig}
                        alt=""
                        style={{
                            zIndex: '-1',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            height: '50%',
                            transform: 'translate(35%, -35%)',
                        }}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '5rem 2rem',
                    paddingBottom: '10rem',
                }}
            >
                <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem', maxWidth: '128rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-center">
                            <span
                                className={`${styles['aboutUsContainer__missionTitle']}`}
                            >
                                {t('aboutUs.mission.title')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            maxWidth: '128rem',
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-center">
                            <span
                                className={`${styles['aboutUsContainer__missionText']}`}
                            >
                                {t('aboutUs.mission.text')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-centera"
                style={{
                    padding: '0 2rem',
                    background: `url(${backgroundSection})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="col-auto px-0">
                    <div
                        className="row mx-0 w-100"
                        style={{
                            paddingTop: isWidthGreaterEqualThan800
                                ? '5rem'
                                : '10rem',
                            marginBottom: '2rem',
                        }}
                    >
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: '2rem',
                                    maxWidth: '128rem',
                                }}
                            >
                                <div className="col px-0 d-flex justify-content-center">
                                    <span
                                        className={`${styles['aboutUsContainer__missionTitle']}`}
                                        style={{ color: '#fff' }}
                                    >
                                        {t('aboutUs.values.title')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            isWidthGreaterEqualThan600
                                ? 'row mx-0 w-100'
                                : 'row mx-0 w-100 d-flex flex-column align-items-center justify-content-center'
                        }
                        style={{
                            maxWidth: '128rem',
                            marginBottom: '10rem',
                        }}
                        ref={aboutUsRef}
                    >
                        <div
                            className={
                                isWidthGreaterEqualThan600 ? 'col' : 'col-auto'
                            }
                            style={{
                                width: isWidthGreaterEqualThan600
                                    ? 'unset'
                                    : '100%',
                                background: '#fff',
                                minHeight: '15rem',
                                borderRadius: '12px',
                                padding: '2rem',

                                boxShadow: ' 0px 2px 6px 0px rgba(0,0,0,0.15)',
                                opacity: 0,
                                animation: aboutUsVisible
                                    ? 'appearImage 0.6s ease-in-out forwards'
                                    : 'none',
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__contentTitle']}`}
                                    >
                                        {t('aboutUs.topic1.title')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__content']}`}
                                    >
                                        {t('aboutUs.topic1.content')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isWidthGreaterEqualThan600 && (
                            <div
                                className="col-auto px-0 d-flex align-items-center"
                                style={{ width: '4rem' }}
                            >
                                {/* <img
                                    src={icoSnake}
                                    style={{
                                        width: '100%',
                                        filter: 'brightness(0) saturate(100%) invert(59%) sepia(20%) saturate(5300%) hue-rotate(359deg) brightness(102%) contrast(104%)',
                                        opacity: 0,
                                        animation: aboutUsVisible
                                            ? 'appearImage 0.6s ease-in-out forwards 0.3s'
                                            : 'none',
                                    }}
                                /> */}
                            </div>
                        )}
                        <div
                            className={
                                isWidthGreaterEqualThan600 ? 'col' : 'col-auto'
                            }
                            style={{
                                width: isWidthGreaterEqualThan600
                                    ? 'unset'
                                    : '100%',
                                background: '#fff',
                                marginTop: isWidthGreaterEqualThan600
                                    ? '0'
                                    : '4rem',
                                minHeight: '15rem',
                                borderRadius: '12px',
                                padding: '2rem',

                                boxShadow: ' 0px 2px 6px 0px rgba(0,0,0,0.15)',
                                opacity: 0,
                                animation: aboutUsVisible
                                    ? 'appearImage 0.6s ease-in-out forwards 0.3s'
                                    : 'none',
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__contentTitle']}`}
                                    >
                                        {t('aboutUs.topic2.title')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__content']}`}
                                    >
                                        {t('aboutUs.topic2.content')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isWidthGreaterEqualThan600 && (
                            <div
                                className="col-auto px-0 d-flex align-items-center"
                                style={{ width: '4rem' }}
                            >
                                {/* <img
                                    src={icoSnake}
                                    style={{
                                        width: '100%',
                                        filter: 'brightness(0) saturate(100%) invert(59%) sepia(20%) saturate(5300%) hue-rotate(359deg) brightness(102%) contrast(104%)',
                                        opacity: 0,
                                        animation: aboutUsVisible
                                            ? 'appearImage 0.6s ease-in-out forwards 0.6s'
                                            : 'none',
                                    }}
                                /> */}
                            </div>
                        )}
                        <div
                            className={
                                isWidthGreaterEqualThan600 ? 'col' : 'col-auto'
                            }
                            style={{
                                width: isWidthGreaterEqualThan600
                                    ? 'unset'
                                    : '100%',
                                background: '#fff',
                                marginTop: isWidthGreaterEqualThan600
                                    ? '0'
                                    : '4rem',
                                minHeight: '15rem',
                                borderRadius: '12px',
                                padding: '2rem',

                                boxShadow: ' 0px 2px 6px 0px rgba(0,0,0,0.15)',
                                opacity: 0,
                                animation: aboutUsVisible
                                    ? 'appearImage 0.6s ease-in-out forwards 0.6s'
                                    : 'none',
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__contentTitle']}`}
                                    >
                                        {t('aboutUs.topic3.title')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['aboutUsContainer__content']}`}
                                    >
                                        {t('aboutUs.topic3.content')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col px-0">
                    <hr
                        style={{
                            color: '#f0f0f0',
                            opacity: '1',
                        }}
                    />
                </div>
            </div> */}
            <div
                className="row mx-0 w-100"
                style={{
                    padding: isWidthGreaterEqualThan800
                        ? '10rem 4rem'
                        : '10rem 6rem',
                    maxWidth: '128rem',
                }}
                ref={quoteRef}
            >
                <div className="col px-0 d-flex flex-column justify-content center align-items-center">
                    <div
                        className="row mx-0 w-100"
                        style={{
                            marginBottom: '2rem',
                            opacity: 0,
                            animation:
                                quoteVisible && showQuote
                                    ? 'appearTextFromLeft 0.3s ease-in-out forwards'
                                    : 'none',
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['aboutUsContainer__quote']}`}
                                style={{
                                    fontSize: isWidthGreaterEqualThan800
                                        ? '3.2rem'
                                        : '2.5rem',
                                }}
                            >
                                &#8220;
                                {t('aboutUs.quote')}
                                &#8222;
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-75 d-flex justify-content-end align-items-center"
                        style={{
                            opacity: 0,
                            animation:
                                quoteVisible && showQuote
                                    ? 'appearTextFromRight 0.3s ease-in-out forwards'
                                    : 'none',
                        }}
                    >
                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                            <div
                                style={{
                                    backgroundImage: `url(${imagePaulo})`,
                                    backgroundSize: 'cover',
                                    width: '13rem',
                                    height: '13rem',
                                    borderRadius: '50%',
                                    marginRight: '2rem',
                                    aspectRatio: '1/1',
                                }}
                            />
                            <span
                                className={`${styles['aboutUsContainer__quoteAuthor']}`}
                            >
                                {t('aboutUs.quoteAuthor')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
