import React, { useState } from 'react';
import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import styles from './JoinEmailList.module.scss';
import { useTranslation } from 'react-i18next';
import { TypeInput } from '../../common/TypeInput/TypeInput';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import { PrimaryButton } from '../../common/PrimaryButton/PrimaryButton';
import { useCurrentSize } from '../../../providers/current-size-context';
import { emailRegex } from '../../../utils/error';
import imageRequestGuide from '../../../assets/images/imageRequestGuide.png';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import axios from 'axios';

export const JoinEmailList = () => {
    const { t } = useTranslation();
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize();
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isCheckboxNewsletterSelected, setIsCheckboxNewsletterSelected] =
        useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isContacted, setIsContacted] = useState(false);
    const handleJoinEmailList = () => {
        if (!firstName.trim()) {
            setShowWarning('Fill in the first name');
            return false;
        } else if (!lastName.trim()) {
            setShowWarning('Fill in the last name');
            return false;
        } else if (!email.trim()) {
            setShowWarning('Fill in the email');
            return false;
        } else if (!emailRegex.test(email)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms');
            return false;
        }
        setShowWarning(false);
        setIsContacted(true);
        return true;
    };
    const handleSendEmail = async (
        firstName,
        lastName,
        email,
        selectedValue,
        details
    ) => {
        var bodyFormData = new FormData();
        // Set the SendGrid API key
        // sgMail.setApiKey('YOUR_SENDGRID_API_KEY')

        // Prepare the email content
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Join Email List',
            text: `Hi, my name is ${firstName} ${lastName}, my email is ${email}. ${details}`
        };
        bodyFormData.append('name', firstName + ' ' + lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        // Send the email
        // sgMail
        //     .send(msg)
        //     .then((response) => {
        //         console.log('Email sent successfully!', response)
        //     })
        //     .catch((error) => {
        //         console.error('There was an error sending the email.', error)
        //     })
    };

    const [showWarning, setShowWarning] = useState(false);
    return (
        <div className={`${styles['join-email-list-container']}`}>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '2rem',
                    paddingTop: 'calc(8rem + 4rem)',
                    maxWidth: '128rem'
                }}
            >
                <MailchimpSubscribe
                    url={
                        'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                    }
                    render={({ subscribe }) => (
                        <>
                            {isContacted && (
                                <>
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col-auto px-0">
                                                <span
                                                    className={`${styles['join-email-list-container__thank-you-title']}`}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitleThx'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-center">
                                            <div
                                                className="col-auto px-0"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span
                                                    className={`${styles['join-email-list-container__thank-you']}`}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitle52'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!isContacted && (
                                <>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={
                                                    isWidthGreaterEqualThan800
                                                        ? 'col px-0 d-flex flex-column justify-content-start align-items-center'
                                                        : 'col-12 px-0 d-flex flex-column justify-content-start align-items-center'
                                                }
                                                style={{
                                                    marginBottom:
                                                        isWidthGreaterEqualThan800
                                                            ? '0'
                                                            : '5rem'
                                                }}
                                            >
                                                <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                                    <div className="col-auto px-0">
                                                        <span
                                                            className={`${styles['join-email-list-container__subTitle']}`}
                                                            style={{
                                                                color: '#36a4e7'
                                                            }}
                                                        >
                                                            {t(
                                                                'joinEmailList.title1'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['join-email-list-container__industry-text']}`}
                                                        >
                                                            {t(
                                                                'joinEmailList.title2'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col-auto px-0">
                                                        <span
                                                            className={`${styles['join-email-list-container__description']}`}
                                                        >
                                                            {t(
                                                                'joinEmailList.description12'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        {showWarning && (
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    maxWidth:
                                                                        '50rem',
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0 d-flex">
                                                                    <span
                                                                        className={`${styles['join-email-list-container__warning']}`}
                                                                    >
                                                                        {
                                                                            showWarning
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.firstName'
                                                                    )}
                                                                    name={
                                                                        'firstName'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        firstName
                                                                    }
                                                                    onChangeInput={
                                                                        setFirstName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.lastName'
                                                                    )}
                                                                    name={
                                                                        'lastName'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        lastName
                                                                    }
                                                                    onChangeInput={
                                                                        setLastName
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    label={t(
                                                                        'bookingModal.inputs.email'
                                                                    )}
                                                                    name={
                                                                        'email'
                                                                    }
                                                                    isRequired={
                                                                        true
                                                                    }
                                                                    value={
                                                                        email
                                                                    }
                                                                    onChangeInput={
                                                                        setEmail
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 w-100 d-flex align-items-start">
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    marginTop:
                                                                        '0.4rem'
                                                                }}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    className={
                                                                        styles[
                                                                            'join-email-list-container__checkbox'
                                                                        ]
                                                                    }
                                                                    src={
                                                                        isCheckboxSelected
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    onClick={() =>
                                                                        setIsCheckboxSelected(
                                                                            !isCheckboxSelected
                                                                        )
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['join-email-list-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms1'
                                                                            )}
                                                                        </span>
                                                                        <span
                                                                            className={`${styles['join-email-list-container__terms']}`}
                                                                            style={{
                                                                                color: 'rgba(6, 164, 231, 1)',
                                                                                cursor: 'pointer',
                                                                                textDecoration:
                                                                                    'underline'
                                                                            }}
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    privacyPolicy,
                                                                                    '_blank'
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'bookingModal.terms2'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100 d-flex align-items-start">
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    marginTop:
                                                                        '0.4rem'
                                                                }}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    className={
                                                                        styles[
                                                                            'join-email-list-container__checkbox'
                                                                        ]
                                                                    }
                                                                    src={
                                                                        isCheckboxNewsletterSelected
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    onClick={() =>
                                                                        setIsCheckboxNewsletterSelected(
                                                                            !isCheckboxNewsletterSelected
                                                                        )
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['join-email-list-container__terms']}`}
                                                                        >
                                                                            {t(
                                                                                'joinEmailList.newsletter2'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                                            <div className="col-auto px-0">
                                                                <PrimaryButton
                                                                    text={t(
                                                                        'button.signUp'
                                                                    )}
                                                                    onClick={() => {
                                                                        if (
                                                                            handleJoinEmailList()
                                                                        ) {
                                                                            subscribe(
                                                                                {
                                                                                    EMAIL: email,
                                                                                    FNAME: firstName,
                                                                                    LNAME: lastName,
                                                                                    MMERGE6:
                                                                                        undefined,
                                                                                    MMERGE3:
                                                                                        undefined,
                                                                                    MMERGE11:
                                                                                        'English',
                                                                                    'group[2089][1]':
                                                                                        isCheckboxNewsletterSelected
                                                                                            ? true
                                                                                            : undefined,
                                                                                    'group[2089][2]': true,
                                                                                    'group[2089][4]':
                                                                                        undefined,
                                                                                    'group[2089][8]':
                                                                                        undefined,
                                                                                    'group[2089][16]': true,
                                                                                    'group[2089][32]':
                                                                                        undefined
                                                                                }
                                                                            );
                                                                            handleSendEmail(
                                                                                firstName,
                                                                                lastName,
                                                                                email
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {isWidthGreaterEqualThan800 && (
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '5rem' }}
                                                ></div>
                                            )}

                                            <div
                                                className={
                                                    isWidthGreaterEqualThan800
                                                        ? 'col'
                                                        : 'col-12'
                                                }
                                                style={{
                                                    padding: '4rem',
                                                    boxShadow:
                                                        '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
                                                    borderRadius: '0.8rem'
                                                }}
                                            >
                                                <>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <span
                                                                className={`${styles['join-email-list-container__subTitle2']}`}
                                                            >
                                                                {t(
                                                                    'joinEmailList.title3'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                            >
                                                                {t(
                                                                    'joinEmailList.description22'
                                                                )}
                                                            </span>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    fontFamily:
                                                                        'VartaBold'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.description23'
                                                                )}
                                                            </span>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                            >
                                                                {t(
                                                                    'joinEmailList.description24'
                                                                )}
                                                            </span>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    fontFamily:
                                                                        'VartaBold'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.description25'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                                        <div className="col-auto px-0">
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    fontSize:
                                                                        '2.5rem'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.whatsInStore.title'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col-9 px-0">
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '1px',
                                                                    background:
                                                                        'rgb(54, 164, 231)'
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                            <div
                                                                style={{
                                                                    width: '0.5rem',
                                                                    height: '0.5rem',
                                                                    background:
                                                                        '#4c4c4c',
                                                                    borderRadius:
                                                                        '50%',
                                                                    marginRight:
                                                                        '1rem'
                                                                }}
                                                            ></div>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    color: '#969696'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.whatsInStore.list1'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                            <div
                                                                style={{
                                                                    width: '0.5rem',
                                                                    height: '0.5rem',
                                                                    background:
                                                                        '#4c4c4c',
                                                                    borderRadius:
                                                                        '50%',
                                                                    marginRight:
                                                                        '1rem'
                                                                }}
                                                            ></div>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    color: '#969696'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.whatsInStore.list2'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                            <div
                                                                style={{
                                                                    width: '0.5rem',
                                                                    height: '0.5rem',
                                                                    background:
                                                                        '#4c4c4c',
                                                                    borderRadius:
                                                                        '50%',
                                                                    marginRight:
                                                                        '1rem'
                                                                }}
                                                            ></div>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    color: '#969696'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.whatsInStore.list3'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                            <div
                                                                style={{
                                                                    width: '0.5rem',
                                                                    height: '0.5rem',
                                                                    background:
                                                                        '#4c4c4c',
                                                                    borderRadius:
                                                                        '50%',
                                                                    marginRight:
                                                                        '1rem'
                                                                }}
                                                            ></div>
                                                            <span
                                                                className={`${styles['join-email-list-container__description']}`}
                                                                style={{
                                                                    color: '#969696'
                                                                }}
                                                            >
                                                                {t(
                                                                    'joinEmailList.whatsInStore.list4'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                                            <img
                                                                loading="lazy"
                                                                src={
                                                                    imageRequestGuide
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: '100%',
                                                                    maxWidth:
                                                                        '50rem',
                                                                    borderRadius:
                                                                        '0.8rem'
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                ></MailchimpSubscribe>
            </div>
        </div>
    );
};
