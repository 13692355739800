import React from 'react'
import styles from './Solution.module.scss'
import icoSalesForecasting from '../../../assets/images/icoSalesForecast.svg'
import useOnScreen from '../../../hooks/useOnScreen'

export const Solution = ({ image, title, description, noIcon, delay }) => {
    const reference = React.useRef()
    const isVisible = useOnScreen(reference)
    return (
        <div
            className={`${styles['solution-container']}`}
            ref={reference}
            style={
                delay
                    ? {
                          opacity: 0,
                          animation: isVisible
                              ? `appearTextFromBottom 1s ease-in-out forwards ${delay}`
                              : 'none',
                      }
                    : undefined
            }
        >
            <div className="row mx-0 w-100" style={{ padding: '0' }}>
                <div className="col px-0">
                    {!noIcon && (
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <img
                                    loading="lazy"
                                    src={image || icoSalesForecasting}
                                    style={{
                                        height: '8rem',
                                    }}
                                    alt={'solution'}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        className="row mx-0 w-100"
                        style={{
                            marginBottom: '1rem',
                            height: !noIcon ? '10rem' : 'unset',
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['solution-container__title']}`}
                                style={{
                                    color: noIcon ? '#2c3c8d' : undefined,
                                }}
                            >
                                {title || '-'}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={` ${styles['solution-container__description']}`}
                            >
                                {description || '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
