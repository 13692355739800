import React from 'react'
import _ from 'lodash'
import styles from './CardCarousel.module.scss'
import { CardUseCase } from '../CardUseCase/CardUseCase'
import Scrollable from '../../navigation/Scrollable'

export const CardCarousel = ({
    cards,
    onClick,
    onClickText,
    justifyCenter,
}) => {
    return (
        <div className={`${styles['card-carousel-container']}`}>
            <Scrollable direction={'horizontal'} isDraggable hideScroll>
                <div className="w-100 h-100 d-flex justify-content-center">
                    <div
                        className={
                            justifyCenter
                                ? 'row d-flex w-100 justify-content-center'
                                : 'd-flex w-100'
                        }
                        style={{ padding: '2rem 0' }}
                    >
                        {cards.map((card, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        justifyCenter
                                            ? `col-auto ${styles['card-carousel-container__card']}`
                                            : `${styles['card-carousel-container__card']}`
                                    }
                                    style={{
                                        minWidth: '35rem',
                                        width: '35rem',
                                        padding: '2rem',
                                    }}
                                >
                                    <CardUseCase
                                        {...card}
                                        industry={card.industry}
                                        id={index + 1}
                                        onClick={() =>
                                            onClick(
                                                card,
                                                _.has(card, 'id')
                                                    ? card.id
                                                    : index
                                            )
                                        }
                                        onClickText={onClickText}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Scrollable>
        </div>
    )
}
