import React from "react";
import styles from "./Footer.module.scss";
import icoStarkdataLogoFooter from "../../../assets/images/logoStarkdata.svg";
import privacyPolicy from "../../../assets/documents/starkdataPrivacyPolicy.pdf";
import cookiePolicy from "../../../assets/documents/cookiePolicy.pdf";
import icoEnvelope from "../../../assets/images/icoMail.svg";
import icoLinkedIn from "../../../assets/images/icoLinkedIn.svg";
import icoMap from "../../../assets/images/icoMap.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCurrentSize } from "../../../providers/current-size-context";

export const Footer = React.memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isWidthGreaterEqualThan800, isWidthGreaterEqualThan1200 } =
    useCurrentSize();
  return (
    <div className={styles["footerContainer"]}>
      <div className="row mx-0 w-100 h-100 d-flex justify-content-between align-items-start">
        <div className="col px-0">
          <div className="row mx-0 w-100" style={{ marginBottom: "5rem" }}>
            <div className="col px-0">
              <img loading="lazy" src={icoStarkdataLogoFooter} alt={"logo"} />
            </div>
          </div>
          <div className="row mx-0 w-100" style={{ marginBottom: "2rem" }}>
            <div className="col px-0">
              <span
                className={styles["footerContainer__content"]}
                style={{
                  fontSize: isWidthGreaterEqualThan800 ? "1.4rem" : "2rem",
                }}
              >
                {t("footer.content")}
              </span>
            </div>
          </div>
          <div className="row mx-0 w-100">
            <div className="col px-0">
              <span
                className={styles["footerContainer__content"]}
                style={{
                  fontSize: isWidthGreaterEqualThan800 ? "1.4rem" : "2rem",
                }}
              >
                © Starkdata 2023. {t("footer.rights")}
              </span>
            </div>
          </div>
          {!isWidthGreaterEqualThan800 && (
            <>
              <div
                className="row mx-0 w-100"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={() => {
                    window.open(
                      "https://www.google.com/maps/place/Av.+Ant%C3%B3nio+Augusto+de+Aguiar+27,+1050-012+Lisboa/@38.7317716,-9.1546116,17z/data=!3m1!4b1!4m6!3m5!1s0xd19337369e9918d:0x1efaa658f016e65f!8m2!3d38.7317716!4d-9.1520367!16s%2Fg%2F11bw44jtvc?entry=ttu",
                      "_blank"
                    );
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoMap}
                    alt={"logo"}
                    style={{
                      height: isWidthGreaterEqualThan1200 ? "5rem" : "4rem",
                    }}
                  />
                </div>
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={(e) => {
                    window.location = "mailto:info@starkdata.ai";
                    e.preventDefault();
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoEnvelope}
                    alt={"logo"}
                    style={{
                      height: isWidthGreaterEqualThan1200 ? "5rem" : "4rem",
                    }}
                  />
                </div>
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={() => {
                    window.open(
                      "https://pt.linkedin.com/company/starkdata-ai",
                      "_blank"
                    );
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoLinkedIn}
                    alt={"logo"}
                    style={{
                      height: isWidthGreaterEqualThan1200 ? "5rem" : "4rem",
                    }}
                  />
                </div>
              </div>
              <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                <div className="col px-0">
                  <span className={styles["footerContainer__contentBold"]}>
                    {t("footer.address")}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        {isWidthGreaterEqualThan800 && (
          <>
            <div className="col-2 px-0"></div>
            <div className="col px-0">
              <div
                className="row mx-0 w-100"
                style={{ marginBottom: "1.5rem" }}
              >
                <div className="col px-0">
                  <span className={styles["footerContainer__contentTitle"]}>
                    {t("footer.product.title")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/platform/starkdata-apollo-retail");
                    }}
                  >
                    {t("footer.product.starkdataApollo")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/platform/starkdata-pegasus-banking");
                    }}
                  >
                    {t("footer.product.starkdataPegasus")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/platform/starkdata-mercury-healthcare");
                    }}
                  >
                    {t("footer.product.starkdataMercury")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/responsible-ai");
                    }}
                  >
                    {t("footer.product.responsibleAI")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col px-0">
              <div
                className="row mx-0 w-100"
                style={{ marginBottom: "1.5rem" }}
              >
                <div className="col px-0">
                  <span className={styles["footerContainer__contentTitle"]}>
                    {t("footer.company.title")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    {t("footer.company.aboutUs")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    {t("footer.company.contactUs")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      navigate("/ai-talks-newsletter");
                    }}
                  >
                    {t("footer.company.newsletter")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      window.open(privacyPolicy, "_blank");
                    }}
                  >
                    {t("footer.company.privacy")}
                  </span>
                </div>
              </div>
              <div className="row mx-0 w-100" style={{ marginBottom: "1rem" }}>
                <div
                  className="col-auto px-0"
                  style={{ marginRight: "0.5rem" }}
                >
                  <span
                    className={styles["footerContainer__contentClickable"]}
                    onClick={() => {
                      window.open(cookiePolicy, "_blank");
                    }}
                  >
                    {t("footer.company.cookies")}
                  </span>
                </div>
              </div>
            </div>
            <div className="col px-0">
              <div
                className="row mx-0 w-100"
                style={{ marginBottom: "1.5rem" }}
              >
                <div className="col px-0">
                  <span className={styles["footerContainer__contentTitle"]}>
                    {t("footer.contacts")}
                  </span>
                </div>
              </div>
              <div
                className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                style={{ marginBottom: "2rem" }}
              >
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={() => {
                    window.open(
                      "https://www.google.com/maps/place/Av.+Ant%C3%B3nio+Augusto+de+Aguiar+27,+1050-012+Lisboa/@38.7317716,-9.1546116,17z/data=!3m1!4b1!4m6!3m5!1s0xd19337369e9918d:0x1efaa658f016e65f!8m2!3d38.7317716!4d-9.1520367!16s%2Fg%2F11bw44jtvc?entry=ttu",
                      "_blank"
                    );
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoMap}
                    alt={"logo"}
                    style={{ height: "3rem" }}
                  />
                </div>
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={(e) => {
                    window.location = "mailto:info@starkdata.ai";
                    e.preventDefault();
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoEnvelope}
                    alt={"logo"}
                    style={{
                      height: "3rem",
                    }}
                  />
                </div>
                <div
                  className="col-auto px-0 d-flex justify-content-center align-items-center"
                  style={{ marginRight: "2rem" }}
                  onClick={() => {
                    window.open(
                      "https://pt.linkedin.com/company/starkdata-ai",
                      "_blank"
                    );
                  }}
                >
                  <img
                    loading="lazy"
                    className={`${styles["footerContainer__icon"]}`}
                    src={icoLinkedIn}
                    alt={"logo"}
                    style={{ height: "3rem" }}
                  />
                </div>
                {/* <div className="col px-0">
                                    <span
                                        className={
                                            styles[
                                                'footerContainer__contentBold'
                                            ]
                                        }
                                    >
                                        {t('footer.address')}
                                    </span>
                                </div> */}
              </div>
              <div
                className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                style={{ marginBottom: "2rem" }}
              >
                <div className="col px-0">
                  <span className={styles["footerContainer__contentBold"]}>
                    {t("footer.address")}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
