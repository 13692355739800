import React from 'react'
import styles from './NavigationMobileOption.module.scss'
import icoDropdownArrow from '../../../assets/images/dropdown-arrow.svg'
import { NavLink, useLocation } from 'react-router-dom'

export const NavigationMobileOption = ({
    text,
    url,
    children,
    notClickable,
    isDropdownOpen,
    onSetIsDropdownOpen,
    isLastOption,
    onSetToggleMenu,
}) => {
    const location = useLocation()
    return (
        <div
            className={`${styles['navigation-mobile-option-container']}`}
            style={{
                paddingBottom: isLastOption ? '0' : '1rem',
            }}
            onClick={() => {
                if (isDropdownOpen === url) {
                    onSetIsDropdownOpen(undefined)
                } else {
                    onSetIsDropdownOpen(url)
                }
            }}
        >
            <div
                className="row mx-0 w-100"
                style={{
                    marginBottom: '2rem',
                    padding: '0 2rem',
                }}
            >
                <div className="col px-0">
                    <NavLink
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                        }}
                        to={url}
                        onClick={(e) => {
                            if (notClickable) {
                                e.preventDefault()
                            } else {
                                onSetToggleMenu(false)
                            }
                        }}
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span
                                    className={`${styles['navigation-mobile-option-container__text']}`}
                                    style={{
                                        color:
                                            location.pathname === url
                                                ? '#2C3C8D'
                                                : '#333',
                                    }}
                                >
                                    {text}
                                </span>
                            </div>
                            {children && (
                                <div className="col-auto px-0">
                                    <img
                                        src={icoDropdownArrow}
                                        loading="lazy"
                                        className={
                                            isDropdownOpen === url
                                                ? `${styles['navigation-mobile-option-container__icon']} ${styles['navigation-mobile-option-container__icon--active']}`
                                                : `${styles['navigation-mobile-option-container__icon']}`
                                        }
                                        style={{
                                            transform:
                                                isDropdownOpen === url
                                                    ? 'rotate(180deg)'
                                                    : 'rotate(0deg)',
                                        }}
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>
                    </NavLink>
                </div>
            </div>
            {isDropdownOpen === url && children && (
                <div
                    className="row mx-0 w-100"
                    style={{
                        padding: '2rem',
                        background: '#fff',
                    }}
                >
                    <div className="col px-0">{children}</div>
                </div>
            )}
        </div>
    )
}
