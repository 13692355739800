import React, { useRef, useState, useEffect, useCallback } from 'react'
// import play from '../../../assets/images/icoPlay.svg'
// import playAlone from '../../../assets/images/icoPlayAlone.svg'
import debounce from 'lodash.debounce' // You need to install lodash.debounce
// import icoVolumeMedium from '../../../assets/images/icoVolumeMedium.svg'
// import icoVolumeHigh from '../../../assets/images/icoVolumeHigh.svg'
// import icoVolumeMuted from '../../../assets/images/icoVolumeMuted.svg'
// import pause from '../../../assets/images/icoPause.svg'
// import pauseAlone from '../../../assets/images/icoPauseAlone.svg'
// import captionsActive from '../../../assets/images/captionsActive.svg'
// import captionsInactive from '../../../assets/images/captionsInactive.svg'
// import enterFullscreen from '../../../assets/images/enterFullscreen.svg'
// import exitFullscreen from '../../../assets/images/exitFullscreen.svg'
// import VolumeControl from '../VolumeControl/VolumeControl'
import styles from './VideoPlayer.module.scss'

function VideoPlayer({ src, captions, thumbnail }) {
    const progressBarRef = useRef(null)
    const videoParentRef = useRef(null)
    const videoRef = useRef(null)
    const [isCaptionsActive, setIsCaptionsActive] = useState(false)
    const [progress, setProgress] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [selectedCaption, setSelectedCaption] = useState('off')
    const [currentCaptionText, setCurrentCaptionText] = useState('')
    const [showControls, setShowControls] = useState(false)
    const hoverTimeout = useRef(null)
    const [isDragging, setIsDragging] = useState(false)
    const [tempProgress, setTempProgress] = useState(0)
    const [mouseDownX, setMouseDownX] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [volume, setVolume] = useState(0)
    const DRAG_THRESHOLD = 5 // pixels
    const [isPaused, setIsPaused] = useState(false)
    const [hasStarted, setHasStarted] = useState(false)

    const togglePlayPause = useCallback(() => {
        setShowControls(true)
        if (!videoRef.current.paused) {
            videoRef.current.pause()
            setIsPaused(true)
        } else {
            videoRef.current.play()
            setIsPaused(false)
        }
        if (!hasStarted) {
            setHasStarted(true)
        }
    }, [hasStarted])

    const handleChangeVolume = useCallback(
        (vol) => {
            setVolume(vol)
            videoRef.current.volume = vol / 100
        },
        [setVolume]
    )

    // Increment the video current time as the video plays
    useEffect(() => {
        const interval = setInterval(() => {
            if (videoRef.current && !isDragging) {
                const currentTime = videoRef.current.currentTime
                const percentage = (currentTime / duration) * 100
                setProgress(percentage)
            }
        }, 50)

        return () => {
            clearInterval(interval)
        }
    }, [duration, isDragging])

    const handleProgressClick = useCallback(
        (e) => {
            if (progressBarRef.current) {
                const rect = progressBarRef.current.getBoundingClientRect()
                const clickPositionInPixels = e.clientX - rect.left
                const clickedPercentage = clickPositionInPixels / rect.width
                const newTime = clickedPercentage * duration
                videoRef.current.currentTime = newTime
                setProgress(clickedPercentage * 100) // Update the progress state to reflect the click position
            }
        },
        [duration]
    )

    const updateVideoCurrentTime = useCallback(() => {
        // tempProgress is a percentage, so we need to calculate the time in seconds
        const newTime = (tempProgress / 100) * duration
        videoRef.current.currentTime = newTime
    }, [tempProgress, duration])

    const handleStart = (clientX) => {
        // This function will handle both touch start and mouse down

        setMouseDownX(clientX)
        setIsDragging(true)
        const rect = progressBarRef.current.getBoundingClientRect()
        const clickPositionInPixels = clientX - rect.left
        const newProgress = clickPositionInPixels / rect.width
        setTempProgress(newProgress * 100) // Update temp progress as a percentage
    }

    const handleMove = useCallback(
        (clientX) => {
            // This function will handle both touch move and mouse move
            if (isDragging && progressBarRef.current) {
                const rect = progressBarRef.current.getBoundingClientRect()
                let clickPositionInPixels = clientX - rect.left

                // Constrain the click position to the progress bar bounds
                clickPositionInPixels = Math.max(clickPositionInPixels, 0)
                clickPositionInPixels = Math.min(
                    clickPositionInPixels,
                    rect.width
                )

                let newProgress = clickPositionInPixels / rect.width
                newProgress = Math.min(Math.max(newProgress, 0), 1) // Ensure newProgress is between 0 and 1

                setTempProgress(newProgress * 100) // Update temp progress as a percentage
            }
        },
        [isDragging]
    )

    const handleEnd = useCallback(
        (clientX) => {
            // This function will handle both touch end and mouse up
            if (isDragging) {
                const moveDistance = Math.abs(clientX - mouseDownX)
                if (moveDistance <= DRAG_THRESHOLD) {
                    // If the mouse hasn't moved beyond the threshold, it's a click
                    // Call the click handler with a mock event
                    handleProgressClick({ clientX: clientX })
                } else {
                    // It's the end of a drag, update the progress and the video current time
                    setProgress(tempProgress)
                    updateVideoCurrentTime()
                }

                setIsDragging(false)
                setMouseDownX(null) // Reset the initial mouse down X position
            }
        },
        [
            handleProgressClick,
            tempProgress,
            updateVideoCurrentTime,
            isDragging,
            mouseDownX,
        ]
    )

    // Convert the mouse handlers to handle both mouse and touch events
    const handleMouseDown = (e) => {
        handleStart(e.clientX)
    }

    const handleMouseMove = useCallback(
        (e) => {
            handleMove(e.clientX)
        },
        [handleMove]
    )

    const handleMouseUp = useCallback(
        (e) => {
            handleEnd(e.clientX)
        },
        [handleEnd]
    )

    // Add touch event handlers
    const handleTouchStart = (e) => {
        const touch = e.touches[0]
        handleStart(touch.clientX)
    }

    const handleTouchMove = useCallback(
        (e) => {
            const touch = e.touches[0]
            handleMove(touch.clientX)
        },
        [handleMove]
    )

    const handleTouchEnd = useCallback(
        (e) => {
            // Use the last known position from touch move
            handleEnd(tempProgress) // Since e.touches is empty on touch end, use tempProgress
        },
        [handleEnd, tempProgress]
    )

    // Modify the useEffect hook to add and remove touch event listeners
    useEffect(() => {
        // Add mouse and touch event listeners to the document
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('touchmove', handleTouchMove, {
            passive: false,
        })
        document.addEventListener('touchend', handleTouchEnd)

        return () => {
            // Remove event listeners when the component is unmounted
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
            document.removeEventListener('touchmove', handleTouchMove)
            document.removeEventListener('touchend', handleTouchEnd)
        }
    }, [handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd])

    // useEffect(() => {
    //     const textTrack = videoRef.current.textTracks[0]
    //     if (textTrack) {
    //         textTrack.oncuechange = (event) => {
    //             const currentCue = event.currentTarget.activeCues[0]
    //             if (currentCue) {
    //                 setCurrentCaptionText(currentCue.text)
    //             }
    //         }
    //     }
    // }, [])

    // useEffect(() => {
    //     const tracks = videoRef.current.textTracks
    //     for (let i = 0; i < tracks.length; i++) {
    //         tracks[i].mode = 'hidden'
    //     }
    // }, [])

    const handleVideoLoaded = () => {
        if (videoRef && videoRef.current) setDuration(videoRef.current.duration)
    }

    const debouncedHandleProgressClick = debounce(handleProgressClick, 100)

    const handleCaptionChange = (e) => {
        const value = e.target.value
        setSelectedCaption(value)
    }

    function toggleFullScreen() {
        if (!videoRef.current.fullscreenElement && videoRef.current) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen()
            } else if (videoRef.current.mozRequestFullScreen) {
                /* Firefox */
                videoRef.current.mozRequestFullScreen()
            } else if (videoRef.current.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                videoRef.current.webkitRequestFullscreen()
            } else if (videoRef.current.msRequestFullscreen) {
                /* IE/Edge */
                videoRef.current.msRequestFullscreen()
            } else {
                videoRef.current.webkitEnterFullscreen()
            }
        } else {
            if (videoRef.current.exitFullscreen) {
                videoRef.current.exitFullscreen()
            } else if (videoRef.current.mozCancelFullScreen) {
                /* Firefox */
                videoRef.current.mozCancelFullScreen()
            } else if (videoRef.current.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                videoRef.current.webkitExitFullscreen()
            } else if (videoRef.current.msExitFullscreen) {
                /* IE/Edge */
                videoRef.current.msExitFullscreen()
            } else {
                videoRef.current.webkitExitFullscreen()
            }
        }
    }

    const handleVideoSeeking = () => {
        setIsLoading(true)
    }

    const handleVideoSeeked = () => {
        setIsLoading(false)
    }

    const handleVideoWaiting = () => {
        setIsLoading(true)
    }

    const handleVideoCanPlayThrough = () => {
        setIsLoading(false)
    }

    return (
        <div
            className={`${styles['video-player']}`}
            ref={videoParentRef}
            onMouseEnter={() => {
                setShowControls(true)
            }}
            onMouseLeave={() => {
                setShowControls(false)
            }}
        >
            {isLoading && (
                <div className={`${styles['center-loader']}`}>
                    <div className={`${styles['loader']}`}></div>
                </div>
            )}
            <iframe
                width={'100%'}
                height={'100%'}
                src={src}
                frameBorder="0"
                allowFullScreen
            >
                {/* {captions.map((caption, index) => (
                    <track
                        key={index}
                        src={caption.src}
                        kind="metadata"
                        srcLang="en"
                        default={false}
                        className={`${styles['caption']}`}
                        label={caption.label}
                    />
                ))} */}
            </iframe>
            {/* <img
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    height: '25%',
                    cursor: 'pointer',
                    opacity: 1,
                    animation:
                        !isPaused && hasStarted
                            ? 'disappear 0.3s ease-in-out forwards'
                            : 'appearImage 0.1s ease-in-out forwards',
                }}
                loading="lazy"
                src={!isPaused && hasStarted ? pause : play}
                onClick={togglePlayPause}
            />
            {selectedCaption !== 'off' && currentCaptionText && (
                <div
                    className={`${styles['current-caption']}`}
                    style={{
                        fontSize: document.fullscreenElement
                            ? '3rem'
                            : '1.4rem',
                    }}
                >
                    {currentCaptionText}
                </div>
            )}
            <div
                className={`${styles['video-controls']}`}
                style={{
                    opacity: 0,
                    animation: showControls
                        ? 'appear 0.3s ease-in-out forwards'
                        : 'disappear 0.3s ease-in-out forwards',
                }}
            >
                <div
                    className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                    style={{ marginBottom: '1rem', padding: '0 0.5rem' }}
                >
                    <div className="col px-0">
                        <div
                            ref={progressBarRef}
                            className={`${styles['progress-bar']}`}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleTouchStart}
                            onClick={debouncedHandleProgressClick}
                        >
                            <div className="w-100 d-flex">
                                <div
                                    className={`${styles['progress-bar-fill']}`}
                                    style={{
                                        width: `${
                                            isDragging ? tempProgress : progress
                                        }%`,
                                    }}
                                ></div>
                                <div
                                    className={`${styles['progress-bar-not-fill']}`}
                                    style={{
                                        width: `${
                                            isDragging
                                                ? 100 - tempProgress
                                                : 100 - progress
                                        }%`,
                                    }}
                                ></div>
                            </div>
                            <div
                                className={`${styles['progress-circle']}`}
                                style={{
                                    left: `${
                                        isDragging ? tempProgress : progress
                                    }%`,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 w-100 d-flex align-items-center">
                    <div
                        className={`col-auto px-0 ${styles['video-controls-left']}`}
                    >
                        <img
                            loading="lazy"
                            style={{
                                width: '3rem',
                                cursor: 'pointer',
                                filter: 'brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7492%) hue-rotate(262deg) brightness(102%) contrast(102%)',
                            }}
                            src={!isPaused ? pauseAlone : playAlone}
                            onClick={togglePlayPause}
                        />
                    </div>
                    <div className="col-auto px-0">
                        <img
                            loading="lazy"
                            src={
                                volume > 50
                                    ? icoVolumeHigh
                                    : volume > 0
                                    ? icoVolumeMedium
                                    : icoVolumeMuted
                            }
                            style={{
                                width: '3rem',
                                cursor: 'pointer',
                                filter: 'brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7492%) hue-rotate(262deg) brightness(102%) contrast(102%)',
                                marginRight: '1.5rem',
                            }}
                            onClick={() => {
                                if (volume > 0) {
                                    handleChangeVolume(0)
                                } else {
                                    handleChangeVolume(50)
                                }
                            }}
                        />
                    </div>
                    <div className="col-auto px-0" style={{ width: '10rem' }}>
                        <VolumeControl
                            volume={volume}
                            onChangeVolume={handleChangeVolume}
                        />
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                    <div className="col-auto px-0" style={{ width: '2rem' }}>
                        <span
                            style={{
                                fontSize: '1.4rem',
                                color: '#fff',
                                fontWeight: 'bold',
                            }}
                        >
                            {Math.floor(progress / 60) < 10 ? (
                                <span>0{Math.floor(progress / 60)}</span>
                            ) : (
                                <span>{Math.floor(progress / 60)}</span>
                            )}
                            :
                        </span>
                        <span
                            style={{
                                fontSize: '1.4rem',
                                color: '#fff',
                                fontWeight: 'bold',
                                marginRight: '1.5rem',
                            }}
                        >
                            {Math.floor(progress % 60) < 10 ? (
                                <span>0{Math.floor(progress % 60)}</span>
                            ) : (
                                <span>{Math.floor(progress % 60)}</span>
                            )}
                        </span>
                    </div>

                    <div
                        className={`col px-0 ${styles['video-controls-right-right']} d-flex justify-content-end position-relative`}
                    >
                        <div className="row mx-100 d-flex align-items-center">
                            <div className="col-auto px-0 position-relative">
                                <img
                                    loading="lazy"
                                    src={
                                        selectedCaption !== 'off'
                                            ? captionsActive
                                            : captionsInactive
                                    }
                                    style={{
                                        marginLeft: '1rem',
                                        width: '3rem',
                                        cursor: 'pointer',
                                        filter: 'brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7492%) hue-rotate(262deg) brightness(102%) contrast(102%)',
                                    }}
                                    onClick={() =>
                                        setIsCaptionsActive(!isCaptionsActive)
                                    }
                                />
                                {isCaptionsActive && (
                                    <ul className={`${styles['options']}`}>
                                        <li
                                            onClick={() => {
                                                setSelectedCaption('off')
                                                handleCaptionChange({
                                                    target: { value: 'off' },
                                                })
                                                setIsCaptionsActive(false)
                                            }}
                                        >
                                            Off
                                        </li>
                                        {captions.map((caption, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    handleCaptionChange({
                                                        target: {
                                                            value: index,
                                                        },
                                                    })
                                                    setIsCaptionsActive(false)
                                                }}
                                            >
                                                {caption.label}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="col">
                                <img
                                    loading="lazy"
                                    style={{
                                        marginLeft: '1rem',
                                        width: '2rem',
                                        cursor: 'pointer',
                                        filter: 'brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7492%) hue-rotate(262deg) brightness(102%) contrast(102%)',
                                    }}
                                    src={
                                        document.fullscreenElement
                                            ? exitFullscreen
                                            : enterFullscreen
                                    }
                                    onClick={toggleFullScreen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default VideoPlayer
