/**
 * ErrorFallback.jsx
 *
 * This component is an error fallback that allows users to see it when an unexpected error occurrs.
 *
 * @author Ricardo Velhinho
 * @version 1.0
 */

import styles from './ErrorFallback.module.scss';

import React from 'react';

/**
 * ErrorFallback functional component
 * @returns {JSX.Element} - returns JSX element of an error fallback
 */

export const ErrorFallback = () => {
  return (
    <div
      role='alert'
      className={`${styles['error-fallback-wrapper']}`}
      data-testid={'error-fallback'}
    >
      <h1>Something went wrong:</h1>
    </div>
  );
};

ErrorFallback.propTypes = {};
