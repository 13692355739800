import React, { useMemo } from 'react'
import styles from './CardUseCase.module.scss'
import { TerciaryButton } from '../TerciaryButton/TerciaryButton'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import imgPlaceholder from '../../../assets/images/imgPlaceholder.png'
import 'react-lazy-load-image-component/src/effects/blur.css'

export const CardUseCase = React.memo(
    ({
        image,
        title,
        description,
        onClickText: buttonText,
        onClick: buttonClick,
        industry,
    }) => {
        const imageToShow = useMemo(() => {
            if (image) {
                return image
            }
            return imgPlaceholder
        }, [image])
        const { t } = useTranslation()
        const [isImageHovered, setIsImageHovered] = React.useState(false)
        const isTouchDevice = useMemo(
            () => 'ontouchstart' in window || navigator.maxTouchPoints > 0,
            []
        )

        // Modify the handlers to only trigger for non-touch devices
        const handleMouseEnter = () => {
            if (!isTouchDevice) {
                setIsImageHovered(true)
            }
        }

        const handleMouseLeave = () => {
            if (!isTouchDevice) {
                setIsImageHovered(false)
            }
        }
        return (
            <div
                className={`${styles['card-use-case-container']}`}
                style={{
                    cursor: 'pointer',

                    transform: isImageHovered ? 'scale(1.1)' : 'scale(1)',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={
                    buttonClick
                        ? () => {
                              buttonClick()
                          }
                        : undefined
                }
            >
                <div className="row mx-0 w-100" style={{ height: '30%' }}>
                    <div
                        className="col px-0"
                        style={{
                            overflow: 'hidden',
                            height: '100%',
                        }}
                    >
                        <LazyLoadImage
                            src={imageToShow || ''}
                            alt={title || ''}
                            className={`${styles['card-use-case-container__image']}`}
                            width="100%"
                            height="100%"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transition: 'all 0.3s ease-in-out',
                                opacity: isImageHovered ? '0.5' : '1',
                            }}
                            placeholderSrc={imgPlaceholder}
                            effect="blur"
                        />
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{ height: '60%', padding: '2rem 0' }}
                >
                    <div className="col px-0">
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '1rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                <span
                                    className={`${styles['card-use-case-container__title']}`}
                                >
                                    {title || '-'}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '1rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                <span
                                    className={`${styles['card-use-case-container__subTitle']}`}
                                >
                                    {t(`platform.${industry}`) || '-'}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                <span
                                    className={` ${styles['card-use-case-container__description']}`}
                                >
                                    {(description &&
                                        description.length > 150 &&
                                        description.substring(0, 150) +
                                            '...') ||
                                        description ||
                                        '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 w-100 d-flex justify-content-start align-items-end flex-grow-1">
                    <div className="col-auto px-0">
                        <TerciaryButton
                            text={buttonText}
                            onClick={buttonClick ? buttonClick : undefined}
                        />
                    </div>
                </div>
            </div>
        )
    }
)
