import React, { useCallback, useEffect, useRef, useState } from 'react';
import icoCloseModal from '../../../assets/images/icoCloseModal.svg';
import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import { SelectInput } from '../../common/SelectInput/SelectInput';
import { TypeInput } from '../../common/TypeInput/TypeInput';
import { emailRegex } from '../../../utils/error';
import styles from './RequestGuideModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useCurrentSize } from '../../../providers/current-size-context';
import { HighlightButton } from '../HighlightButton/HighlightButton';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import imageMockupGuide from '../../../assets/images/imageRequestGuide.png';
import axios from 'axios';

export const RequestGuideModal = ({ onCloseModal }) => {
    const { t } = useTranslation();
    const { isWidthGreaterEqualThan1200, isWidthGreaterEqualThan800 } =
        useCurrentSize();
    const [isGuideSent, setIsGuideSent] = useState(false);
    const requestGuideModalRef = useRef(null);
    const [firstName, firstNameSetValue] = useState('');
    const [lastName, lastNameSetValue] = useState('');
    const [email, setEmail] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isCheckboxSelectedForMarketing, setIsCheckboxSelectedForMarketing] =
        useState(false);
    const [
        isCheckboxSelectedForNewsletter,
        setIsCheckboxSelectedForNewsletter
    ] = useState(false);

    const handleSendGuide = () => {
        if (!firstName.trim()) {
            setShowWarning('Fill in the first name');
            return false;
        } else if (!lastName.trim()) {
            setShowWarning('Fill in the last name');
            return false;
        } else if (!email.trim()) {
            setShowWarning('Fill in the email');
            return false;
        } else if (!emailRegex.test(email)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!selectedValue.trim()) {
            setShowWarning('Fill in the industry');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms');
            return false;
        }
        setIsGuideSent(true);
        return true;
    };
    const [showWarning, setShowWarning] = useState(false);
    const handleOutsideClick = useCallback(
        (event) => {
            if (
                requestGuideModalRef.current &&
                !requestGuideModalRef.current.contains(event.target)
            ) {
                event.stopPropagation();
                onCloseModal();
            }
        },
        [requestGuideModalRef, onCloseModal]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick, true);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick, true);
        };
    }, [handleOutsideClick]);

    const handleSendEmail = async (
        firstName,
        lastName,
        email,
        selectedValue
    ) => {
        var bodyFormData = new FormData();
        // Set the SendGrid API key
        // sgMail.setApiKey('YOUR_SENDGRID_API_KEY')

        // Prepare the email content
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Responsible AI Guide',
            text: `Hi, my name is ${firstName} ${lastName}, my email is ${email}, and I am interested in the industry of ${selectedValue}. I want a short guide regarding responsible AI.`
        };
        bodyFormData.append('name', firstName + ' ' + lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        // Send the email
        // sgMail
        //     .send(msg)
        //     .then((response) => {
        //         console.log('Email sent successfully!', response)
        //     })
        //     .catch((error) => {
        //         console.error('There was an error sending the email.', error)
        //     })
    };

    return (
        <div
            className={`${styles['request-guide-modal-container']}`}
            style={{
                animation: 'appearImage 0.3s ease-in-out forwards'
            }}
        >
            <div
                className={`${styles['request-guide-modal-container__card']}`}
                ref={requestGuideModalRef}
                style={{
                    maxWidth: isGuideSent ? '50rem' : '128rem',
                    width: isGuideSent ? '50rem' : 'calc(100% - 4rem)',
                    animation: 'appearTextFromBottom 0.6s ease-in-out forwards'
                }}
            >
                <img
                    src={icoCloseModal}
                    loading="lazy"
                    alt="close modal"
                    className={`${styles['request-guide-modal-container__card__closeIcon']}`}
                    onClick={onCloseModal}
                />
                <MailchimpSubscribe
                    url={
                        'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                    }
                    render={({ subscribe }) => (
                        <>
                            <div className="row mx-0 w-100 h-100">
                                {!isGuideSent && (
                                    <div className="col px-0 d-flex flex-column justify-content-start align-items-center">
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '1rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['request-guide-modal-container__card__title']}`}
                                                        >
                                                            {t(
                                                                'requestGuideModal.title'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '1rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['request-guide-modal-container__card__descriptionBig']}`}
                                                        >
                                                            {t(
                                                                `responsibleAI.approach.text11`
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                {isWidthGreaterEqualThan800 && (
                                                    <div className="row mx-0 w-100 d-flex justify-content-center">
                                                        <div className="col-auto px-0">
                                                            <img
                                                                loading="lazy"
                                                                src={
                                                                    imageMockupGuide
                                                                }
                                                                alt="mockup guide"
                                                                style={{
                                                                    height: '40rem',
                                                                    marginBottom:
                                                                        '2rem'
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {isGuideSent && (
                                    <>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{
                                                padding: '0 2rem',
                                                margin: '2rem 0'
                                            }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    className={`${styles['request-guide-modal-container__thank-you-title']}`}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitleThx2'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{
                                                padding: '0 2rem'
                                            }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <span
                                                    className={`${styles['request-guide-modal-container__thank-you']}`}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitle62'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {isWidthGreaterEqualThan1200 && (
                                    <>
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '2rem' }}
                                        ></div>
                                        <div className="col px-0">
                                            {!isGuideSent && (
                                                <>
                                                    {showWarning && (
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                maxWidth:
                                                                    '50rem',
                                                                marginBottom:
                                                                    '1rem'
                                                            }}
                                                        >
                                                            <div className="col px-0 d-flex">
                                                                <span
                                                                    className={`${styles['request-guide-modal-container__warning']}`}
                                                                >
                                                                    {
                                                                        showWarning
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            padding: '0',
                                                            marginBottom:
                                                                '2rem',
                                                            paddingLeft: 0
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                label={t(
                                                                    'bookingModal.inputs.firstName'
                                                                )}
                                                                name={
                                                                    'firstName'
                                                                }
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={
                                                                    firstName
                                                                }
                                                                onChangeInput={
                                                                    firstNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            padding: '0',
                                                            marginBottom:
                                                                '2rem',
                                                            paddingLeft: 0
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={
                                                                    'lastName'
                                                                }
                                                                label={t(
                                                                    'bookingModal.inputs.lastName'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={lastName}
                                                                onChangeInput={
                                                                    lastNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            padding: '0',
                                                            marginBottom:
                                                                '2rem',
                                                            paddingLeft: 0
                                                        }}
                                                    >
                                                        <div
                                                            className="col px-0"
                                                            style={{}}
                                                        >
                                                            <SelectInput
                                                                label={t(
                                                                    'bookingModal.inputs.industry'
                                                                )}
                                                                name={
                                                                    'industry'
                                                                }
                                                                onChangeInput={(
                                                                    value
                                                                ) =>
                                                                    setSelectedValue(
                                                                        value
                                                                    )
                                                                }
                                                                options={[
                                                                    'Banking',
                                                                    'Insurance',
                                                                    'Healthcare',
                                                                    'Retail',
                                                                    'Manufacturing',
                                                                    'Other'
                                                                ]}
                                                                value={
                                                                    selectedValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom:
                                                                '4rem',
                                                            paddingLeft: 0
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                label={t(
                                                                    'bookingModal.inputs.email'
                                                                )}
                                                                name={'email'}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={email}
                                                                onChangeInput={
                                                                    setEmail
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'request-guide-modal-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelected
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelected(
                                                                        !isCheckboxSelected
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['request-guide-modal-container__terms']}`}
                                                            >
                                                                {t(
                                                                    'home.terms1'
                                                                )}
                                                            </span>
                                                            <span
                                                                className={`${styles['request-guide-modal-container__terms']}`}
                                                                style={{
                                                                    color: 'rgba(6, 164, 231, 1)',
                                                                    cursor: 'pointer',
                                                                    textDecoration:
                                                                        'underline'
                                                                }}
                                                                onClick={() => {
                                                                    window.open(
                                                                        privacyPolicy,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            >
                                                                {t(
                                                                    'home.terms2'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'request-guide-modal-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelectedForNewsletter
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelectedForNewsletter(
                                                                        !isCheckboxSelectedForNewsletter
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['request-guide-modal-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.termsNewsletter'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'request-guide-modal-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelectedForMarketing
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelectedForMarketing(
                                                                        !isCheckboxSelectedForMarketing
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['request-guide-modal-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms8'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-end align-items-center"
                                                        style={{
                                                            padding: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <HighlightButton
                                                                text={t(
                                                                    'button.requestGuide2'
                                                                )}
                                                                onClick={() => {
                                                                    if (
                                                                        handleSendGuide()
                                                                    ) {
                                                                        subscribe(
                                                                            {
                                                                                EMAIL: email,
                                                                                FNAME: firstName,
                                                                                LNAME: lastName,
                                                                                MMERGE6:
                                                                                    selectedValue,
                                                                                MMERGE3:
                                                                                    undefined,
                                                                                MMERGE11:
                                                                                    'English',
                                                                                'group[2089][1]':
                                                                                    isCheckboxSelected
                                                                                        ? true
                                                                                        : undefined,
                                                                                'group[2089][2]':
                                                                                    isCheckboxSelectedForMarketing
                                                                                        ? true
                                                                                        : undefined,
                                                                                'group[2089][4]':
                                                                                    undefined,
                                                                                'group[2089][8]': true,
                                                                                'group[2089][16]':
                                                                                    undefined,
                                                                                'group[2089][32]':
                                                                                    undefined
                                                                            }
                                                                        );
                                                                        handleSendEmail(
                                                                            firstName,
                                                                            lastName,
                                                                            email,
                                                                            selectedValue
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {!isWidthGreaterEqualThan1200 && (
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginTop: '2rem' }}
                                >
                                    <div className="col px-0">
                                        {!isGuideSent && (
                                            <>
                                                {showWarning && (
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col px-0 d-flex">
                                                            <span
                                                                className={`${styles['request-guide-modal-container__warning']}`}
                                                            >
                                                                {showWarning}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}

                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <TypeInput
                                                            label={t(
                                                                'bookingModal.inputs.firstName'
                                                            )}
                                                            name={'firstName'}
                                                            isRequired={true}
                                                            value={firstName}
                                                            onChangeInput={
                                                                firstNameSetValue
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <TypeInput
                                                            label={t(
                                                                'bookingModal.inputs.lastName'
                                                            )}
                                                            name={'lastName'}
                                                            isRequired={true}
                                                            value={lastName}
                                                            onChangeInput={
                                                                lastNameSetValue
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div
                                                        className="col px-0"
                                                        style={{}}
                                                    >
                                                        <SelectInput
                                                            label={t(
                                                                'bookingModal.inputs.industry'
                                                            )}
                                                            name={'industry'}
                                                            onChangeInput={(
                                                                value
                                                            ) =>
                                                                setSelectedValue(
                                                                    value
                                                                )
                                                            }
                                                            options={[
                                                                'Banking',
                                                                'Insurance',
                                                                'Healthcare',
                                                                'Retail',
                                                                'Manufacturing',
                                                                'Other'
                                                            ]}
                                                            value={
                                                                selectedValue
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '4rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <TypeInput
                                                            label={t(
                                                                'bookingModal.inputs.email'
                                                            )}
                                                            name={'email'}
                                                            isRequired={true}
                                                            value={email}
                                                            onChangeInput={
                                                                setEmail
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            marginTop: '0.4rem'
                                                        }}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            className={
                                                                styles[
                                                                    'request-guide-modal-container__checkbox'
                                                                ]
                                                            }
                                                            src={
                                                                isCheckboxSelected
                                                                    ? icoCheckboxSelected
                                                                    : icoCheckboxEmpty
                                                            }
                                                            onClick={() =>
                                                                setIsCheckboxSelected(
                                                                    !isCheckboxSelected
                                                                )
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['request-guide-modal-container__terms']}`}
                                                        >
                                                            {t('home.terms1')}
                                                        </span>
                                                        <span
                                                            className={`${styles['request-guide-modal-container__terms']}`}
                                                            style={{
                                                                color: 'rgba(6, 164, 231, 1)',
                                                                cursor: 'pointer',
                                                                textDecoration:
                                                                    'underline'
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    privacyPolicy,
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            {t('home.terms2')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            marginTop: '0.4rem'
                                                        }}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            className={
                                                                styles[
                                                                    'request-guide-modal-container__checkbox'
                                                                ]
                                                            }
                                                            src={
                                                                isCheckboxSelectedForNewsletter
                                                                    ? icoCheckboxSelected
                                                                    : icoCheckboxEmpty
                                                            }
                                                            onClick={() =>
                                                                setIsCheckboxSelectedForNewsletter(
                                                                    !isCheckboxSelectedForNewsletter
                                                                )
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['request-guide-modal-container__terms']}`}
                                                                >
                                                                    {t(
                                                                        'bookingModal.termsNewsletter'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            marginTop: '0.4rem'
                                                        }}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            className={
                                                                styles[
                                                                    'request-guide-modal-container__checkbox'
                                                                ]
                                                            }
                                                            src={
                                                                isCheckboxSelectedForMarketing
                                                                    ? icoCheckboxSelected
                                                                    : icoCheckboxEmpty
                                                            }
                                                            onClick={() =>
                                                                setIsCheckboxSelectedForMarketing(
                                                                    !isCheckboxSelectedForMarketing
                                                                )
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['request-guide-modal-container__terms']}`}
                                                                >
                                                                    {t(
                                                                        'bookingModal.terms8'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                    style={{
                                                        padding: '2rem'
                                                    }}
                                                >
                                                    <div className="col-auto px-0">
                                                        <HighlightButton
                                                            text={t(
                                                                'button.requestGuide2'
                                                            )}
                                                            onClick={() => {
                                                                if (
                                                                    handleSendGuide()
                                                                ) {
                                                                    subscribe({
                                                                        EMAIL: email,
                                                                        FNAME: firstName,
                                                                        LNAME: lastName,
                                                                        MMERGE6:
                                                                            selectedValue,
                                                                        MMERGE3:
                                                                            undefined,
                                                                        MMERGE11:
                                                                            'English',
                                                                        'group[2089][1]':
                                                                            isCheckboxSelectedForNewsletter
                                                                                ? true
                                                                                : undefined,
                                                                        'group[2089][2]':
                                                                            isCheckboxSelectedForMarketing
                                                                                ? true
                                                                                : undefined,
                                                                        'group[2089][4]':
                                                                            undefined,
                                                                        'group[2089][8]': true,
                                                                        'group[2089][16]':
                                                                            undefined,
                                                                        'group[2089][32]':
                                                                            undefined
                                                                    });
                                                                    handleSendEmail(
                                                                        firstName,
                                                                        lastName,
                                                                        email,
                                                                        selectedValue
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                ></MailchimpSubscribe>
            </div>
        </div>
    );
};
