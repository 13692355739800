import React from 'react'
import styles from './ToggleButton.module.scss'

export const ToggleButton = React.memo(({ bool, onClick, index }) => {
    return (
        <div
            className={
                bool
                    ? `${styles['toggle-button-container']} ${styles['toggle-button-container--on']}`
                    : `${styles['toggle-button-container']} ${styles['toggle-button-container--off']}`
            }
            onClick={() => onClick(index)}
        >
            <div
                className={
                    bool
                        ? `${styles['toggle-button-container__circle']} ${styles['toggle-button-container__circle--on']}`
                        : `${styles['toggle-button-container__circle']} ${styles['toggle-button-container__circle--off']}`
                }
            ></div>
        </div>
    )
})
