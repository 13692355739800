import React, { useMemo } from 'react'
import styles from './UseCaseContent.module.scss'
import { SolutionsCarousel } from '../SolutionsCarousel/SolutionsCarousel'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import backgroundSection from '../../../assets/images/backgroundSection.svg'
import { useCasesAll } from '../../../constants/useCases'
import useOnScreen from '../../../hooks/useOnScreen'
import { CardCarousel } from '../CardCarousel/CardCarousel'

export const UseCaseContent = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const resultsRef = React.useRef()
    const resultsVisible = useOnScreen(resultsRef)
    const useCase = useMemo(
        () => [...useCasesAll].filter((el) => el.id == params.id)[0],
        [params.id]
    )
    const useCases = useMemo(
        () => [...useCasesAll].filter((el) => el.id != params.id),
        [params.id]
    )
    const handleClickUseCase = (useCase, index) => {
        navigate(`/use-case/${index}`, {
            state: { useCase },
            replace: true,
        })
    }
    const cardsRef = React.useRef()
    const cardsVisible = useOnScreen(cardsRef)
    return (
        <div
            className={`${styles['use-case-content-container']}`}
            style={{
                padding: `5rem 0rem`,
            }}
        >
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '5rem 2rem',
                    background: `url(${backgroundSection})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 justify-content-center"
                        style={{
                            maxWidth: '128rem',
                            marginBottom: '5rem',
                        }}
                    >
                        <div className="col-auto px-0 d-flex justify-content-center">
                            <span
                                className={`${styles['use-case-content-container__title']}`}
                                style={{ color: '#fff' }}
                            >
                                {t('useCaseContent.solutionsTitle')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0 d-flex justify-content-center">
                            <SolutionsCarousel solutions={useCase.solutions} />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    maxWidth: '128rem',
                    padding: '5rem 2rem',
                }}
            >
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={`${styles['use-case-content-container__title']}`}
                    >
                        {t('useCaseContent.resultsTitle')}
                    </span>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                ref={resultsRef}
                style={{
                    maxWidth: '128rem',
                    marginBottom: '4rem',
                    padding: '0 2rem',
                }}
            >
                <div className="col px-0 d-flex flex-column justify-content-center">
                    <span
                        className={`${styles['use-case-content-container__text']}`}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                        {t('useCaseContent.resultsDescription')}
                    </span>
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center"
                style={{
                    maxWidth: '50rem',
                    padding: '0 2rem',
                    marginBottom: '2rem',
                }}
            >
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    {useCase.results.map((el, index) => (
                        <div className="row mx-0 w-100" key={index}>
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100"
                                    id={index}
                                    style={{ height: '2rem' }}
                                >
                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center h-100">
                                        <div
                                            className={`${styles['use-case-content-container__circle']}`}
                                        ></div>
                                    </div>
                                    <div
                                        className="col px-0 d-flex justify-content-start align-items-center h-100"
                                        style={{
                                            opacity: 0,
                                            animation: resultsVisible
                                                ? `appearTextFromRight 0.3s ease-in-out forwards ${
                                                      index * 0.3
                                                  }s`
                                                : undefined,
                                        }}
                                    >
                                        <span
                                            className={`${styles['use-case-content-container__result-text']}`}
                                        >
                                            {el}
                                        </span>
                                    </div>
                                </div>

                                {index !== useCase.results.length - 1 && (
                                    <div
                                        className="row mx-0 d-flex justify-content-center align-items-center"
                                        style={{
                                            height: '10rem',
                                            width: '2rem',
                                        }}
                                    >
                                        <div
                                            className="col-auto px-0 d-flex justify-content-center align-items-center h-100"
                                            style={{
                                                width: '2px',
                                                background: '#06A4E7',
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ margin: '4rem 0' }}
            >
                <div className="col px-0">
                    <hr
                        style={{
                            color: '#f0f0f0',
                            opacity: '1',
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col px-0">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['use-case-content-container__title2']}`}
                            >
                                {t('useCaseSpecific.useCases.title')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center"
                        style={{
                            marginBottom: '4rem',
                            padding: '0 2rem',
                        }}
                    >
                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['use-case-content-container__useCasesSubTitle']}`}
                            >
                                {t('home.useCases.subTitle')}
                            </span>
                        </div>
                    </div>
                    <div
                        ref={cardsRef}
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{
                            padding: '0',
                            height: '60rem',
                            opacity: 0,
                            animation: cardsVisible
                                ? `appearTextFromBottom 0.3s ease-in-out forwards`
                                : undefined,
                        }}
                    >
                        <CardCarousel
                            cards={useCases}
                            onClick={handleClickUseCase}
                            onClickText={t('home.card.learnMore')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
