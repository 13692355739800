import { useCallback, useState } from 'react';

export function useToggle(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = useCallback((v) => {
    setValue((currentValue) => (typeof v === 'boolean' ? v : !currentValue));
  }, []);

  return [value, toggleValue];
}
