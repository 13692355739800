import React, { useMemo } from 'react'

import StarkdataLogoBig from '../../../assets/images/starkdataLogoHuge.svg'
import captions from '../../../assets/videos/captions.vtt'
import styles from './Home.module.scss'
import thumbnail from '../../../assets/images/videoThumbnail.png'

import feature1 from '../../../assets/images/feature1.png'
import feature2 from '../../../assets/images/feature2.png'
import feature3 from '../../../assets/images/feature3.png'
import { useTranslation } from 'react-i18next'

import { CardCarousel } from '../../common/CardCarousel/CardCarousel'
import { useNavigate } from 'react-router-dom'
import { HighlightButton } from '../../common/HighlightButton/HighlightButton'
import { useCasesAll } from '../../../constants/useCases'
import useOnScreen from '../../../hooks/useOnScreen'
import { useCurrentSize } from '../../../providers/current-size-context'
import backgroundSection from '../../../assets/images/backgroundSection.svg'
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer'
import { NumberCard } from '../../common/NumberCard/NumberCard'

export const Home = React.memo(() => {
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const feature1Ref = React.useRef()
    const feature1Visible = useOnScreen(feature1Ref)
    const feature2Ref = React.useRef()
    const feature2Visible = useOnScreen(feature2Ref)
    const feature3Ref = React.useRef()
    const feature3Visible = useOnScreen(feature3Ref)
    const useCases = useMemo(() => [...useCasesAll], [])
    const cardsRef = React.useRef()
    const cardsVisible = useOnScreen(cardsRef)

    const handleClickUseCase = (useCase, index) => {
        navigate(`/use-case/${index}`, {
            state: { useCase },
        })
    }

    return (
        <>
            <div className={`${styles['home-container']}`}>
                <div
                    className="row mx-0 w-100 position-relative"
                    style={{
                        minHeight: '75rem',
                        maxWidth: `128rem`,
                        padding: '0 2rem',
                    }}
                >
                    <div className="col" style={{}}>
                        <div className="row mx-0 w-100">
                            <div
                                className="col-auto h-100 d-flex flex-column justify-content-center"
                                style={{
                                    width: isWidthGreaterEqualThan800
                                        ? '50rem'
                                        : '100%',
                                    padding: isWidthGreaterEqualThan800
                                        ? `20rem 0`
                                        : isWidthGreaterEqualThan600
                                        ? `15rem 0`
                                        : `12rem 0`,
                                    paddingBottom: isWidthGreaterEqualThan800
                                        ? `10rem`
                                        : isWidthGreaterEqualThan600
                                        ? `5rem`
                                        : `2.5rem`,
                                    background: 'transparent',
                                    zIndex: 0,
                                    opacity: 0,
                                    animation: `appearTextFromLeft 0.6s ease-in-out forwards`,
                                }}
                            >
                                <div
                                    className="row mx-0 w-100"
                                    style={{
                                        marginBottom: '3rem',
                                    }}
                                >
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['home-container__title']}`}
                                        >
                                            {t('home.title')}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '5rem' }}
                                >
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['home-container__subTitle']}`}
                                        >
                                            {t('home.subTitle')}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-start align-items-center">
                                    <div className="col-auto px-0 h-100">
                                        <HighlightButton
                                            text={t('home.contactUs')}
                                            onClick={() =>
                                                navigate('/contact-us')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {isWidthGreaterEqualThan800 && (
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '5rem' }}
                                ></div>
                            )}
                            <div
                                className={
                                    isWidthGreaterEqualThan800
                                        ? 'col px-0 d-flex justify-content-center align-items-center'
                                        : 'col-12 px-0 d-flex justify-content-center align-items-center'
                                }
                                style={{
                                    padding: isWidthGreaterEqualThan800
                                        ? `20rem 0`
                                        : `0`,
                                    background: 'transparent',
                                    zIndex: 0,
                                    opacity: 0,
                                    animation: `appearTextFromRight 0.6s ease-in-out forwards`,
                                }}
                            >
                                <VideoPlayer
                                    thumbnail={thumbnail}
                                    src={
                                        'https://www.youtube.com/embed/LkT77ziTh_I'
                                    }
                                    captions={[
                                        { src: captions, label: 'English' },
                                    ]}
                                />
                                {/* <ReactPlayer
                                    url={starkdataVideo}
                                    onEnded={(e) => {
                                        e.target.load()
                                    }}
                                    poster={thumbnail}
                                    style={{
                                        borderRadius: '8px',
                                        boxShadow:
                                            '0 0px 20px 20px rgba(40, 60 ,141, 0.1)',
                                    }}
                                    width="100%"
                                    controls
                                    config={{
                                        file: {
                                            tracks: [
                                                {
                                                    kind: 'subtitles',
                                                    src: captions,
                                                    srcLang: 'English',
                                                    default: true,
                                                    color: 'orange',
                                                },
                                            ],
                                        },
                                    }}
                                > */}
                                {/* <track
                                        kind="captions"
                                        src={captions}
                                        srcLang="en"
                                        label="English"
                                    ></track> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles['home-container__filter']}`}
                        style={{
                            zIndex: -1,
                        }}
                    ></div>
                    <img
                        src={StarkdataLogoBig}
                        style={{
                            position: 'absolute',
                            top: !isWidthGreaterEqualThan600
                                ? 'calc(70%)'
                                : isWidthGreaterEqualThan800
                                ? 'calc(50% + 15rem)'
                                : 'calc(50%)',
                            right: '0',
                            zIndex: -1,
                            width: isWidthGreaterEqualThan800
                                ? '100rem'
                                : '25rem',
                            transform: 'translateY(-50%) translateX(50%)',
                            opacity: 0.5,
                        }}
                    ></img>
                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            left: '0',
                            top: '100%',
                            height: '1px',
                            background: '#f0f0f0',
                        }}
                    ></div>
                </div>
                <div
                    className="row mx-0 w-100 d-flex justify-content-center"
                    style={{
                        backgroundColor: '#fff',
                        padding: '5rem 2rem',
                        position: 'relative',
                        maxWidth: `128rem`,
                        zIndex: -1,
                    }}
                >
                    <div
                        className="col d-flex flex-column justify-content-center position-relative h-100"
                        style={{
                            backgroundColor: '#fff',
                        }}
                    >
                        {/* <img
                            src={homeTree}
                            alt="tree"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                padding: `0 ${horizontalPadding}`,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                pointerEvents: 'none',
                            }}
                        /> */}
                        <div
                            className="row mx-0 w-100"
                            style={{
                                marginBottom: '5rem',
                            }}
                        >
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['home-container__title2']}`}
                                >
                                    {t('home.whatWeDo.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div
                                className="col-auto px-0"
                                style={{ textAlign: 'center' }}
                            >
                                <span
                                    className={`${styles['home-container__title4']}`}
                                >
                                    {t('home.whatWeDo.subTitle1')}
                                </span>
                                <span
                                    className={`${styles['home-container__title4']}`}
                                >
                                    {t('home.whatWeDo.subTitle2')}
                                </span>
                            </div>
                        </div>

                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div
                                    ref={feature1Ref}
                                    className="row mx-0 w-100 position-relative"
                                    style={{
                                        paddingTop: '10rem',
                                        opacity: 0,
                                        animation: feature1Visible
                                            ? `appearTextFromRight 0.6s ease-in-out forwards`
                                            : undefined,
                                    }}
                                >
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex align-items-center'
                                                : 'col-12 px-0 d-flex align-items-center order-2'
                                        }
                                        style={{
                                            marginTop:
                                                !isWidthGreaterEqualThan600
                                                    ? '5rem'
                                                    : '0',
                                        }}
                                    >
                                        <img
                                            src={feature1}
                                            style={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                boxShadow:
                                                    '0 0px 20px 20px rgba(40, 60 ,141, 0.1)',
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '5rem' }}
                                    ></div>
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex flex-column justify-content-center align-items-start'
                                                : 'col-12 px-0 d-flex flex-column justify-content-center align-items-start order-1'
                                        }
                                    >
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__title3']}`}
                                                >
                                                    {t(
                                                        'home.noCodeAI.contentTitle'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__content']}`}
                                                >
                                                    {t('home.noCodeAI.content')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ref={feature2Ref}
                                    className="row mx-0 w-100 position-relative"
                                    style={{
                                        paddingTop: '10rem',
                                        opacity: 0,
                                        animation: feature2Visible
                                            ? `appearTextFromLeft 0.6s ease-in-out forwards 0.6s`
                                            : undefined,
                                    }}
                                >
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex flex-column justify-content-center align-items-start'
                                                : 'col-12 px-0 d-flex flex-column justify-content-center align-items-start order-1'
                                        }
                                    >
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__title3']}`}
                                                >
                                                    {t(
                                                        'home.customerLifecycleManagement.contentTitle'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__content']}`}
                                                >
                                                    {t(
                                                        'home.customerLifecycleManagement.content'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '5rem' }}
                                    ></div>
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex align-items-center'
                                                : 'col-12 px-0 d-flex align-items-center order-2'
                                        }
                                        style={{
                                            marginTop:
                                                !isWidthGreaterEqualThan600
                                                    ? '5rem'
                                                    : '0',
                                        }}
                                    >
                                        <img
                                            src={feature3}
                                            style={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                boxShadow:
                                                    '0 0px 20px 20px rgba(40, 60 ,141, 0.1)',
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    ref={feature3Ref}
                                    className="row mx-0 w-100 position-relative"
                                    style={{
                                        paddingTop: '10rem',
                                        marginBottom: '5rem',
                                        opacity: 0,
                                        animation: feature3Visible
                                            ? `appearTextFromRight 0.6s ease-in-out forwards 0.6s`
                                            : undefined,
                                    }}
                                >
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex align-items-center'
                                                : 'col-12 px-0 d-flex align-items-center order-2'
                                        }
                                        style={{
                                            marginTop:
                                                !isWidthGreaterEqualThan600
                                                    ? '5rem'
                                                    : '0',
                                        }}
                                    >
                                        <img
                                            src={feature2}
                                            style={{
                                                width: '100%',
                                                borderRadius: '8px',
                                                boxShadow:
                                                    '0 0px 20px 20px rgba(40, 60 ,141, 0.1)',
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '5rem' }}
                                    ></div>
                                    <div
                                        className={
                                            isWidthGreaterEqualThan600
                                                ? 'col px-0 d-flex flex-column justify-content-center align-items-start'
                                                : 'col-12 px-0 d-flex flex-column justify-content-center align-items-start order-1'
                                        }
                                    >
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__title3']}`}
                                                >
                                                    {t(
                                                        'home.predictiveAI.contentTitle'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={`${styles['home-container__content']}`}
                                                >
                                                    {t(
                                                        'home.predictiveAI.content'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                    style={{
                        background: `url(${backgroundSection})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div
                        className="col"
                        style={{
                            padding: `6rem 2rem`,
                            maxWidth: `128rem`,
                        }}
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['home-container__benefitsTitle']}`}
                                >
                                    &#8220;
                                    {t('home.benefits.firstQuote')}
                                    &#8222;
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-75"
                            style={{ marginBottom: '5rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span
                                    className={`${styles['home-container__benefitsAuthor']}`}
                                    style={{}}
                                >
                                    - {t('home.benefits.firstQuoteAuthor')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                            style={{
                                marginBottom: '5rem',
                                height: isWidthGreaterEqualThan600
                                    ? '22.5rem'
                                    : undefined,
                            }}
                        >
                            <NumberCard
                                targetNumber={52}
                                text={t('home.statistics.1')}
                            />
                            {isWidthGreaterEqualThan600 && (
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '5rem' }}
                                ></div>
                            )}
                            <NumberCard
                                targetNumber={48}
                                text={t('home.statistics.2')}
                            />
                            {isWidthGreaterEqualThan600 && (
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '5rem' }}
                                ></div>
                            )}
                            <NumberCard
                                targetNumber={47}
                                text={t('home.statistics.3')}
                            />
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['home-container__benefitsTitle']}`}
                                >
                                    &#8220;
                                    {t('home.benefits.secondQuote')}
                                    &#8222;
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-75">
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span
                                    className={`${styles['home-container__benefitsAuthor']}`}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            'https://info1.exlservice.com/hubfs/EXL-OSnap.pdf',
                                            '_blank'
                                        )
                                    }}
                                >
                                    - {t('home.benefits.secondQuoteAuthor')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{
                        paddingTop: `4rem`,
                    }}
                >
                    <div className="col px-0 d-flex flex-column align-items-center">
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['home-container__title2']}`}
                                >
                                    {t('home.useCases.title')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{
                                marginBottom: '4rem',
                                maxWidth: '128rem',
                                padding: '0 2rem',
                            }}
                        >
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['home-container__useCasesSubTitle']}`}
                                >
                                    {t('home.useCases.subTitle')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                            ref={cardsRef}
                            style={{
                                padding: '0',
                                height: '60rem',
                                opacity: 0,
                                animation: cardsVisible
                                    ? `appearTextFromBottom 0.3s ease-in-out forwards`
                                    : undefined,
                            }}
                        >
                            <CardCarousel
                                cards={useCases}
                                onClick={handleClickUseCase}
                                onClickText={t('home.card.learnMore')}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                    style={{
                        padding: `4rem 2rem`,
                        height: '',
                        maxWidth: `128rem`,
                    }}
                >
                    <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                        <div
                            className={
                                'row mx-0 w-100 d-flex justify-content-center align-items-center'
                            }
                            style={{ marginBottom: '4rem' }}
                        >
                            <div
                                className={'col-auto px-0'}
                                style={{
                                    textAlign: isWidthGreaterEqualThan600
                                        ? 'left'
                                        : 'center',
                                    marginBottom: isWidthGreaterEqualThan600
                                        ? '0'
                                        : '2rem',
                                }}
                            >
                                <span
                                    className={`${styles['home-container__book-demo-title']}`}
                                >
                                    {t('home.bookDemo.title1')}
                                </span>
                                <span
                                    className={`${styles['home-container__book-demo-title']}`}
                                    style={{ color: '#06a4e7' }}
                                >
                                    {t('home.bookDemo.title2')}
                                </span>
                                <span
                                    className={`${styles['home-container__book-demo-title']}`}
                                >
                                    {t('home.bookDemo.title3')}
                                </span>
                                <span
                                    className={`${styles['home-container__book-demo-title']}`}
                                    style={{ color: '#06a4e7' }}
                                >
                                    {t('home.bookDemo.title4')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div className="col-auto px-0">
                                <HighlightButton
                                    text={t('home.bookDemo.action')}
                                    onClick={() => navigate('/contact-us')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
