import React, { useEffect, useState } from 'react'

import styles from './App.module.scss'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/pages/ErrorFallback'
import DashboardLayout from './components/pages/DashboardLayout'
import { CurrentSizeProvider } from './providers/current-size-context'
import useGaTracker from './hooks/useGaTracker'

export const App = () => {
    useGaTracker()
    const [isWidthGreaterEqualThan1200, setIsWidthGreaterEqualThan1200] =
        useState(window.innerWidth > 1200)
    const [isWidthGreaterEqualThan600, setIsWidthGreaterEqualThan600] =
        useState(window.innerWidth > 600)
    const [isWidthGreaterEqualThan800, setIsWidthGreaterEqualThan800] =
        useState(window.innerWidth > 800)

    useEffect(() => {
        function handleResize() {
            setIsWidthGreaterEqualThan1200(window.innerWidth >= 1200)
            setIsWidthGreaterEqualThan600(window.innerWidth >= 600)
            setIsWidthGreaterEqualThan800(window.innerWidth >= 800)
        }

        window.addEventListener('resize', handleResize)

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={styles['app']} data-testid={'app'}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <CurrentSizeProvider
                    value={{
                        isWidthGreaterEqualThan1200:
                            isWidthGreaterEqualThan1200,
                        isWidthGreaterEqualThan600: isWidthGreaterEqualThan600,
                        isWidthGreaterEqualThan800: isWidthGreaterEqualThan800,
                    }}
                >
                    <main className="container-fluid p-0 h-100 initial-height ">
                        {/* <LoaderForeground /> */}
                        <DashboardLayout />
                    </main>
                </CurrentSizeProvider>
            </ErrorBoundary>
        </div>
    )
}

export default App
