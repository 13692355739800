import { useState, useEffect, useCallback } from 'react'

const useNumberCounter = (targetNumber, shouldAnimate) => {
    const [currentNumber, setCurrentNumber] = useState(0)
    const [animationSpeed, setAnimationSpeed] = useState(150)
    const animationTime = 500 // 1000 milliseconds (1 second)

    const updateNumber = useCallback(() => {
        if (currentNumber < targetNumber) {
            const diff = targetNumber - currentNumber
            const steps = Math.max(1, Math.abs(diff))
            const stepSize = diff / steps

            setTimeout(() => {
                setAnimationSpeed(animationTime / steps)
                setCurrentNumber(currentNumber + stepSize)
            }, animationSpeed)
        }
    }, [currentNumber, targetNumber, animationSpeed, animationTime])

    useEffect(() => {
        if (shouldAnimate) {
            updateNumber(300)
        }
    }, [shouldAnimate, updateNumber])

    return currentNumber
}

export default useNumberCounter
