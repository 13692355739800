/**
 * TopbarMenu.jsx
 *
 * This component is an input that allows users to enter text inputs
 *
 * @author Ricardo Velhinho
 * @version 1.0
 */

//import icoProfile from '../../../assets/images/icoProfile.svg';
import React, { useCallback, useEffect, useRef, useState } from 'react'

import imageStarkdataLogo from '../../../assets/images/logoStarkdata.svg'
import icoBanking from '../../../assets/images/icoBanking2.svg'
import icoHealthcare from '../../../assets/images/icoHealthcare.svg'
import icoRetail from '../../../assets/images/icoRetail.svg'
import icoNewsletter from '../../../assets/images/icoNewsletter.svg'
import styles from './TopbarMenu.module.scss'
import { useTranslation } from 'react-i18next'
import { useMainScroll } from '../../../providers/main-scroll-context'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useCurrentSize } from '../../../providers/current-size-context'
import { NavigationOption } from '../../common/NavigationOption/NavigationOption'
import { NavigationMobileOption } from '../../common/NavigationMobileOption/NavigationMobileOption'
import { HighlightButton } from '../../common/HighlightButton/HighlightButton'
import Scrollable from '../../navigation/Scrollable'

/**
 * TopbarMenu functional component
 * @returns {JSX.Element} - returns JSX element of a text input
 */

export const TopbarMenu = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [isPlatformHovered, setIsPlatformHovered] = useState(false)
    const [isResourcesHovered, setIsResourcesHovered] = useState(false)
    const [hasHovered, setHasHovered] = useState(false)
    const [hasScrolled, setHasScrolled] = useState(false)
    const { isScrollUndefined } = useMainScroll()
    const [toggleMenu, setToggleMenu] = useState(false)
    const {
        isWidthGreaterEqualThan600,
        isWidthGreaterEqualThan800,
        isWidthGreaterEqualThan1200,
    } = useCurrentSize()
    const menuRef = useRef(null)
    const [isHovered, setIsHovered] = React.useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(undefined)

    const handleOutsideClick = useCallback(
        (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                event.stopPropagation()
                setToggleMenu(false)
            }
        },
        [menuRef, setToggleMenu]
    )

    useEffect(() => {
        if (toggleMenu) {
            document.addEventListener('mousedown', handleOutsideClick, true)
        } else {
            document.removeEventListener('mousedown', handleOutsideClick, true)
        }
    }, [handleOutsideClick, toggleMenu])

    useEffect(() => {
        if (!isScrollUndefined) {
            setHasScrolled(true)
        } else {
            setHasScrolled(false)
        }
    }, [isScrollUndefined]) // Empty dependency array means the effect will run only once after initial render

    return (
        <>
            <div
                onMouseEnter={() => setHasHovered(true)}
                onMouseLeave={() => setHasHovered(false)}
                className={
                    hasScrolled || hasHovered || toggleMenu
                        ? `${styles['topbar-menu-container']} ${styles['topbar-menu-container--scrolled']}`
                        : `${styles['topbar-menu-container']}`
                }
            >
                <div
                    className="row mx-0 w-100 h-100"
                    style={{ maxWidth: '128rem', padding: '0 2rem' }}
                >
                    <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                        <img
                            loading="lazy"
                            className={`${styles['topbar-menu-container__logo']}`}
                            src={
                                location.pathname !== '/'
                                    ? imageStarkdataLogo
                                    : imageStarkdataLogo
                            }
                            onClick={() => navigate('/')}
                            alt="Starkdata logo"
                        />
                    </div>
                    {isWidthGreaterEqualThan1200 && (
                        <>
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                    <NavigationOption
                                        isHovered={isHovered}
                                        onSetIsHovered={setIsHovered}
                                        url={'/platform'}
                                        text={t('topbarMenu.platform')}
                                        notClickable
                                        onMouseEnter={() =>
                                            setIsPlatformHovered(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsPlatformHovered(false)
                                        }
                                    >
                                        {isPlatformHovered && (
                                            <div
                                                className={`${styles['topbar-menu-container__dropdown-container']}`}
                                            >
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginTop: '4rem',
                                                        marginBottom: '5rem',
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100 d-flex justify-content-center">
                                                            <div className="col-auto px-0 justify-content-center align-items-center">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription1'
                                                                    )}
                                                                </span>

                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                    style={{
                                                                        color: '#2c3c8d',
                                                                        fontFamily:
                                                                            'RobotoMedium',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription2'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription3'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100 justify-content-center">
                                                            <div className="col-auto px-0 justify-content-center align-items-center">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription4'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                    style={{
                                                                        color: '#2c3c8d',
                                                                        fontFamily:
                                                                            'RobotoMedium',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription5'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                    style={{
                                                                        color: '#2c3c8d',
                                                                        fontFamily:
                                                                            'RobotoMedium',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription6'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription7'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                    style={{
                                                                        color: '#2c3c8d',
                                                                        fontFamily:
                                                                            'RobotoMedium',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription8'
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__sub-title']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformDescription9'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 justify-content-between"
                                                    style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) => {
                                                            if (isActive) {
                                                                return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                            }
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start`
                                                        }}
                                                        to={
                                                            '/platform/starkdata-apollo-retail'
                                                        }
                                                        onClick={() => {
                                                            setIsPlatformHovered(
                                                                false
                                                            )
                                                        }}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            pointerEvents:
                                                                'all',
                                                        }}
                                                    >
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                                    <div className="col-auto px-0">
                                                                        <img
                                                                            loading="lazy"
                                                                            src={
                                                                                icoRetail
                                                                            }
                                                                            alt=""
                                                                            className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                        />
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                                        >
                                                                            {t(
                                                                                'topbarMenu.platformApollo.title'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                            style={{
                                                                                color: '#ff8900',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'platform.apollo'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                height: '12rem',
                                                                marginBottom:
                                                                    '3rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformApollo.description'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.learnMore'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '1.5rem',
                                                        }}
                                                    ></div>
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) => {
                                                            if (isActive) {
                                                                return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                            }
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start`
                                                        }}
                                                        to={
                                                            '/platform/starkdata-pegasus-banking'
                                                        }
                                                        onClick={() => {
                                                            setIsPlatformHovered(
                                                                false
                                                            )
                                                        }}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            pointerEvents:
                                                                'all',
                                                        }}
                                                    >
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                                    <div className="col-auto px-0">
                                                                        <img
                                                                            loading="lazy"
                                                                            src={
                                                                                icoBanking
                                                                            }
                                                                            alt=""
                                                                            className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                        />
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                                        >
                                                                            {t(
                                                                                'topbarMenu.platformPegasus.title'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                            style={{
                                                                                color: '#ff8900',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'platform.pegasus'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                height: '12rem',
                                                                marginBottom:
                                                                    '3rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformPegasus.description'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.learnMore'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '1.5rem',
                                                        }}
                                                    ></div>
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) => {
                                                            if (isActive) {
                                                                return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                            }
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-start`
                                                        }}
                                                        to={
                                                            '/platform/starkdata-mercury-healthcare'
                                                        }
                                                        onClick={() => {
                                                            setIsPlatformHovered(
                                                                false
                                                            )
                                                        }}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            pointerEvents:
                                                                'all',
                                                        }}
                                                    >
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                                    <div className="col-auto px-0">
                                                                        <img
                                                                            loading="lazy"
                                                                            src={
                                                                                icoHealthcare
                                                                            }
                                                                            alt=""
                                                                            className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                        />
                                                                    </div>
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                                        >
                                                                            {t(
                                                                                'topbarMenu.platformMercury.title'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                            style={{
                                                                                color: '#ff8900',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'platform.mercury'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                height: '12rem',
                                                                marginBottom:
                                                                    '3rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.platformMercury.description'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.learnMore'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )}
                                    </NavigationOption>

                                    <NavigationOption
                                        isHovered={isHovered}
                                        onSetIsHovered={setIsHovered}
                                        url={'/responsible-ai'}
                                        text={t('topbarMenu.responsibleAi')}
                                    ></NavigationOption>

                                    <NavigationOption
                                        isHovered={isHovered}
                                        onSetIsHovered={setIsHovered}
                                        url={'/about-us'}
                                        text={t('topbarMenu.aboutUs')}
                                    ></NavigationOption>

                                    <NavigationOption
                                        isHovered={isHovered}
                                        onSetIsHovered={setIsHovered}
                                        url={'/resources'}
                                        text={t('topbarMenu.resources')}
                                        onMouseEnter={() =>
                                            setIsResourcesHovered(true)
                                        }
                                        notClickable={true}
                                        onMouseLeave={() =>
                                            setIsResourcesHovered(false)
                                        }
                                    >
                                        {isResourcesHovered && (
                                            <div
                                                className={`${styles['topbar-menu-container__dropdown-container']}`}
                                                style={{
                                                    width: '30rem',
                                                }}
                                            >
                                                <div className="row mx-0 w-100 justify-content-between">
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) => {
                                                            if (isActive) {
                                                                return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                            }
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between`
                                                        }}
                                                        to={
                                                            '/ai-talks-newsletter'
                                                        }
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                        }}
                                                        onClick={() => {
                                                            setIsResourcesHovered(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        <div
                                                            className="row mx-0 w-100 d-flex"
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                                <img
                                                                    loading="lazy"
                                                                    src={
                                                                        icoNewsletter
                                                                    }
                                                                    alt=""
                                                                    className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                />
                                                            </div>
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.newsletter.title'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                                >
                                                                    {t(
                                                                        'topbarMenu.learnMore'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )}
                                    </NavigationOption>
                                </div>
                            </div>
                            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                    <div className="col-auto px-0">
                                        <HighlightButton
                                            onClick={() =>
                                                navigate('/contact-us')
                                            }
                                            text={t('topbarMenu.bookDemo')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {!isWidthGreaterEqualThan1200 && (
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <div className="row mx-0 w-100 h-100 d-flex justify-content-end">
                                <>
                                    {isWidthGreaterEqualThan600 && (
                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                            <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                                <div className="col-auto px-0">
                                                    <HighlightButton
                                                        onClick={() =>
                                                            navigate(
                                                                '/contact-us'
                                                            )
                                                        }
                                                        text={t(
                                                            'topbarMenu.bookDemo'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '1rem' }}
                                    ></div>
                                    <div
                                        className={
                                            toggleMenu
                                                ? `${styles['topbar-menu-container__background']} ${styles['active']}`
                                                : `${styles['topbar-menu-container__background']}`
                                        }
                                    ></div>
                                    <div
                                        className="col-auto h-100 px-0 d-flex justify-content-end align-items-center"
                                        style={{
                                            cursor: 'pointer',
                                            width: '5rem',
                                            height: '5rem',
                                            zIndex: 10,
                                            pointerEvents: toggleMenu && 'none',
                                        }}
                                        onClick={() => {
                                            setToggleMenu(!toggleMenu)
                                        }}
                                    >
                                        <div
                                            alt=""
                                            className={
                                                toggleMenu
                                                    ? `${styles['topbar-menu-container__menu']} ${styles['active']}`
                                                    : `${styles['topbar-menu-container__menu']}`
                                            }
                                        />
                                    </div>

                                    <div
                                        className={
                                            toggleMenu
                                                ? `${styles['topbar-menu-container__menu-container']}`
                                                : `${styles['topbar-menu-container__menu-container']} ${styles['hidden']}`
                                        }
                                        style={{
                                            width: isWidthGreaterEqualThan800
                                                ? '40rem'
                                                : 'calc(100% - 4rem)',
                                        }}
                                        ref={menuRef}
                                    >
                                        <Scrollable>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    padding: '2rem',
                                                }}
                                            >
                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                    <span
                                                        className={`${styles['topbar-menu-container__dropdown-container__mobileDescription']}`}
                                                    >
                                                        {t(
                                                            'topbarMenu.platformDescription'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <NavigationMobileOption
                                                text={t('topbarMenu.platform')}
                                                url={'/platform'}
                                                isDropdownOpen={isDropdownOpen}
                                                onSetIsDropdownOpen={
                                                    setIsDropdownOpen
                                                }
                                                onSetToggleMenu={setToggleMenu}
                                                notClickable
                                                onMouseEnter={() =>
                                                    setIsPlatformHovered(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsPlatformHovered(false)
                                                }
                                            >
                                                <NavLink
                                                    className={({
                                                        isActive,
                                                    }) => {
                                                        if (isActive) {
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                        }
                                                        return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between`
                                                    }}
                                                    to={
                                                        '/platform/starkdata-apollo-retail'
                                                    }
                                                    onClick={() => {
                                                        setToggleMenu(false)
                                                        setIsPlatformHovered(
                                                            false
                                                        )
                                                    }}
                                                    style={{
                                                        textDecoration: 'none',
                                                        pointerEvents: 'all',
                                                    }}
                                                >
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom:
                                                                '2rem',
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <img
                                                                loading="lazy"
                                                                src={icoRetail}
                                                                alt=""
                                                                className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                style={{
                                                                    width: '3rem',
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '1rem',
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformApollo.title'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                style={{
                                                                    color: '#ff8900',
                                                                }}
                                                            >
                                                                {t(
                                                                    'platform.apollo'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformApollo.description'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.learnMore'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                                <NavLink
                                                    className={({
                                                        isActive,
                                                    }) => {
                                                        if (isActive) {
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                        }
                                                        return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between`
                                                    }}
                                                    to={
                                                        '/platform/starkdata-pegasus-banking'
                                                    }
                                                    onClick={() => {
                                                        setToggleMenu(false)
                                                        setIsPlatformHovered(
                                                            false
                                                        )
                                                    }}
                                                    style={{
                                                        textDecoration: 'none',
                                                        pointerEvents: 'all',
                                                    }}
                                                >
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <img
                                                                loading="lazy"
                                                                src={icoBanking}
                                                                alt=""
                                                                className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                style={{
                                                                    width: '3rem',
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '1rem',
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformPegasus.title'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                style={{
                                                                    color: '#ff8900',
                                                                }}
                                                            >
                                                                {t(
                                                                    'platform.pegasus'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformPegasus.description'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.learnMore'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                                <NavLink
                                                    className={({
                                                        isActive,
                                                    }) => {
                                                        if (isActive) {
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                        }
                                                        return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between`
                                                    }}
                                                    to={
                                                        '/platform/starkdata-mercury-healthcare'
                                                    }
                                                    onClick={() => {
                                                        setToggleMenu(false)
                                                        setIsPlatformHovered(
                                                            false
                                                        )
                                                    }}
                                                    style={{
                                                        textDecoration: 'none',
                                                        pointerEvents: 'all',
                                                    }}
                                                >
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <img
                                                                loading="lazy"
                                                                src={
                                                                    icoHealthcare
                                                                }
                                                                alt=""
                                                                className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                style={{
                                                                    width: '3rem',
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '1rem',
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformMercury.title'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__subTitle']}`}
                                                                style={{
                                                                    color: '#ff8900',
                                                                }}
                                                            >
                                                                {t(
                                                                    'platform.mercury'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__description']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.platformMercury.description'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.learnMore'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </NavigationMobileOption>
                                            <NavigationMobileOption
                                                text={t(
                                                    'topbarMenu.responsibleAi'
                                                )}
                                                url={'/responsible-ai'}
                                                isDropdownOpen={isDropdownOpen}
                                                onSetToggleMenu={setToggleMenu}
                                                onSetIsDropdownOpen={
                                                    setIsDropdownOpen
                                                }
                                            />

                                            <NavigationMobileOption
                                                text={t('topbarMenu.aboutUs')}
                                                url={'/about-us'}
                                                isDropdownOpen={isDropdownOpen}
                                                onSetToggleMenu={setToggleMenu}
                                                onSetIsDropdownOpen={
                                                    setIsDropdownOpen
                                                }
                                            />
                                            <NavigationMobileOption
                                                text={t('topbarMenu.resources')}
                                                isLastOption
                                                url={'/resources'}
                                                isDropdownOpen={isDropdownOpen}
                                                onSetToggleMenu={setToggleMenu}
                                                onSetIsDropdownOpen={
                                                    setIsDropdownOpen
                                                }
                                                notClickable
                                                onMouseEnter={() =>
                                                    setIsPlatformHovered(true)
                                                }
                                                onMouseLeave={() =>
                                                    setIsPlatformHovered(false)
                                                }
                                            >
                                                <NavLink
                                                    className={({
                                                        isActive,
                                                    }) => {
                                                        if (isActive) {
                                                            return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between ${styles['topbar-menu-container__dropdown-container__option--active']}`
                                                        }
                                                        return `col ${styles['topbar-menu-container__dropdown-container__option']} d-flex flex-column justify-content-between`
                                                    }}
                                                    to={'/ai-talks-newsletter'}
                                                    onClick={() => {
                                                        setToggleMenu(false)
                                                        setIsPlatformHovered(
                                                            false
                                                        )
                                                    }}
                                                    style={{
                                                        textDecoration: 'none',
                                                        pointerEvents: 'all',
                                                    }}
                                                >
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom:
                                                                '2rem',
                                                        }}
                                                    >
                                                        <div className="col-auto px-0">
                                                            <img
                                                                loading="lazy"
                                                                src={
                                                                    icoNewsletter
                                                                }
                                                                alt=""
                                                                className={`${styles['topbar-menu-container__dropdown-container__option__icon']}`}
                                                                style={{
                                                                    width: '3rem',
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '1rem',
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__text']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.newsletter.title'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['topbar-menu-container__dropdown-container__link']}`}
                                                            >
                                                                {t(
                                                                    'topbarMenu.learnMore'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </NavigationMobileOption>
                                        </Scrollable>
                                    </div>
                                </>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

TopbarMenu.propTypes = {}
