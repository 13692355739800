import i18n, { use } from 'i18next'
//import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend'

use(XHR)
    // .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'navigator',
                'htmlTag',
            ],
            caches: ['cookie'],
        },
        ns: ['pages'],
        backend: {
            loadPath: '/assets/translations/{{ns}}/{{lng}}.json',
        },
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
