import React from 'react'
import styles from './CookiesBanner.module.scss'
import cookiePolicy from '../../../assets/documents/cookiePolicy.pdf'

import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { TerciaryButton } from '../TerciaryButton/TerciaryButton'
import { SecondaryButton } from '../SecondaryButton/SecondaryButton'
import { useTranslation } from 'react-i18next'
import { useCurrentSize } from '../../../providers/current-size-context'

export const CookiesBanner = ({ onClickConfigure, onAccept, onReject }) => {
    const { isWidthGreaterEqualThan800 } = useCurrentSize()
    const { t } = useTranslation()
    return (
        <div
            className={`${styles['cookies-banner-container']}`}
            style={{
                width: isWidthGreaterEqualThan800
                    ? '60rem'
                    : 'calc(100% - 4rem)',
            }}
        >
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['cookies-banner-container__title']}`}
                    >
                        {t('cookies.ourCookies')}
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['cookies-banner-container__text']}`}
                    >
                        {t('cookies.summary')}
                    </span>
                    <span
                        className={`${styles['cookies-banner-container__text']}`}
                        style={{
                            color: 'rgb(6, 164, 231)',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onClick={() => window.open(cookiePolicy, '_blank')}
                    >
                        {t('cookies.cookiePolicyText')}
                    </span>
                    .
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col-auto px-0'
                            : 'col-12 px-0'
                    }
                    style={{
                        marginBottom: isWidthGreaterEqualThan800 ? '0' : '2rem',
                    }}
                >
                    <PrimaryButton
                        text={t('cookies.acceptAll')}
                        onClick={() => onAccept()}
                    />
                </div>
                {isWidthGreaterEqualThan800 && (
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                )}
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col-auto px-0'
                            : 'col-12 px-0'
                    }
                    style={{
                        marginBottom: isWidthGreaterEqualThan800 ? '0' : '2rem',
                    }}
                >
                    <SecondaryButton
                        text={t('cookies.reject')}
                        onClick={() => onReject()}
                    />
                </div>
                {isWidthGreaterEqualThan800 && (
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                )}
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col-auto px-0'
                            : 'col-12 px-0'
                    }
                >
                    <TerciaryButton
                        text={t('cookies.configure')}
                        onClick={onClickConfigure}
                    />
                </div>
            </div>
        </div>
    )
}
