import React from 'react'
import styles from './NavigationOption.module.scss'
import icoDropdownArrow from '../../../assets/images/dropdown-arrow.svg'
import { NavLink } from 'react-router-dom'

export const NavigationOption = ({
    text,
    url,
    onMouseEnter,
    onMouseLeave,
    children,
    notClickable,
    isHovered,
    onSetIsHovered,
}) => {
    return (
        <div
            className="col-auto d-flex justify-content-center align-items-center h-100"
            onMouseEnter={() => {
                if (onMouseEnter) onMouseEnter()
                onSetIsHovered(url)
            }}
            onMouseLeave={() => {
                if (onMouseLeave) onMouseLeave()
                onSetIsHovered(undefined)
            }}
        >
            {children && (
                <div
                    className={`d-flex justify-content-center align-items-center ${styles['navigation-option-container']}`}
                    style={{
                        cursor: notClickable ? 'default' : 'pointer',
                    }}
                    to={url}
                    onClick={(e) => {
                        if (notClickable) {
                            e.preventDefault()
                        }
                    }}
                >
                    <>
                        {children}
                        <span
                            className={
                                isHovered === url
                                    ? `${styles['navigation-option-container__text']} ${styles['navigation-option-container__text--hovered']}`
                                    : `${styles['navigation-option-container__text']}`
                            }
                            style={{
                                marginRight: '1rem',
                            }}
                        >
                            {text}
                        </span>
                        {onMouseEnter && (
                            <img
                                src={icoDropdownArrow}
                                loading="lazy"
                                className={
                                    isHovered === url
                                        ? `${styles['navigation-option-container__icon']} ${styles['navigation-option-container__icon--active']}`
                                        : `${styles['navigation-option-container__icon']}`
                                }
                                style={{
                                    filter:
                                        isHovered === url
                                            ? 'brightness(0) saturate(100%) invert(71%) sepia(49%) saturate(7475%) hue-rotate(166deg) brightness(97%) contrast(95%)'
                                            : 'none',
                                    transform:
                                        isHovered === url
                                            ? 'rotate(180deg)'
                                            : 'rotate(0deg)',
                                }}
                                alt=""
                            />
                        )}
                    </>
                </div>
            )}
            {!children && (
                <NavLink
                    className={({ isActive }) => {
                        let activeStyle = ''
                        if (isActive) {
                            activeStyle = 'navigation-option-container--active'
                        }
                        return `d-flex justify-content-center align-items-center ${styles['navigation-option-container']} ${styles[activeStyle]}`
                    }}
                    style={{
                        cursor: notClickable ? 'default' : 'pointer',
                    }}
                    to={url}
                    onClick={(e) => {
                        if (notClickable) {
                            e.preventDefault()
                        }
                    }}
                >
                    {({ isActive }) => (
                        <>
                            <span
                                className={
                                    isHovered === url
                                        ? `${styles['navigation-option-container__text']} ${styles['navigation-option-container__text--hovered']}`
                                        : `${styles['navigation-option-container__text']}`
                                }
                                style={{
                                    marginRight: '1rem',
                                }}
                            >
                                {text}
                            </span>
                            {onMouseEnter && (
                                <img
                                    src={icoDropdownArrow}
                                    loading="lazy"
                                    className={
                                        isActive
                                            ? `${styles['navigation-option-container__icon']} ${styles['navigation-option-container__icon--active']}`
                                            : `${styles['navigation-option-container__icon']}`
                                    }
                                    style={{
                                        filter:
                                            isHovered === url || isActive
                                                ? 'brightness(0) saturate(100%) invert(71%) sepia(49%) saturate(7475%) hue-rotate(166deg) brightness(97%) contrast(95%)'
                                                : 'none',
                                        transform:
                                            isHovered === url
                                                ? 'rotate(180deg)'
                                                : 'rotate(0deg)',
                                    }}
                                    alt=""
                                />
                            )}
                        </>
                    )}
                </NavLink>
            )}
        </div>
    )
}
