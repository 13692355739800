import React from 'react'
import styles from './UseCaseSpecific.module.scss'
import { UseCaseHeader } from '../../common/UseCaseHeader/UseCaseHeader'
import { UseCaseContent } from '../../common/UseCaseContent/UseCaseContent'

export const UseCaseSpecific = React.memo(() => {
    return (
        <div className={`${styles['useCaseSpecificContainer']}`}>
            <UseCaseHeader title={'Title'} subTitle={'SubTitle'} />
            <UseCaseContent title={'Title'} content={'Contenta'} />
        </div>
    )
})
