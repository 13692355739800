/**
 * PrimaryButton is a reusable component that displays a button with customizable text, onClick function, and optional data-testid attribute, isActive and isDisabled.
 * @param {string} text - The text to display on the button(required).
 * @param {function} onClick - The function invoked when the button is clicked(required).
 * @param {string} dataTestId - An optional attribute used for testing purposes.
 * @param {boolean} isActive - An optional attribute used to set the button to an active state.
 * @param {boolean} isDisabled - An optional attribute used to set the button to a disabled state.
 * @example ./PrimaryButton.md
 * @returns A PrimaryButton component with the provided properties.
 */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './PrimaryButton.module.scss'

export const PrimaryButton = ({
    icon,
    text,
    onClick,
    dataTestId,
    isActive = false,
    isDisabled = false,
    hasShadow,
    isRound,
    disappearCondition,
    isInsideBorder,
}) => {
    // Combine styles based on button activity and disabled status.
    const cursorStyle = onClick ? `${styles['cursor-pointer']}` : ``
    const buttonClassName = `${styles['primary-button-container']} ${
        isActive ? styles['active'] : ''
    } ${isDisabled ? styles['disabled'] : ''}`

    return (
        // Display button with provided properties
        <div
            data-testid={dataTestId}
            onClick={onClick}
            className={buttonClassName + ' ' + cursorStyle}
            disabled={isDisabled}
            style={{
                padding: '0.5rem 3rem',
                borderRadius: isRound ? '1.2rem' : '0.6rem',
                borderTopLeftRadius: !isInsideBorder
                    ? isRound
                        ? '1.2rem'
                        : '0.6rem'
                    : '0rem',
                borderBottomLeftRadius: !isInsideBorder
                    ? isRound
                        ? '1.2rem'
                        : '0.6rem'
                    : '0rem',
                boxShadow: hasShadow
                    ? '2px 2px 5px rgba(0, 0, 0, 0.25)'
                    : undefined,
            }}
        >
            {icon && (
                <img
                    src={icon}
                    alt="plus"
                    loading="lazy"
                    className={styles['primary-button-container__icon']}
                    style={text ? { marginRight: '0.5rem' } : {}}
                />
            )}
            <span
                data-testid={'button-text'}
                className={styles['primary-button-container__text']}
                style={{
                    animation: disappearCondition
                        ? `${styles['disappear']} 0.6s ease-in-out forwards`
                        : undefined,
                    animationFillMode: 'forwards',
                }}
            >
                {text}
            </span>
        </div>
    )
}

// PropTypes validation for PrimaryButton properties
PrimaryButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
}
