import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Scrollable.module.scss'

export const Scrollable = React.memo(
    ({
        children,
        direction,
        initialScrollOffset,
        isSmooth,
        isDraggable,
        isMainScroll,
    }) => {
        const location = useLocation()
        const myRef = useRef(null)
        const isDragging = useRef(false)
        const startPos = useRef({ x: 0, y: 0 })
        const scrollPos = useRef({ x: 0, y: 0 }) // Add this to track the scroll position
        const hasDragged = useRef(false)

        useEffect(() => {
            if (isMainScroll) {
                myRef.current.scrollTo(0, 0)
            }
        }, [location.pathname, isMainScroll])

        useEffect(() => {
            const currentRef = myRef.current

            const handleDragStart = (event) => {
                if (!isDraggable) return

                isDragging.current = true
                hasDragged.current = false
                startPos.current = {
                    x:
                        'touches' in event
                            ? event.touches[0].clientX
                            : event.clientX,
                    y:
                        'touches' in event
                            ? event.touches[0].clientY
                            : event.clientY,
                }
                scrollPos.current = {
                    x: currentRef.scrollLeft,
                    y: currentRef.scrollTop,
                }

                currentRef.style.cursor = 'grabbing'
                currentRef.style.userSelect = 'none'
            }

            const handleMove = (event) => {
                if (!isDraggable || !isDragging.current) return

                const currentX =
                    'touches' in event
                        ? event.touches[0].clientX
                        : event.clientX
                const currentY =
                    'touches' in event
                        ? event.touches[0].clientY
                        : event.clientY

                const deltaX = currentX - startPos.current.x
                const deltaY = currentY - startPos.current.y

                if (direction === 'horizontal') {
                    currentRef.scrollLeft = scrollPos.current.x - deltaX
                } else {
                    currentRef.scrollTop = scrollPos.current.y - deltaY
                }

                hasDragged.current = true

                event.preventDefault()
            }

            const handleClickCapture = (event) => {
                if (hasDragged.current) {
                    event.stopPropagation()
                    event.preventDefault()
                }
            }

            const handleDragEnd = () => {
                if (!isDraggable) return

                isDragging.current = false

                currentRef.style.cursor = ''
                currentRef.style.userSelect = ''
            }

            if (currentRef && isDraggable) {
                currentRef.addEventListener('mousedown', handleDragStart)
                currentRef.addEventListener('click', handleClickCapture, true)
                window.addEventListener('mousemove', handleMove)
                window.addEventListener('mouseup', handleDragEnd)
            }

            return () => {
                if (currentRef && isDraggable) {
                    currentRef.removeEventListener('mousedown', handleDragStart)
                    currentRef.removeEventListener(
                        'click',
                        handleClickCapture,
                        true
                    )
                }
                window.removeEventListener('mousemove', handleMove)
                window.removeEventListener('mouseup', handleDragEnd)
            }
        }, [isDraggable])

        // ... other useEffect hooks

        const containerStyle = {
            overflowX: direction === 'horizontal' ? 'auto' : 'hidden',
            overflowY: direction === 'horizontal' ? 'hidden' : 'auto',
            scrollBehavior: isSmooth ? 'smooth' : 'auto',
            cursor: isDraggable ? 'grab' : 'default',
        }

        return (
            <div className={styles['scrollable-container']}>
                <div
                    className={styles['scrollable-container__container']}
                    ref={myRef}
                    style={containerStyle}
                >
                    {children}
                </div>
            </div>
        )
    }
)
