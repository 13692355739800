import React from 'react';
import Scrollable from '../../navigation/Scrollable';
import TopbarMenu from '../../pages/TopbarMenu';
import styles from './DashboardLayout.module.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from '../Home/Home';
import { Footer } from '../Footer/Footer';
import { AboutUs } from '../AboutUs/AboutUs';
import { Industry } from '../Industry/Industry';
import { UseCaseSpecific } from '../UseCaseSpecific/UseCaseSpecific';
import { Newsletter } from '../Newsletter/Newsletter';
import { ResponsibleAI } from '../ResponsibleAI/ResponsibleAI';
import { CookiesBanner } from '../../common/CookiesBanner/CookiesBanner';
import { CookiesConfigureBanner } from '../../common/CookiesConfigureBanner/CookiesConfigureBanner';
import { ContactUs } from '../ContactUs/ContactUs';
import useCookie from '../../../hooks/useCookie';
import { SubscribeNewsletter } from '../../common/SubscribeNewsletter/SubscribeNewsletter';
import { JoinEmailList } from '../JoinEmailList/JoinEmailList';
import { WhitePaperEnterpriseAi } from '../WhitePaperEnterpriseAi/WhitePaperEnterpriseAi';

export const DashboardLayout = () => {
    const location = useLocation();
    const [isCookiesSet, setIsCookiesSet] = useCookie('cookiesSet', 'no');
    const [cookiesPreferences, setCookiesPreferences] = useCookie(
        'cookiesPreferences',
        JSON.stringify({
            essential: 'yes',
            analytics: 'no',
            functional: 'no',
            marketing: 'no'
        })
    );
    const [isCookiesVisible] = React.useState(true);
    const [isCookiesConfigure, setIsCookieConfigure] = React.useState(false);
    return (
        <div
            className={styles['dashboard-layout-container']}
            data-testid={'dashboard-layout'}
        >
            <div
                className={styles['dashboard-layout-container__main-container']}
            >
                <Scrollable isMainScroll>
                    <div className="w-100 h-100 d-flex flex-column">
                        <div
                            className={
                                styles[
                                    'dashboard-layout-container__main-container__header'
                                ]
                            }
                            style={{
                                zIndex:
                                    isCookiesVisible && isCookiesConfigure
                                        ? 9
                                        : 100
                            }}
                        >
                            <TopbarMenu />
                        </div>
                        <div
                            className={`${styles['dashboard-layout-container__main-container__content-container']} flex-grow-1`}
                        >
                            <Routes>
                                <Route
                                    path="/white-paper-enterprise-ai"
                                    element={<WhitePaperEnterpriseAi />}
                                />
                                <Route
                                    path="/join-email-list"
                                    element={<JoinEmailList />}
                                />
                                <Route
                                    path="/contact-us"
                                    element={<ContactUs />}
                                />
                                <Route
                                    path="/platform/:industry"
                                    element={<Industry />}
                                />
                                <Route
                                    path="/responsible-ai"
                                    element={<ResponsibleAI />}
                                />
                                <Route
                                    path="/use-case/:id"
                                    element={<UseCaseSpecific />}
                                />
                                <Route path="/about-us" element={<AboutUs />} />
                                <Route
                                    path="/ai-talks-newsletter"
                                    element={<Newsletter />}
                                />
                                <Route path="/" element={<Home />} />
                            </Routes>
                            {/* ------Routes here-------*/}
                        </div>
                        <>
                            {location.pathname !== '/join-email-list' && (
                                <div
                                    className={
                                        styles[
                                            'dashboard-layout-container__main-container__subscribe-newsletter'
                                        ]
                                    }
                                >
                                    <SubscribeNewsletter />
                                </div>
                            )}
                            <div
                                className={
                                    styles[
                                        'dashboard-layout-container__main-container__footer'
                                    ]
                                }
                                style={{
                                    padding: `5rem 2rem`
                                }}
                            >
                                <Footer />
                            </div>
                        </>
                        {isCookiesConfigure && isCookiesSet === 'no' && (
                            <div
                                className={
                                    styles[
                                        'dashboard-layout-container__main-container__cookie-configure-banner'
                                    ]
                                }
                            >
                                <CookiesConfigureBanner
                                    onAcceptAll={() => {
                                        setIsCookieConfigure(false);
                                        setIsCookiesSet('yes');
                                        setCookiesPreferences(
                                            JSON.stringify({
                                                essential: 'yes',
                                                analytics: 'yes',
                                                functional: 'yes',
                                                marketing: 'yes'
                                            })
                                        );
                                    }}
                                    onSavePreferences={(
                                        essential,
                                        analytics,
                                        functional,
                                        marketing
                                    ) => {
                                        setIsCookieConfigure(false);
                                        setIsCookiesSet('yes');
                                        setCookiesPreferences(
                                            JSON.stringify({
                                                essential: essential
                                                    ? 'yes'
                                                    : 'no',
                                                analytics: analytics
                                                    ? 'yes'
                                                    : 'no',
                                                functional: functional
                                                    ? 'yes'
                                                    : 'no',
                                                marketing: marketing
                                                    ? 'yes'
                                                    : 'no'
                                            })
                                        );
                                    }}
                                />
                            </div>
                        )}
                        {!isCookiesConfigure && isCookiesSet === 'no' && (
                            <div
                                className={
                                    styles[
                                        'dashboard-layout-container__main-container__cookie-banner'
                                    ]
                                }
                            >
                                <CookiesBanner
                                    onClickConfigure={() =>
                                        setIsCookieConfigure(true)
                                    }
                                    onAccept={() => {
                                        setIsCookieConfigure(false);
                                        setIsCookiesSet('yes');
                                        setCookiesPreferences(
                                            JSON.stringify({
                                                essential: 'yes',
                                                analytics: 'yes',
                                                functional: 'yes',
                                                marketing: 'yes'
                                            })
                                        );
                                    }}
                                    onReject={() => {
                                        setIsCookieConfigure(false);
                                        setIsCookiesSet('yes');
                                        setCookiesPreferences(
                                            JSON.stringify({
                                                essential: 'yes',
                                                analytics: 'no',
                                                functional: 'no',
                                                marketing: 'no'
                                            })
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Scrollable>
            </div>
            {/* <Assistant /> */}
        </div>
    );
};
