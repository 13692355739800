import { createContext, useContext } from 'react'

const CurrentSizeContext = createContext({
    isWidthGreaterEqualThan1200: undefined,
    isWidthGreaterEqualThan600: undefined,
    isWidthGreaterEqualThan800: undefined,
    setIsWidthGreaterEqualThan1200: () => {},
    setIsWidthGreaterEqualThan600: () => {},
    setIsWidthGreaterEqualThan800: () => {},
})

export function CurrentSizeProvider({ value, children }) {
    return (
        <CurrentSizeContext.Provider value={value}>
            {children}
        </CurrentSizeContext.Provider>
    )
}

export function useCurrentSize() {
    return useContext(CurrentSizeContext)
}
