import React from 'react'
import PropTypes from 'prop-types'
//import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import styles from './TypeInput.module.scss'
import { useTranslation } from 'react-i18next'

export const TypeInput = React.memo(
    ({ label, value, error, onChangeInput, inputType, name }) => {
        const { t } = useTranslation()
        let containerStyle
        const errorStyle = error
            ? `${styles['type-input-container__input--error']}`
            : ''
        const textAreaStyle =
            inputType === 'container' ? `${styles['textarea']}` : ''
        if (value !== '') {
            containerStyle = `${styles['type-input-container']} ${
                styles[`valued`]
            } ${styles[`${inputType}`]} `
        } else {
            containerStyle = `${styles['type-input-container']} ${
                styles[`${inputType}`]
            } `
        }
        return (
            <React.Fragment>
                <div className={containerStyle}>
                    <label
                        htmlFor={name}
                        className={
                            inputType === 'container'
                                ? `${styles['type-input-container__label']} ${styles['textarea']}`
                                : `${styles['type-input-container__label']}`
                        }
                        style={error ? { color: '#FF0000' } : {}}
                    >
                        {label}
                    </label>
                    {inputType === 'container' && (
                        <textarea
                            autoComplete={'false'}
                            name={name}
                            value={value}
                            id={name}
                            placeholder={''}
                            className={
                                value
                                    ? `${styles['form-control']} ${styles['type-input-container__input']} ${errorStyle} ${textAreaStyle}`
                                    : `${styles['form-control']} ${styles['type-input-container__input']} ${styles['placeholder']} ${errorStyle} ${textAreaStyle}`
                            }
                            style={{ resize: 'none' }}
                            onChange={(e) => onChangeInput(e.target.value)}
                        />
                    )}
                    {inputType !== 'container' && (
                        <input
                            autoComplete={'false'}
                            name={name}
                            value={value}
                            id={name}
                            placeholder={''}
                            className={
                                value
                                    ? `${styles['form-control']} ${styles['type-input-container__input']} ${errorStyle}`
                                    : `${styles['form-control']} ${styles['type-input-container__input']} ${styles['placeholder']} ${errorStyle}`
                            }
                            onChange={(e) => onChangeInput(e.target.value)}
                        />
                    )}
                </div>
                {error && (
                    <div className={`${styles['type-input-error']}`}>
                        {t(`errors:${error}`)}
                    </div>
                )}
            </React.Fragment>
        )
    }
)

TypeInput.propTypes = {
    /**
     * Boolean to disable input if true
     */
    disabled: PropTypes.bool,
    /**
     * Boolean to display icon in right side inside input
     */
    edit: PropTypes.bool,
    /**
     * String to display error below the input
     */
    error: PropTypes.string,
    /**
     * String to display input title
     */
    label: PropTypes.string,
    /**
     * String to match variable containing all the other attributes
     */
    name: PropTypes.string.isRequired,
    /**
     * Boolean to display text as a password or normal text
     */
    hidden: PropTypes.bool,
    /**
     * Function to change hidden prop to opposite
     */
    onClickHiddenIcon: PropTypes.func,
    /**
     * Function to change value to display inside input
     */
    onChangeInput: PropTypes.func.isRequired,
    /**
     * Function to change value to display inside input
     */
    onKeyPress: PropTypes.func,
    /**
     * Boolean to display password icon on the right inside input
     */
    password: PropTypes.bool,
    /**
     * String to contain input inside
     */
    value: PropTypes.string.isRequired,
}
