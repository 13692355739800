import { createContext, useContext } from 'react'

const MainScrollContext = createContext({
    mainScrollRef: undefined,
    isScrollUndefined: undefined,
})

export function MainScrollProvider({ value, children }) {
    return (
        <MainScrollContext.Provider value={value}>
            {children}
        </MainScrollContext.Provider>
    )
}

export function useMainScroll() {
    return useContext(MainScrollContext)
}
