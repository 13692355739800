import { useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (!isIntersecting) {
                    setTimeout(() => {
                        setIntersecting(entry.isIntersecting)
                    }, 300)
                }
            }),
        [isIntersecting]
    )

    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [observer, ref])

    return isIntersecting
}
