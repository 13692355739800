import React from 'react'
import ReactDOM from 'react-dom/client'
import i18n from './configs/i18nextConf.js'
// --------Bootstrap CSS----------
import 'bootstrap/dist/css/bootstrap.min.css'
// -------Bootstrap Bundle JS-------
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'event-source-polyfill/src/eventsource.min.js'
import './index.scss'
import './assets/typography.scss'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
