import React, { useState } from 'react';
import styles from './Industry.module.scss';
import imageMockupDemo from '../../../assets/images/imageMockupDemo.png';

import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import imageApollo from '../../../assets/images/imageRetail.jpg';
import imagePegasus from '../../../assets/images/imageBanking.jpg';
import imageMercury from '../../../assets/images/imageHealthcare.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../common/PrimaryButton/PrimaryButton';
import { CardCarousel } from '../../common/CardCarousel/CardCarousel';
import { TypeInput } from '../../common/TypeInput/TypeInput';
import { HighlightButton } from '../../common/HighlightButton/HighlightButton';
import icoPatientLifecycleManagement from '../../../assets/images/icoPatientLifecycleManagement.svg';
import icoHealthcareManagement from '../../../assets/images/icoHealthcareManagement.svg';
import icoMedicationAnalysis from '../../../assets/images/icoMedicationAnalysis.svg';
import icoSalesTeamManagement from '../../../assets/images/icoSalesTeamManagement.svg';
import icoSalesForecasting from '../../../assets/images/icoSalesForecast.svg';
import icoCustomerRetention from '../../../assets/images/icoCustomerRetention.svg';
import icoBank from '../../../assets/images/icoBank.svg';
import icoLupa from '../../../assets/images/icoLupa.svg';
import icoSentimentAnalysis from '../../../assets/images/icoSentimentAnalysis.svg';
import { useCasesAll } from '../../../constants/useCases';
import useOnScreen from '../../../hooks/useOnScreen';
import backgroundSection from '../../../assets/images/backgroundSection.svg';
import { useCurrentSize } from '../../../providers/current-size-context';
import { SelectInput } from '../../common/SelectInput/SelectInput';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import axios from 'axios';
import { emailRegex } from '../../../utils/error';

export const Industry = () => {
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize();
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [lastName, setLastName] = useState('');
    const [details, setDetails] = useState('');
    const [email, setEmail] = useState('');
    const cardsRef = React.useRef();
    const cardsVisible = useOnScreen(cardsRef);
    const demoRef = React.useRef();
    const demoVisible = useOnScreen(demoRef);
    const [isContacted, setIsContacted] = useState(false);
    const [useCases] = useState([...useCasesAll]);
    const [isCheckboxSelectedForMarketing, setIsCheckboxSelectedForMarketing] =
        useState(false);
    const handleClickUseCase = (useCase, index) => {
        navigate(`/use-case/${index}`, {
            state: { useCase }
        });
    };
    const handleContactUs = () => {
        if (!firstName.trim()) {
            setShowWarning('Fill in the first name');
            return false;
        } else if (!lastName.trim()) {
            setShowWarning('Fill in the last name');
            return false;
        } else if (!email.trim()) {
            setShowWarning('Fill in the email');
            return false;
        } else if (!emailRegex.test(email)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!details.trim()) {
            setShowWarning('Fill in the details');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms');
            return false;
        }

        setShowWarning(false);
        setIsContacted(true);
        return true;
    };
    const handleSendEmail = async (
        firstName,
        lastName,
        email,
        selectedValue,
        details
    ) => {
        var bodyFormData = new FormData();
        // Set the SendGrid API key
        // sgMail.setApiKey('YOUR_SENDGRID_API_KEY')

        // Prepare the email content
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Industry',
            text: `Hi, my name is ${firstName} ${lastName}, my email is ${email}, and I am interested in the industry of ${selectedValue}. ${details}`
        };
        bodyFormData.append('name', firstName + ' ' + lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        // Send the email
        // sgMail
        //     .send(msg)
        //     .then((response) => {
        //         console.log('Email sent successfully!', response)
        //     })
        //     .catch((error) => {
        //         console.error('There was an error sending the email.', error)
        //     })
    };

    return (
        <div className={`${styles['industry-container']}`}>
            <div
                className="row mx-0 w-100 position-relative"
                style={{
                    maxWidth: `128rem`,
                    padding: '0 2rem',
                    paddingTop: isWidthGreaterEqualThan800
                        ? 'calc(80px + 10rem)'
                        : 'calc(80px + 5rem)',
                    paddingBottom: isWidthGreaterEqualThan800 ? '10rem' : '5rem'
                }}
            >
                <div className="col px-0">
                    <div className="row mx-0 w-100">
                        <div
                            className="col h-100 d-flex flex-column justify-content-center"
                            style={{
                                background: 'transparent',
                                zIndex: 0
                            }}
                        >
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span
                                        className={`${styles['industry-container__title']}`}
                                    >
                                        {t(`industry.${params.industry}.title`)}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: '2rem'
                                }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['industry-container__industry-text']}`}
                                    >
                                        {t(`platform.${params.industry}`)}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '5rem' }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['industry-container__subTitle']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.subTitle`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-start align-items-center">
                                <div className="col-auto px-0 h-100">
                                    <HighlightButton
                                        text={t(`button.contactUs`)}
                                        onClick={() => navigate('/contact-us')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '5rem' }}
                        ></div>
                        <div
                            className={
                                isWidthGreaterEqualThan800
                                    ? 'col-7 px-0 d-flex justify-content-center align-items-center'
                                    : 'col-12 px-0 d-flex justify-content-center align-items-center'
                            }
                            style={{
                                height: '40rem',
                                marginTop: isWidthGreaterEqualThan800
                                    ? 0
                                    : '5rem',
                                backgroundImage:
                                    params.industry ===
                                    'starkdata-apollo-retail'
                                        ? `url(${imageApollo})`
                                        : params.industry ===
                                          'starkdata-mercury-healthcare'
                                        ? `url(${imageMercury})`
                                        : `url(${imagePegasus})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '0.8rem',
                                boxShadow: '0px 0px 10px 0px #cee2f1',
                                zIndex: 0
                            }}
                        ></div>
                    </div>
                </div>
            </div>

            <div
                className="row mx-0 w-100 position-relative d-flex justify-content-center"
                style={{
                    background: `url(${backgroundSection})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div className="col-auto px-0">
                    <div
                        className="row mx-0 w-100"
                        style={{ maxWidth: `140rem`, padding: '5rem 2rem' }}
                    >
                        <div
                            className={
                                isWidthGreaterEqualThan800 ? 'col' : 'col-12'
                            }
                            style={{
                                backgroundColor: '#ffffff',
                                padding: '2rem',
                                borderRadius: '0.8rem',
                                boxShadow: '0px 0px 10px 0px #cee2f1',
                                marginBottom: isWidthGreaterEqualThan800
                                    ? 0
                                    : '5rem'
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem', height: '8rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <img
                                        loading="lazy"
                                        style={{
                                            width: '7.5rem'
                                        }}
                                        src={
                                            params.industry ===
                                            'starkdata-apollo-retail'
                                                ? icoSalesForecasting
                                                : params.industry ===
                                                  'starkdata-mercury-healthcare'
                                                ? icoPatientLifecycleManagement
                                                : icoBank
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureTitle']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureTitle1`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureText']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureText1`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isWidthGreaterEqualThan800 && (
                            <div
                                className="col-auto"
                                style={{ padding: '0 4rem' }}
                            ></div>
                        )}
                        <div
                            className={
                                isWidthGreaterEqualThan800 ? 'col' : 'col-12'
                            }
                            style={{
                                backgroundColor: '#ffffff',
                                padding: '2rem',
                                borderRadius: '0.8rem',
                                boxShadow: '0px 0px 10px 0px #cee2f1',
                                marginBottom: isWidthGreaterEqualThan800
                                    ? 0
                                    : '5rem'
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem', height: '8rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <img
                                        loading="lazy"
                                        style={{
                                            width: '7.5rem'
                                        }}
                                        src={
                                            params.industry ===
                                            'starkdata-apollo-retail'
                                                ? icoLupa
                                                : params.industry ===
                                                  'starkdata-mercury-healthcare'
                                                ? icoHealthcareManagement
                                                : icoCustomerRetention
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureTitle']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureTitle2`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureText']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureText2`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isWidthGreaterEqualThan800 && (
                            <div
                                className="col-auto"
                                style={{ padding: '0 4rem' }}
                            ></div>
                        )}
                        <div
                            className={
                                isWidthGreaterEqualThan800 ? 'col' : 'col-12'
                            }
                            style={{
                                backgroundColor: '#ffffff',
                                padding: '2rem',
                                borderRadius: '0.8rem',
                                boxShadow: '0px 0px 10px 0px #cee2f1'
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem', height: '8rem' }}
                            >
                                <div className="col px-0  d-flex justify-content-center align-items-center">
                                    <img
                                        loading="lazy"
                                        style={{
                                            width: '7.5rem'
                                        }}
                                        src={
                                            params.industry ===
                                            'starkdata-apollo-retail'
                                                ? icoSalesTeamManagement
                                                : params.industry ===
                                                  'starkdata-mercury-healthcare'
                                                ? icoMedicationAnalysis
                                                : icoSentimentAnalysis
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureTitle']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureTitle3`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['industry-container__featureText']}`}
                                    >
                                        {t(
                                            `industry.${params.industry}.featureText3`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: `4rem 0`
                }}
            >
                <div className="col px-0">
                    <div className="row mx-0 w-100">
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['industry-container__useCasesTitle']}`}
                                style={{ color: '#2c3c8d' }}
                            >
                                {t(`home.useCases.title`)}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '4rem', padding: '0 2rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['industry-container__useCasesSubTitle']}`}
                            >
                                {t(`home.useCases.subTitle`)}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        ref={cardsRef}
                        style={{
                            opacity: 0,
                            animation: cardsVisible
                                ? `appearTextFromBottom 0.6s ease-in-out forwards`
                                : undefined
                        }}
                    >
                        <CardCarousel
                            justifyCenter
                            cards={useCases.filter(
                                (useCase) =>
                                    useCase.industry.toLowerCase() ===
                                    params.industry.toLowerCase()
                            )}
                            onClick={handleClickUseCase}
                            onClickText={t(`home.card.learnMore`)}
                        />
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col px-0">
                    <hr
                        style={{
                            color: '#f0f0f0',
                            opacity: '1'
                        }}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                ref={demoRef}
                style={{
                    maxWidth: `128rem`,
                    padding: '5rem 2rem'
                }}
            >
                <div
                    className={
                        isWidthGreaterEqualThan800
                            ? 'col px-0 d-flex flex-column justify-content-start align-items-center'
                            : 'col-12 px-0 d-flex flex-column justify-content-start align-items-center'
                    }
                    style={{
                        marginBottom: isWidthGreaterEqualThan800 ? 0 : '5rem',
                        opacity: 0,
                        animation: demoVisible
                            ? `appearTextFromLeft 0.6s ease-in-out forwards`
                            : undefined
                    }}
                >
                    <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['contact-us-container__subTitle']}`}
                            >
                                {t('contactUs.title1')}
                            </span>
                            <span
                                className={`${styles['contact-us-container__subTitle']}`}
                                style={{
                                    color: '#ff8900'
                                }}
                            >
                                {t('contactUs.title2')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            marginBottom: '2rem'
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-start align-items-center">
                            <span
                                className={`${styles['contact-us-container__industry-text']}`}
                            >
                                {t('contactUs.subTitle')}
                            </span>
                        </div>
                    </div>

                    <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['contact-us-container__description']}`}
                            >
                                {t('contactUs.description1')}
                            </span>
                            <span
                                className={`${styles['contact-us-container__description']}`}
                                style={{
                                    color: '#2c3c8d',
                                    fontFamily: 'VartaBold'
                                }}
                            >
                                {t('contactUs.description2')}
                            </span>
                            <span
                                className={`${styles['contact-us-container__description']}`}
                            >
                                {t('contactUs.description3')}
                            </span>
                            <span
                                className={`${styles['contact-us-container__description']}`}
                                style={{
                                    color: '#2c3c8d',
                                    fontFamily: 'VartaBold'
                                }}
                            >
                                {t('contactUs.description4')}
                            </span>
                            <span
                                className={`${styles['contact-us-container__description']}`}
                            >
                                {t('contactUs.description5')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        <div className="col px-0">
                            <img
                                loading="lazy"
                                src={imageMockupDemo}
                                style={{
                                    width: '100%',
                                    transform: 'scale(1.4)'
                                }}
                            />
                        </div>
                    </div>
                </div>
                {isWidthGreaterEqualThan800 && (
                    <div
                        className="col-auto px-0"
                        style={{ width: '5rem' }}
                    ></div>
                )}
                <div
                    className="col px-0"
                    style={{
                        opacity: 0,
                        animation: demoVisible
                            ? `appearTextFromRight 0.6s ease-in-out forwards`
                            : undefined
                    }}
                >
                    <MailchimpSubscribe
                        url={
                            'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                        }
                        render={({ subscribe }) => (
                            <>
                                {isContacted && (
                                    <>
                                        <div
                                            className="col-auto px-0 d-flex"
                                            style={{ marginBottom: '4rem' }}
                                        >
                                            <span
                                                className={`${styles['industry-container__thank-you']}`}
                                            >
                                                {t('home.subscribe.subTitle5')}
                                            </span>
                                        </div>
                                    </>
                                )}
                                {!isContacted && (
                                    <>
                                        {showWarning && (
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    maxWidth: '50rem',
                                                    marginBottom: '1rem'
                                                }}
                                            >
                                                <div className="col px-0 d-flex">
                                                    <span
                                                        className={`${styles['industry-container__warning']}`}
                                                    >
                                                        {showWarning}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <TypeInput
                                                    name={'firstName'}
                                                    label={t(
                                                        'bookingModal.inputs.firstName'
                                                    )}
                                                    isRequired={true}
                                                    value={firstName}
                                                    onChangeInput={setFirstName}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <TypeInput
                                                    name={'lastName'}
                                                    label={t(
                                                        'bookingModal.inputs.lastName'
                                                    )}
                                                    isRequired={true}
                                                    value={lastName}
                                                    onChangeInput={setLastName}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <TypeInput
                                                    name={'email'}
                                                    label={t(
                                                        'bookingModal.inputs.email'
                                                    )}
                                                    isRequired={true}
                                                    value={email}
                                                    onChangeInput={setEmail}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <SelectInput
                                                    label={t(
                                                        'bookingModal.inputs.industry'
                                                    )}
                                                    name={'industry'}
                                                    onChangeInput={(value) =>
                                                        setSelectedValue(value)
                                                    }
                                                    options={[
                                                        'Banking',
                                                        'Insurance',
                                                        'Healthcare',
                                                        'Retail',
                                                        'Manufacturing',
                                                        'Other'
                                                    ]}
                                                    value={selectedValue}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem',
                                                height: '20rem'
                                            }}
                                        >
                                            <div className="col px-0">
                                                <TypeInput
                                                    name={'details'}
                                                    label={t(
                                                        'bookingModal.inputs.details'
                                                    )}
                                                    isRequired={true}
                                                    value={details}
                                                    inputType={'container'}
                                                    onChangeInput={setDetails}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex align-items-start"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginTop: '0.4rem',
                                                    marginRight: '1rem'
                                                }}
                                            >
                                                <img
                                                    loading="lazy"
                                                    className={
                                                        styles[
                                                            'industry-container__checkbox'
                                                        ]
                                                    }
                                                    src={
                                                        isCheckboxSelected
                                                            ? icoCheckboxSelected
                                                            : icoCheckboxEmpty
                                                    }
                                                    onClick={() =>
                                                        setIsCheckboxSelected(
                                                            !isCheckboxSelected
                                                        )
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['industry-container__terms']}`}
                                                        >
                                                            {t(
                                                                'bookingModal.terms1'
                                                            )}
                                                        </span>
                                                        <span
                                                            className={`${styles['industry-container__terms']}`}
                                                            style={{
                                                                color: 'rgba(6, 164, 231, 1)',
                                                                cursor: 'pointer',
                                                                textDecoration:
                                                                    'underline'
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    privacyPolicy,
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            {t(
                                                                'bookingModal.terms2'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        {/* <span
                                        className={`${styles['industry-container__terms']}`}
                                    >
                                        {t('bookingModal.terms3')}
                                    </span> */}
                                                        {/* <span
                                        className={`${styles['industry-container__terms']}`}
                                        style={{
                                            color: 'rgba(6, 164, 231, 1)',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            window.open(privacyPolicy, '_blank')
                                        }}
                                    >
                                        {t('bookingModal.terms4')}
                                    </span>
                                    <span
                                        className={`${styles['industry-container__terms']}`}
                                    >
                                        {t('bookingModal.terms5')}
                                    </span>
                                    <span
                                        className={`${styles['industry-container__terms']}`}
                                        style={{
                                            color: 'rgba(6, 164, 231, 1)',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            window.open(privacyPolicy, '_blank')
                                        }}
                                    >
                                        {t('bookingModal.terms6')}
                                    </span>
                                    <span
                                        className={`${styles['industry-container__terms']}`}
                                    >
                                        {t('bookingModal.terms7')}
                                    </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex align-items-start"
                                            style={{
                                                marginBottom: '2rem'
                                            }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginTop: '0.4rem',
                                                    marginRight: '1rem'
                                                }}
                                            >
                                                <img
                                                    loading="lazy"
                                                    className={
                                                        styles[
                                                            'industry-container__checkbox'
                                                        ]
                                                    }
                                                    src={
                                                        isCheckboxSelectedForMarketing
                                                            ? icoCheckboxSelected
                                                            : icoCheckboxEmpty
                                                    }
                                                    onClick={() =>
                                                        setIsCheckboxSelectedForMarketing(
                                                            !isCheckboxSelectedForMarketing
                                                        )
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['industry-container__terms']}`}
                                                        >
                                                            {t(
                                                                'bookingModal.terms8'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                            <div className="col-auto px-0">
                                                <PrimaryButton
                                                    text={t('button.submit')}
                                                    onClick={() => {
                                                        if (handleContactUs()) {
                                                            subscribe({
                                                                EMAIL: email,
                                                                FNAME: firstName,
                                                                LNAME: lastName,
                                                                MMERGE6:
                                                                    selectedValue,
                                                                MMERGE3:
                                                                    details,
                                                                MMERGE11:
                                                                    'English',
                                                                'group[2089][1]':
                                                                    undefined,
                                                                'group[2089][2]':
                                                                    isCheckboxSelectedForMarketing
                                                                        ? true
                                                                        : undefined,
                                                                'group[2089][4]': true,
                                                                'group[2089][8]':
                                                                    undefined,
                                                                'group[2089][16]':
                                                                    undefined,
                                                                'group[2089][32]':
                                                                    undefined
                                                            });
                                                            handleSendEmail(
                                                                firstName,
                                                                lastName,
                                                                email,
                                                                selectedValue,
                                                                details
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    ></MailchimpSubscribe>
                </div>
            </div>
        </div>
    );
};
