import React, { useState } from 'react'
import styles from './ResponsibleAI.module.scss'
import { useTranslation } from 'react-i18next'

import { useCurrentSize } from '../../../providers/current-size-context'
import { SolutionsCarousel } from '../../common/SolutionsCarousel/SolutionsCarousel'
import dataIntegration from '../../../assets/images/dataIntegration.svg'
import centro from '../../../assets/images/centro.png'
import Accountability from '../../../assets/images/Accountability.png'
import Auditability from '../../../assets/images/Auditability.png'
import Fairness from '../../../assets/images/Fairness.png'
import Privacy from '../../../assets/images/Privacy.png'
import Security from '../../../assets/images/Security.png'
import Traceability from '../../../assets/images/Traceability.png'
import Transparency from '../../../assets/images/Transparency.png'
import imageResponsibleAI from '../../../assets/images/imageResponsibleAI.png'
import algorithms from '../../../assets/images/algorithms.svg'
import technology from '../../../assets/images/technology.svg'
import humanComputerInteraction from '../../../assets/images/humanComputerInteraction.svg'
import operations from '../../../assets/images/operations.svg'
import useOnScreen from '../../../hooks/useOnScreen'
import aiCompliance from '../../../assets/images/aiCompliance.jpg'
import backgroundSection from '../../../assets/images/backgroundSection.svg'
import { HighlightButton } from '../../common/HighlightButton/HighlightButton'
import { RequestGuideModal } from '../../common/RequestGuideModal/RequestGuideModal'
import { MinCardCarousel } from '../../common/MinCardCarousel/MinCardCarousel'
import imageRequestGuide from '../../../assets/images/imageRequestGuide.png'

export const ResponsibleAI = () => {
    const { t } = useTranslation()
    const { isWidthGreaterEqualThan600, isWidthGreaterEqualThan800 } =
        useCurrentSize()
    const consistRef = React.useRef()
    const consistVisible = useOnScreen(consistRef)
    const solutionsText = React.useRef()
    const solutionsTextVisible = useOnScreen(solutionsText)
    const aiComplianceRef = React.useRef()
    const aiComplianceVisible = useOnScreen(aiComplianceRef)
    const [isRequestActive, setIsRequestActive] = useState(false)

    const [solutions] = React.useState([
        {
            image: dataIntegration,
            title: 'Quality Data',
            description:
                'AI systems are only as good as the data they are trained on. Responsible AI systems use data that is collected and used in an ethical and responsible manner.',
        },
        {
            image: algorithms,
            title: 'Transparent Algorithms',
            description:
                'Algorithms are complex and can be difficult to understand and explain. Responsible AI systems use algorithms that are transparent, accountable, and auditable.',
        },
        {
            image: technology,
            title: 'Flexible Technology',
            description:
                'Technology is constantly evolving. Responsible AI is designed to help systems become flexible and adaptable.',
        },
        {
            image: humanComputerInteraction,
            title: 'Human Centric',
            description:
                'AI needs to be easy and enjoyable for humans to use. Responsible AI considers users’ needs and ensures they can interact with systems safely and effectively and that their human rights are not threatened.',
        },
        {
            image: operations,
            title: 'Continuous Supervision',
            description:
                'Continuous supervision throughout systems’ lifecycle is needed to ensure transparency, explainability, accuracy and safety.',
        },
    ])

    const [cards] = React.useState([
        {
            title: 'Compliance',
            text: 'Data and models are developed and regularly checked for legal and ethical use.',
        },
        {
            title: 'Bias sensitive',
            text: 'To prevent discrimination against any particular group or individual, or to manipulate or deceive people in any way. ',
        },
        {
            title: 'Privacy as default',
            text: 'Strick compliance with Data and individuals privacy.',
        },
    ])

    return (
        <div className={`${styles['responsible-ai-container']}`}>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '0 2rem',
                    paddingBottom: '2rem',
                    paddingTop: 'calc(8rem + 4rem)',
                    maxWidth: '128rem',
                }}
            >
                <div className="col px-0">
                    <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                        <div
                            className="col h-100 d-flex flex-column justify-content-center"
                            style={{
                                background: 'transparent',
                                zIndex: 0,
                            }}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: '2rem',
                                }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['responsible-ai-container__title']}`}
                                        style={{
                                            color: '#2c3c8d',
                                        }}
                                    >
                                        {t(`responsibleAI.what.title1`)}
                                        <span
                                            style={{
                                                color: '#ff8900',
                                            }}
                                        >
                                            {t(`responsibleAI.what.title2`)}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: isWidthGreaterEqualThan800
                                        ? '0'
                                        : '2rem',
                                }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['responsible-ai-container__subTitle']}`}
                                    >
                                        {t(`responsibleAI.what.text1`)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {!isWidthGreaterEqualThan800 && (
                            <div
                                className="col-auto px-0"
                                style={{ width: '5rem' }}
                            ></div>
                        )}
                        <div
                            className={
                                isWidthGreaterEqualThan800
                                    ? 'col-6 px-0 d-flex justify-content-center align-items-center'
                                    : 'col-12 px-0 d-flex justify-content-center align-items-center'
                            }
                        >
                            <img
                                loading="lazy"
                                src={imageResponsibleAI}
                                alt=""
                                style={{
                                    width: '100%',
                                    maxWidth: '50rem',
                                    borderRadius: '0.8rem',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '2rem',
                    maxWidth: '128rem',
                }}
            >
                <div className="col px-0">
                    <span
                        className={`${styles['responsible-ai-container__content']}`}
                    >
                        {t(`responsibleAI.what.text2`)}
                    </span>
                </div>
            </div>

            <div
                className="row mx-0 w-100"
                style={{
                    padding: `4rem 0`,
                }}
            >
                <div className="col px-0 d-flex flex-column align-items-center">
                    <div
                        className="row mx-0 w-100"
                        style={{
                            marginBottom: isWidthGreaterEqualThan800
                                ? '10rem'
                                : isWidthGreaterEqualThan600
                                ? '5rem'
                                : '0rem',
                            padding: '0 2rem',
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                            >
                                {t('responsibleAI.consist.title')}
                            </span>
                        </div>
                    </div>
                    <div
                        ref={consistRef}
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{
                            marginBottom: isWidthGreaterEqualThan800
                                ? '15rem'
                                : isWidthGreaterEqualThan600
                                ? '5rem'
                                : '2rem',
                            maxWidth: '128rem',
                            minWidth: '128rem',
                            transform: isWidthGreaterEqualThan800
                                ? 'scale(1)'
                                : isWidthGreaterEqualThan600
                                ? 'scale(0.75)'
                                : 'scale(0.45)',
                            padding: '0 2rem',
                            height: '35rem',
                            position: 'relative',
                        }}
                    >
                        <div className="col-auto px-0">
                            <div
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    left: '50%',
                                    top: 'calc(50% - 3rem)',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards'
                                        : 'none',
                                }}
                            >
                                <img
                                    loading="lazy"
                                    src={centro}
                                    alt=""
                                    style={{
                                        height: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: '35%',
                                    bottom: '-3rem',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 0.9s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '2.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.accountability`)}
                                </span>
                                <img
                                    loading="lazy"
                                    src={Accountability}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: 'calc(50% + 0.5rem)',
                                    bottom: '-12rem',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 1.2s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '125%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.auditability`)}
                                </span>

                                <img
                                    loading="lazy"
                                    src={Auditability}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: '30%',
                                    top: 'calc(50%)',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 0.6s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '5.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.fairness`)}
                                </span>
                                <img
                                    loading="lazy"
                                    src={Fairness}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: 'calc(65% + 1.5rem)',
                                    top: '3rem',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 2.1s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '-10.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.privacy`)}
                                </span>

                                <img
                                    loading="lazy"
                                    src={Privacy}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: 'calc(70% + 1.5rem)',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 1.8s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '-11.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.security`)}
                                </span>

                                <img
                                    loading="lazy"
                                    src={Security}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: 'calc(65% + 1.5rem)',
                                    bottom: '-3rem',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 1.5s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '-16.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.traceability`)}
                                </span>

                                <img
                                    loading="lazy"
                                    src={Traceability}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '10rem',
                                    left: '35%',
                                    top: '3rem',
                                    transform: 'translate(-50%, -50%)',
                                    opacity: 0,
                                    animation: consistVisible
                                        ? 'appearImage 0.3s ease-in-out forwards 0.3s'
                                        : 'none',
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '2.5rem',
                                        transform: 'translate(-100%, -50%)',
                                    }}
                                    className={
                                        styles[
                                            'responsible-ai-container__title3'
                                        ]
                                    }
                                >
                                    {t(`responsibleAI.consist.transparency`)}
                                </span>
                                <img
                                    loading="lazy"
                                    src={Transparency}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="row mx-0 w-100"
                        style={{
                            padding: '2rem',
                            maxWidth: '128rem',
                            opacity: 0,
                            animation: consistVisible
                                ? 'appearImage 0.3s ease-in-out forwards 2.5s'
                                : 'none',
                        }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                            >
                                {t(`responsibleAI.consist.text1`)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="row mx-0 w-100"
                style={{
                    padding: `5rem 0`,
                }}
            >
                <div className="col px-0 d-flex flex-column align-content-center">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '5rem', padding: '0 2rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                                style={{
                                    color: '#2c3c8d',
                                }}
                            >
                                {t('responsibleAI.focus.title1')}
                                <span
                                    style={{
                                        color: '#ff8900',
                                    }}
                                >
                                    {t('responsibleAI.focus.title2')}
                                </span>
                                {t('responsibleAI.focus.title3')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SolutionsCarousel solutions={solutions} />
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        ref={solutionsText}
                        style={{
                            padding: '2rem',
                            marginBottom: '2rem',
                            opacity: 0,
                            animation: solutionsTextVisible
                                ? 'appearTextFromBottom 0.3s ease-in-out forwards 0.3s'
                                : 'none',
                        }}
                    >
                        <div className="col-auto px-0 justify-content-center">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{
                                    color: '#2c3c8d',
                                    fontFamily: 'RobotoMedium',
                                }}
                            >
                                {t(`responsibleAI.consist.text4`)}
                            </span>
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{
                                    fontFamily: 'RobotoMedium',
                                }}
                            >
                                {t(`responsibleAI.consist.text5`)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: `4rem 2rem`,
                    background: `url(${backgroundSection})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="col px-0 d-flex flex-column align-items-center">
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                                style={{ color: '#fff' }}
                            >
                                {t('responsibleAI.approach.title1')}
                            </span>
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                                style={{ color: '#ff8900' }}
                            >
                                {t('responsibleAI.approach.title2')}
                            </span>
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                                style={{ color: '#fff' }}
                            >
                                {t('responsibleAI.approach.title3')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            padding: '2rem 0',
                            maxWidth: '128rem',
                        }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{ color: '#fff' }}
                            >
                                {t(`responsibleAI.approach.text1`)}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            padding: '2rem 0',
                            maxWidth: '128rem',
                        }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{ color: '#fff' }}
                            >
                                {t(`responsibleAI.approach.text2`)}
                                {t(`responsibleAI.approach.text3`)}
                                {t(`responsibleAI.approach.text4`)}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{
                            padding: '2rem 0',
                            maxWidth: '128rem',
                        }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{ color: '#fff' }}
                            >
                                {t(`responsibleAI.approach.text5`)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: `4rem 2rem`,
                }}
            >
                <div className="col px-0 d-flex flex-column align-items-center">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '5rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                            >
                                {t('responsibleAI.aiCompliance.title')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-start"
                        style={{
                            marginBottom: '2rem',
                            maxWidth: '128rem',
                            padding: '0 2rem',
                        }}
                    >
                        <div
                            className={
                                isWidthGreaterEqualThan800
                                    ? 'col-6 px-0'
                                    : 'col-12 px-0'
                            }
                        >
                            <img
                                loading="lazy"
                                src={aiCompliance}
                                alt=""
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    marginBottom: isWidthGreaterEqualThan800
                                        ? 0
                                        : '2rem',
                                }}
                            />
                        </div>
                        {isWidthGreaterEqualThan600 && (
                            <div
                                className="col-auto px-0"
                                style={{ width: '2rem' }}
                            ></div>
                        )}
                        <div className="col px-0">
                            <div
                                className="row mx-0 w-100"
                                ref={aiComplianceRef}
                                style={{
                                    padding: '2rem 0',
                                    maxWidth: '128rem',
                                    opacity: 0,
                                    animation: aiComplianceVisible
                                        ? 'appearTextFromBottom 0.3s ease-in-out forwards'
                                        : 'none',
                                }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['responsible-ai-container__content']}`}
                                    >
                                        {t(`responsibleAI.aiCompliance.text1`)}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    padding: '2rem 0',
                                    maxWidth: '128rem',
                                    opacity: 0,
                                    animation: aiComplianceVisible
                                        ? 'appearTextFromBottom 0.3s ease-in-out forwards 0.3s'
                                        : 'none',
                                }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['responsible-ai-container__content']}`}
                                    >
                                        {t(`responsibleAI.aiCompliance.text2`)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{
                            padding: '0',
                        }}
                    >
                        <MinCardCarousel cards={cards} />
                    </div>

                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{
                            padding: '2rem 0',
                            maxWidth: '128rem',
                        }}
                    >
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{
                                    color: '#ff8900',
                                    fontFamily: 'RobotoMedium',
                                }}
                            >
                                {t(`responsibleAI.aiCompliance.text4`)}
                            </span>
                            <span
                                className={`${styles['responsible-ai-container__content']}`}
                                style={{
                                    fontFamily: 'RobotoMedium',
                                }}
                            >
                                {t(`responsibleAI.aiCompliance.text5`)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col px-0">
                    <hr
                        style={{
                            marginTop: 0,
                            color: '#f0f0f0',
                            opacity: '1',
                        }}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center"
                style={{
                    padding: `4rem 0`,
                    // background: `url(${backgroundSection})`,
                    // backgroundSize: 'cover',
                    // backgroundPosition: 'center',
                    // backgroundRepeat: 'no-repeat',
                }}
            >
                <div
                    className="col px-0 d-flex flex-column align-items-center"
                    style={{ maxWidth: '128rem' }}
                >
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '2rem', padding: '0 2rem' }}
                    >
                        <div
                            className="col-auto px-0 justify-content-center align-items-center"
                            style={{ textAlign: 'center' }}
                        >
                            <span
                                className={`${styles['responsible-ai-container__title2']}`}
                                style={{ color: '#2c3c8d' }}
                            >
                                {t('responsibleAI.approach.text6')}
                                <span
                                    style={{
                                        color: '#ff8900',
                                    }}
                                >
                                    {t('responsibleAI.approach.text7')}
                                </span>
                                {t('responsibleAI.approach.text8')}
                                <span
                                    style={{
                                        color: '#ff8900',
                                    }}
                                >
                                    {t('responsibleAI.approach.text9')}
                                </span>
                                {t('responsibleAI.approach.text10')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col-auto">
                            <img
                                loading="lazy"
                                src={imageRequestGuide}
                                alt=""
                                style={{
                                    width: '100%',
                                    maxWidth: '50rem',
                                    borderRadius: '8px',
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{
                            padding: '0 2rem',
                        }}
                    >
                        <div className="col-auto px-0 d-flex">
                            <HighlightButton
                                text={t('button.requestGuide')}
                                onClick={() => {
                                    setIsRequestActive(true)
                                }}
                            />
                        </div>
                    </div>
                    {isRequestActive && (
                        <RequestGuideModal
                            onCloseModal={() => setIsRequestActive(false)}
                        ></RequestGuideModal>
                    )}
                </div>
            </div>
        </div>
    )
}
