import React from 'react'
import styles from './CardResponsibleAI.module.scss'

export const CardResponsibleAI = ({ title, text }) => {
    return (
        <div className={`${styles['card-responsible-ai-container']}`}>
            <div
                className="row mx-0 w-100"
                style={{ height: '60%', padding: '2rem 0' }}
            >
                <div className="col px-0">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-start align-items-center">
                            <span
                                className={`${styles['card-responsible-ai-container__title']}`}
                            >
                                {title || '-'}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-start align-items-center">
                            <span
                                className={`${styles['card-responsible-ai-container__subTitle']}`}
                            >
                                {text || '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
