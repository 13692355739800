export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const joiErrorHandler = (schema, value) => {
    const result = schema.validate(value, { abortEarly: false })
    const errorDetails = {}
    if (result.error) {
        result.error.details.map((el) => {
            errorDetails[el.path[0]] = el.type
            return null
        })
        return errorDetails
    }
    return false
}

export const joiPasswordErrorHandler = (schema, value) => {
    const result = schema.validate(value)
    const errorDetails = {}
    if (result.error) {
        result.error.details.map((el) => {
            errorDetails['password'] = el.type
            return null
        })
        return errorDetails
    }
    return false
}
