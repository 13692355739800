import imagePegasusCase1Big from '../assets/images/imagePegasusSegmentation.jpg'
import imagePegasusCase2Big from '../assets/images/imagePegasusRetention.jpg'
import imagePegasusCase3Big from '../assets/images/imagePegasusSentiment.jpg'
import imageApolloCase1Big from '../assets/images/imageApolloForecast.jpg'
import imageApolloCase2Big from '../assets/images/imageApolloSegmentation.jpg'
import imageApolloCase3Big from '../assets/images/imageApolloTeamManagement.jpg'
import imageMercuryCase1Big from '../assets/images/imageMercuryLifecycle.jpg'
import imageMercuryCase2Big from '../assets/images/imageMercuryResources.jpg'
import imageMercuryCase3Big from '../assets/images/imageMercuryMedication.jpg'
import imagePegasusCase1 from '../assets/images/imagePegasusSegmentationSmall.jpg'
import imagePegasusCase2 from '../assets/images/imagePegasusRetentionSmall.jpg'
import imagePegasusCase3 from '../assets/images/imagePegasusSentimentSmall.jpg'
import imageApolloCase1 from '../assets/images/imageApolloForecastSmall.jpg'
import imageApolloCase2 from '../assets/images/imageApolloSegmentationSmall.jpg'
import imageApolloCase3 from '../assets/images/imageApolloTeamManagementSmall.jpg'
import imageMercuryCase1 from '../assets/images/imageMercuryLifecycleSmall.jpg'
import imageMercuryCase2 from '../assets/images/imageMercuryResourcesSmall.jpg'
import imageMercuryCase3 from '../assets/images/imageMercuryMedicationSmall.jpg'
import dataIntegration from '../assets/images/dataIntegration.svg'
import predictiveSalesForecasting from '../assets/images/predictiveSalesForecasting.svg'
import inventoryOptimization from '../assets/images/inventoryOptimization.svg'
import marketingAndPromotionPlanning from '../assets/images/marketingAndPromotionPlanning.svg'
import customerBehaviorAnalysis from '../assets/images/customerBehaviorAnalysis.svg'
import behavioralSegmentation from '../assets/images/behaviouralSegmentation.svg'
import personalizedProductAndServiceRecommendations from '../assets/images/personalizedProductAndServiceRecommendations.svg'
import salesTeamEmpowerment from '../assets/images/salesTeamEmpowerment.svg'
import personalizedSalesStrategies from '../assets/images/personalizedSalesStrategies.svg'
import demandPrediction from '../assets/images/demandPrediction.svg'
import resourceAllocation from '../assets/images/resourceAllocation.svg'
import emergencyResponsePlanning from '../assets/images/emergencyResponsePlanning.svg'
import medicationUsagePatterns from '../assets/images/medicationUsagePatterns.svg'
import medicationUsageDataCollection from '../assets/images/medicationUsageDataCollection.svg'
import preventingMedicationShortages from '../assets/images/preventingMedicationShortage.svg'
import medicationDistributionEfficiency from '../assets/images/medicalDistributionEfficiency.svg'
import customerDataIntegration from '../assets/images/customerDataIntegration.svg'
import customerSegmentation from '../assets/images/customerSegmentation.svg'
import churnPrediction from '../assets/images/churnPrediction.svg'
import personalizedFinancialGuidance from '../assets/images/personalizedFinancialGuidance.svg'
import feedbackAnalysisAndCompliance from '../assets/images/feedbackAnalysisAndCompliance.svg'
import sentimentAnalysis from '../assets/images/sentimentAnalysis.svg'
import identifyingPainPoints from '../assets/images/identifyingPainpoints.svg'
import rootCauseAnalysis from '../assets/images/rootCauseAnalysis.svg'
import continuousImprovement from '../assets/images/continuousImprovement.svg'
import patientLifecycleStages from '../assets/images/patientLifecycleStages.svg'
import personalizedCarePlans from '../assets/images/personalizedCarePlans.svg'
import feedbackAndQualityImprovement from '../assets/images/feedbackAndQualityImprovement.svg'
import predictiveInsights from '../assets/images/predictiveInsights.svg'
import personalizeOfferingRecommendations from '../assets/images/personalizeOfferingRecommendation.svg'

export const useCasesAll = [
    {
        title: 'Sales Forecasting in Retail',
        id: 'sales-forecasting-retail',
        industry: 'starkdata-apollo-retail',
        description:
            'A rapidly growing e-commerce company faces the challenge of efficiently managing inventory, optimizing supply chains, and meeting customer demand. In order to optimize operations and proactively anticipate customer demand, they deploy Starkdata’s Apollo Sales Forecasting feature.',
        image: imageApolloCase1,
        imageBig: imageApolloCase1Big,
        solutions: [
            {
                image: dataIntegration,
                title: 'Data Integration',
                description:
                    'Starkdata’s Apollo platform seamlessly integrates with the company’s data sources which include e-commerce platforms, sales data, customer behavior data, website analytics, and inventory databases.',
            },
            {
                image: predictiveSalesForecasting,
                title: 'Predictive Sales Forecast',
                description:
                    'The platform employs advanced Machine Learning and predictive analytics algorithms to forecast product demand across various sales channels, considering historical sales data, seasonal patterns, marketing campaigns, product attributes, and customer behavior.',
            },
            {
                image: inventoryOptimization,
                title: 'Inventory Optimization',
                description:
                    'The predictive insights from sales forecasting help the company optimize inventory management, maintaining the right stock levels for each product, reducing overstocking, and minimizing the risk of stockouts.',
            },
            {
                image: marketingAndPromotionPlanning,
                title: 'Marketing Planning',
                description:
                    'The company uses sales forecasting insights to plan marketing campaigns and promotions more effectively, aligning initiatives with expected demand.',
            },
        ],
        results: [
            'Enhanced demand prediction',
            'Improved marketing ROI',
            'Increased revenue',
        ],
    },
    {
        title: 'Using Behavioral Segmentation to Identify Target Markets for Retail',
        id: 'behavioral-segmentation-retail',

        industry: 'starkdata-apollo-retail',
        description:
            'A well-established retail chain aims to enhance customer engagement and retention by gaining a deeper understanding of its vast customer base. In order to achieve this, they implement Starkdata’s Apollo platform, which enables the company to get a better understanding of their customers, identify segments, and link behavior to product.',
        image: imageApolloCase2,
        imageBig: imageApolloCase2Big,
        solutions: [
            {
                image: dataIntegration,
                title: 'Data Integration',
                description:
                    'The platform integrates with the company’s extensive data sources and touchpoints, such as in-store and online purchase data, website traffic, customer interactions, and loyalty program data.',
            },
            {
                image: customerBehaviorAnalysis,
                title: 'Customer Behavior Analysis',
                description:
                    'Starkdata’s Apollo platform identifies patterns, preferences, and trends, allowing the retailer to understand how customers interact with their retail chain.',
            },
            {
                image: behavioralSegmentation,
                title: 'Behavioral Segmentation',
                description:
                    'The platform segments customers into distinct groups based on their behaviors. These segments may include Frequent Shoppers, Budget-Conscious Buyers, Occasional Shoppers, and Brand Loyalists.',
            },
            {
                image: personalizedProductAndServiceRecommendations,
                title: 'Personalized Recommendations',
                description:
                    'For each behavioral segment, the retailer tailors product recommendations and service offerings. Budget-Conscious Buyers may receive targeted promotions for cost-effective product options while Brand Loyalists may receive exclusive previews of new brand-related products.',
            },
        ],
        results: [
            'Deeper customer understanding',
            'Increased customer retention',
            'Efficient resource allocation',
        ],
    },
    {
        title: 'Sales Team Empowerment With AI-Powered Management',
        id: 'sales-team-management',
        industry: 'starkdata-apollo-retail',
        description:
            'A prominent retail chain is dedicated to elevating its sales team’s performance and optimizing revenue generation. In order to achieve this goal, they use Starkdata’s Apollo Sales Team Management feature to empower its sales teams, enhance cross-selling and upselling as well as boost operational efficiency.',
        image: imageApolloCase3,
        imageBig: imageApolloCase3Big,
        solutions: [
            {
                image: predictiveSalesForecasting,
                title: 'Predictive Analytics',
                description:
                    'The platform uses predictive analytics to anticipate needs, suggesting cross-selling and upselling opportunities based on customer profiles, purchase history, and current shopping behaviors.',
            },
            {
                image: personalizedSalesStrategies,
                title: 'Personalized Sales Strategies',
                description:
                    'The retailer’s sales team can tailor their interaction and recommendations to each customer. For instance, if a customer typically purchases workout clothing, the platform might prompt the automatic suggestion on the website or to the salesperson to recommend complementary items like running shoes or fitness accessories.',
            },
            {
                image: salesTeamEmpowerment,
                title: 'Sales Team Empowerment',
                description:
                    'The predictive insights from sales forecasting help the company optimize inventory management, maintaining the right stock levels for each product, reducing overstocking, and minimizing the risk of stockouts.',
            },
            {
                image: marketingAndPromotionPlanning,
                title: 'Marketing Planning',
                description:
                    'Starkdata’s Apollo platform equips sales teams with valuable insights, providing instant access to customer behavior, preferences, and purchase history. Now, sales representatives have an immediate 360º view of each customer as they interact.',
            },
        ],
        results: [
            'Enhanced cross-selling and upselling',
            'Improved customer satisfaction',
            'Operational efficiency',
        ],
    },
    {
        title: 'Optimizing Patient Lifecycle Management',
        id: 'patient-lifecycle-management',
        industry: 'starkdata-mercury-healthcare',
        description:
            'A forward-thinking healthcare provider is committed to improving patient experience and ensuring optimal care throughout the entire patient journey.',
        image: imageMercuryCase1,
        imageBig: imageMercuryCase1Big,
        solutions: [
            {
                image: dataIntegration,
                title: 'Data Integration',
                description:
                    'By integrating seamlessly with the hospital’s data sources such as electronic health records, patient demographics, appointment history, and feedback, a hospital gets a 360º view of each patient’s healthcare journey.',
            },
            {
                image: patientLifecycleStages,
                title: 'Patient Lifecycle Stages',
                description:
                    'The platform categorizes patients into various stages of their healthcare journey such as New Patients, Active Patients, Post-Procedure Recovery, and Continuing Care.',
            },
            {
                image: personalizedCarePlans,
                title: 'Personalized Care Plans',
                description:
                    'For each patient stage, the platform generates personalized care plans and recommendations. New Patients can receive welcome kits with information on hospital services, while Post-Procedure Recovery patients get recovery and follow-up guidelines.',
            },
            {
                image: predictiveInsights,
                title: 'Predictive Insights',
                description:
                    'Starkdata’s Mercury platform provides predictive insights into patient behavior based on historical data. It can forecast when an Active Patient may need a follow-up appointment or when Continuing Care Patients might require a specialist consultation.',
            },
            {
                image: feedbackAndQualityImprovement,
                title: 'Feedback and Quality Improvement',
                description:
                    'The hospital incorporates feedback loops within the platform to collect patient input and use this feedback to improve the quality of care and services continually.',
            },
        ],
        results: [
            'Tailored care plans',
            'Allocate resources efficiently',
            'Improve quality',
            "Put patient's care first.",
        ],
    },
    {
        title: 'Optimizing Healthcare Resources Allocation',
        id: 'healthcare-resources-management',
        industry: 'starkdata-mercury-healthcare',
        description:
            'A reputable urban hospital operates in a highly dynamic and resource-intensive environment.',
        image: imageMercuryCase2,
        imageBig: imageMercuryCase2Big,
        solutions: [
            {
                image: demandPrediction,
                title: 'Demand Prediction',
                description:
                    'Through predictive analytics, Starkdata’s Mercury enables the hospital to forecast resource demand accurately, considering different parameters like historical data on patient admissions, seasonal fluctuations, and special events such as flu outbreaks.',
            },
            {
                image: resourceAllocation,
                title: 'Resource Allocation',
                description:
                    'Based on the demand predictions, the platform assists hospital administrators in allocating resources more efficiently, ensuring that there are enough available beds, staff, medical equipment, and supplies to meet expected patient needs.',
            },
            {
                image: emergencyResponsePlanning,
                title: 'Emergency Response Planning',
                description:
                    'In case of an emergency like a natural disaster, Starkdata’s Mercury can be used to provide administrators with contingency plans for resource allocation.',
            },
        ],
        results: [
            'Enhanced resource efficiency',
            'Optimized patient care',
            'Reduced operational cost',
        ],
    },
    {
        title: 'Medications Analytics for Healthcare Management',
        id: 'medication-analytics',
        industry: 'starkdata-mercury-healthcare',
        description:
            'A leading healthcare provider is dedicated to improving patient experience and ensuring optimal care throughout the entire patient journey.',
        image: imageMercuryCase3,
        imageBig: imageMercuryCase3Big,
        solutions: [
            {
                image: medicationUsageDataCollection,
                title: 'Medication Data Collection',
                description:
                    'The platform integrates with the hospital’s electronic health records, pharmacy systems, and medication dispensing logs to collect comprehensive data on medication prescriptions and patient medication histories.',
            },
            {
                image: medicationUsagePatterns,
                title: 'Medication Usage Patterns',
                description:
                    'Through the collected data, the platform identifies medication usage patterns over time, categorizing the data by medication types, patient demographics, and specific medical conditions.',
            },
            {
                image: preventingMedicationShortages,
                title: 'Preventing Medication Shortages',
                description:
                    'By using predictive analytics, the platform helps the hospital proactively prevent medication shortages, forecasting medication demands based on historical trends and aligning it with disease patterns.',
            },
            {
                image: medicationDistributionEfficiency,
                title: 'Medication Distribution Efficiency',
                description:
                    'The insights from medication analytics guide the hospital’s pharmacy and healthcare management teams in optimizing medication distribution. If the data reveals an increasing number of heart-related conditions in a particular age group, the hospital can ensure sufficient stock of cardiovascular medications.',
            },
        ],
        results: [
            'Improved medication distribution',
            'Enhanced patient care',
            'Data-driven healthcare',
            'Shortage prevention',
        ],
    },
    {
        title: 'Customer-Centric Behavioral Segmentation in Banking',
        id: 'behavioral-segmentation-banking',
        industry: 'starkdata-pegasus-banking',
        description:
            'Let’s suppose a progressive bank is committed to providing tailored support and a customer-centric approach to enhance customer experience. The bank leverages Starkdata’s Pegasus behavioral segmentation feature to better understand its customers and provide personalized support throughout their journey.',
        image: imagePegasusCase1,
        imageBig: imagePegasusCase1Big,
        solutions: [
            {
                image: dataIntegration,
                title: 'Data Integration',
                description:
                    'The platform integrates seamlessly with the bank’s data sources encompassing customer transaction history, interactions, visits, customer service records, and even external data like economic trends. This comprehensive dataset provides a 360º view of each customer behavior.',
            },
            {
                image: behavioralSegmentation,
                title: 'Behavioral Segmentation',
                description:
                    'By employing advanced Machine Learning algorithms, the platform segments customers based on their behavior. It groups customers into different segments, each reflecting a unique set of behaviors, needs, and preferences.',
            },
            {
                image: personalizeOfferingRecommendations,
                title: 'Personalize Offering Recommendations',
                description:
                    'For each segment, the platform generates personalized product and service recommendations. For instance, it suggests saving account options with higher interest rates to Savers and investment opportunities to Active Investors based on their risk appetite.',
            },
            {
                image: predictiveInsights,
                title: 'Predictive Insights',
                description:
                    'The platform provides insights into future behavior based on historical data, forecasting when a Saver may be ready to invest or when a Digital Enthusiast might need assistance with a particular online banking feature.',
            },
        ],
        results: [
            'Enhanced customer understanding',
            'Tailored support',
            'Efficient resource allocation',
            'Cross-selling and upselling opportunities',
        ],
    },
    {
        title: 'AI-Driven Customer Retention',
        id: 'customer-retention-banking',
        industry: 'starkdata-pegasus-banking',
        description:
            'In the highly competitive BFSI industry, maintaining and enhancing customer relationships is crucial. Let’s suppose a bank has been facing customer retention issues and is seeking to leverage AI Customer Analytics to improve it.',
        image: imagePegasusCase2,
        imageBig: imagePegasusCase2Big,
        solutions: [
            {
                image: customerDataIntegration,
                title: 'Customer Data Integration',
                description:
                    'Starkdata’s Pegasus seamlessly integrates with the bank’s data sources, including transaction history, interactions, credit card usage, and customer service records - which provides a holistic view of each customer’s financial journey.',
            },
            {
                image: customerSegmentation,
                title: 'Customer Segmentation',
                description:
                    'By using advanced Machine Learning algorithms, the software segments the bank’s customers into categories based on their financial behaviors and needs, such as Saving-Focused, Investment Enthusiasts and Frequent Traders.',
            },
            {
                image: churnPrediction,
                title: 'Churn Prediction',
                description:
                    'The platform then applies predictive analytics to identify customers at risk of churning, considering factors like reduced transaction frequency, declining savings account balances, and decreased engagement.',
            },
            {
                image: personalizedFinancialGuidance,
                title: 'Personalized Financial Guidance',
                description:
                    'Once the customers at risk are identified, the platform generates tailored financial recommendations. For example, if a customer in the Saving-Focused segment shows reduced savings activity, the system provides personalized advice on investment options, helping them meet their financial goals.',
            },
            {
                image: feedbackAnalysisAndCompliance,
                title: 'Feedback Analysis and Compliance',
                description:
                    'The bank uses the platform to gather feedback from churned customers and analyze it to understand the reasons behind their decisions. This not only helps improve customer retention strategies but also ensures compliance with financial regulations by tracking customer interactions and recommendations.',
            },
        ],
        results: [
            'Reduced churn',
            'Improved customer lifetime value',
            'Increased revenue',
            'Enhanced revenue',
            'Better compliance',
        ],
    },
    {
        title: 'AI-Driven Sentiment Analysis',
        id: 'sentiment-analysis-banking',
        industry: 'starkdata-pegasus-banking',
        description:
            'The bank at hand recognizes the importance of understanding customer sentiments to improve their services. By deploying Starkdata’s Pegasus platform, the bank is able to collect a wide range of customer data from various sources including:\n\n- Customer Service Interactions: Phone calls, live chat transcript, and email exchanges with customer service representatives.\n- Social Media Mentions: Monitoring social media platforms for the bank’s name or related keywords.\n- Online Feedback: Analyzing customer feedback submitted through the bank’s online portal.',
        image: imagePegasusCase3,
        imageBig: imagePegasusCase3Big,
        solutions: [
            {
                image: sentimentAnalysis,
                title: 'Sentiment Analysis',
                description:
                    'The platform employs advanced natural language processing (NLP) and Machine Learning algorithms to perform sentiment analysis on the gathered data, categorizing it into three main sentiment categories: positive sentiment, neutral sentiment, and negative sentiment: Interactions that reveal dissatisfaction, frustration, or complaints.',
            },
            {
                image: identifyingPainPoints,
                title: 'Identifying Pain Points',
                description:
                    'The bank is particularly interested in understanding negative sentiments, so the platform identifies keywords, phrases, and patterns in customer interactions that indicate pain points. Common negative keywords may include long wait times, high fees, complex account management, and unresolved issues.',
            },
            {
                image: rootCauseAnalysis,
                title: 'Root Cause Analysis',
                description:
                    'In order to address the pain points effectively, the platform delves deeper into the data to identify the root causes behind negative sentiments, identifying specific areas or processes that lead to customer dissatisfaction. It might discover that a high percentage of negative comments are related to difficulties customers face in accessing online banking services due to a non-intuitive user interface.',
            },
            {
                image: continuousImprovement,
                title: 'Continuous Improvement',
                description:
                    "Empowered with insights, the bank shifts gears towards a proactive approach in addressing customers' pain points such as a website redesign, improved customer service training, and fee structure competitiveness.",
            },
        ],
        results: [
            'Enhancing customer understanding',
            'Improving customer experience',
            'Growing competitive advantage',
        ],
    },
]
