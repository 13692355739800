import React from 'react'
import styles from './SolutionsCarousel.module.scss'
import { Solution } from '../Solution/Solution'
import { useCurrentSize } from '../../../providers/current-size-context'

export const SolutionsCarousel = ({
    solutions,
    maxShowing = 3,
    onClick,
    onClickText,
    noIcon,
}) => {
    function divideArray(array) {
        const elementsPerRow = array.length % 3 === 1 ? 2 : 3
        let result = []

        for (let i = 0; i < array.length; i += elementsPerRow) {
            result.push(array.slice(i, i + elementsPerRow))
        }

        return result
    }

    const { isWidthGreaterEqualThan800 } = useCurrentSize()
    function splitArrayIntoChunks(array, chunkSize) {
        const result = []
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize))
        }
        return result
    }
    const [arrayIntoChunksMemo] = React.useMemo(
        () => [divideArray(solutions)],
        [solutions]
    )
    return (
        <div className={`${styles['solutions-carousel-container']}`}>
            <div
                className="w-100 position-relative"
                style={{
                    marginBottom: '2rem',
                    transition: 'transform 0.5s ease-in-out',
                }}
            >
                {arrayIntoChunksMemo.map((chunk, index) => {
                    return (
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center"
                            key={index}
                        >
                            {chunk.map((card, index2) => {
                                return (
                                    <div
                                        className={
                                            isWidthGreaterEqualThan800
                                                ? 'col-auto px-0 d-flex justify-content-center align-items-center'
                                                : 'col-12 px-0 d-flex justify-content-center align-items-center'
                                        }
                                        key={index2}
                                    >
                                        <div
                                            className={`${styles['solutions-carousel-container__card']}`}
                                            style={{
                                                width: '40rem',
                                                padding: '2rem',
                                            }}
                                        >
                                            <Solution
                                                {...card}
                                                noIcon={noIcon}
                                                delay={
                                                    (index + index2 + 1) * 0 +
                                                    's'
                                                }
                                                onClick={() =>
                                                    onClick(card, index2)
                                                }
                                                onClickText={onClickText}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
