import React, { useState } from 'react';
import styles from './Newsletter.module.scss';
import imageNewsletterNew from '../../../assets/images/imageNewsletterNew3.png';
import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import captionsNewsletter from '../../../assets/videos/captionsNewsletter.vtt';
import newsletterThumbnail from '../../../assets/videos/thumbnailNewsletter.png';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import { useTranslation } from 'react-i18next';
import { TypeInput } from '../../common/TypeInput/TypeInput';
import { SolutionsCarousel } from '../../common/SolutionsCarousel/SolutionsCarousel';
import backgroundSection from '../../../assets/images/backgroundSection.svg';
import useOnScreen from '../../../hooks/useOnScreen';
import { useCurrentSize } from '../../../providers/current-size-context';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import { SubscribeButton } from '../../common/SubscribeButton/SubscribeButton';
import { SelectInput } from '../../common/SelectInput/SelectInput';
import { emailRegex } from '../../../utils/error';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import axios from 'axios';

export const Newsletter = () => {
    const { isWidthGreaterEqualThan600 } = useCurrentSize();
    const { t } = useTranslation();
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isCheckboxSelectedForMarketing, setIsCheckboxSelectedForMarketing] =
        useState(false);
    const [firstName, firstNameSetValue] = useState('');
    const [lastName, lastNameSetValue] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [email, setEmail] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [language, setLanguage] = useState('en');
    const phoneRef = React.useRef();
    const phoneVisible = useOnScreen(phoneRef);
    const [showWarning, setShowWarning] = useState(false);
    const [solutions] = useState([
        {
            title: 'Always free',
            description:
                'A free newsletter packed with valuable insights. Expect it in your inbox by the end of each month!'
        },
        {
            title: 'Ahead of the curve',
            description:
                'Stay ahead of the curve and get the latest insights and updates right to your inbox'
        },
        {
            title: 'Witness innovation',
            description:
                'Get a front-row seat to witness AI’s transformative impact on diverse sectors.'
        }
    ]);
    const [inStore] = useState([
        {
            title: 'Key insights for your industry',
            emoji: '🔑'
        },
        {
            title: 'Latest trends in AI and Customer Analytics',
            emoji: '🚀'
        },
        {
            title: 'Comprehensive AI learning materials',
            emoji: '📈'
        },
        {
            title: 'Resources that will help you harness the power of AI',
            emoji: '🌐'
        }
    ]);

    const handleSubscribe = () => {
        if (!firstName.trim()) {
            setShowWarning('Fill in the first name');
            return false;
        } else if (!lastName.trim()) {
            setShowWarning('Fill in the last name');
            return false;
        } else if (!email.trim()) {
            setShowWarning('Fill in the email');
            return false;
        } else if (!emailRegex.test(email)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!selectedValue.trim()) {
            setShowWarning('Fill in the industry');
            return false;
        } else if (!language.trim()) {
            setShowWarning('Fill in the language');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms');
            return false;
        }
        setIsSubscribed(true);
        setShowWarning(false);
        return true;
    };

    const handleSendEmail = async (
        firstName,
        lastName,
        email,
        selectedValue
    ) => {
        var bodyFormData = new FormData();
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Newsletter',
            text: `Hi, my name is ${firstName} ${lastName}, my email is ${email}, and I am interested in the industry of ${selectedValue}. I just subscribed to the newsletter`
        };
        bodyFormData.append('name', firstName + ' ' + lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
    };

    return (
        <div className={`${styles['newsletter-container']}`}>
            <div
                className="row mx-0 w-100"
                style={{
                    padding: '2rem 2rem',
                    paddingTop: 'calc(8rem + 4rem)',
                    paddingBottom: '8rem',
                    maxWidth: '128rem'
                }}
            >
                {isWidthGreaterEqualThan600 && (
                    <div className="col px-0">
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '3rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['newsletter-container__title']}`}
                                >
                                    {t('newsletter.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <MailchimpSubscribe
                                    url={
                                        'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                                    }
                                    render={({ subscribe, status }) => (
                                        <>
                                            {isSubscribed && (
                                                <>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            maxWidth: '50rem',
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col px-0 d-flex">
                                                            <span
                                                                className={`${styles['newsletter-container__thank-you']}`}
                                                            >
                                                                {t(
                                                                    'home.subscribe.subTitle4'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {!isSubscribed && (
                                                <>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['newsletter-container__subTitle']}`}
                                                            >
                                                                {t(
                                                                    'newsletter.subTitle'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {showWarning && (
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                maxWidth:
                                                                    '50rem',

                                                                marginBottom:
                                                                    '1rem'
                                                            }}
                                                        >
                                                            <div className="col px-0 d-flex">
                                                                <span
                                                                    className={`${styles['newsletter-container__warning']}`}
                                                                >
                                                                    {
                                                                        showWarning
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                label={t(
                                                                    'bookingModal.inputs.firstName'
                                                                )}
                                                                name={
                                                                    'firstName'
                                                                }
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={
                                                                    firstName
                                                                }
                                                                onChangeInput={
                                                                    firstNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={
                                                                    'lastName'
                                                                }
                                                                label={t(
                                                                    'bookingModal.inputs.lastName'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={lastName}
                                                                onChangeInput={
                                                                    lastNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col px-0"
                                                            style={{
                                                                width: '30rem'
                                                            }}
                                                        >
                                                            <SelectInput
                                                                label={t(
                                                                    'bookingModal.inputs.industry'
                                                                )}
                                                                name={
                                                                    'industry'
                                                                }
                                                                onChangeInput={(
                                                                    value
                                                                ) =>
                                                                    setSelectedValue(
                                                                        value
                                                                    )
                                                                }
                                                                options={[
                                                                    'Banking',
                                                                    'Insurance',
                                                                    'Healthcare',
                                                                    'Retail',
                                                                    'Manufacturing',
                                                                    'Other'
                                                                ]}
                                                                value={
                                                                    selectedValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col px-0"
                                                            style={{
                                                                width: '30rem'
                                                            }}
                                                        >
                                                            <SelectInput
                                                                label={t(
                                                                    'bookingModal.inputs.language'
                                                                )}
                                                                name={
                                                                    'language'
                                                                }
                                                                onChangeInput={(
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        value ===
                                                                        'English'
                                                                    ) {
                                                                        setLanguage(
                                                                            'en'
                                                                        );
                                                                    } else if (
                                                                        value ===
                                                                        'Portuguese'
                                                                    ) {
                                                                        setLanguage(
                                                                            'pt'
                                                                        );
                                                                    }
                                                                }}
                                                                options={[
                                                                    'English',
                                                                    'Portuguese'
                                                                ]}
                                                                value={
                                                                    language ===
                                                                    'en'
                                                                        ? 'English'
                                                                        : 'Portuguese'
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={'email'}
                                                                label={t(
                                                                    'bookingModal.inputs.email'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={email}
                                                                onChangeInput={
                                                                    setEmail
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 justify-content-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 justify-content-center align-items-center">
                                                            <span
                                                                className={`${styles['newsletter-container__subscribeSubSubTitle']}`}
                                                            >
                                                                {t(
                                                                    'home.subscribe.subSubTitle'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'newsletter-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelected
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelected(
                                                                        !isCheckboxSelected
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms1'
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                        style={{
                                                                            color: 'rgba(6, 164, 231, 1)',
                                                                            cursor: 'pointer',
                                                                            textDecoration:
                                                                                'underline'
                                                                        }}
                                                                        onClick={() => {
                                                                            window.open(
                                                                                privacyPolicy,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms2'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'newsletter-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelectedForMarketing
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelectedForMarketing(
                                                                        !isCheckboxSelectedForMarketing
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms8'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mx-0 w-100">
                                                        <div className="col-auto px-0">
                                                            <SubscribeButton
                                                                text={t(
                                                                    'button.subscribe'
                                                                )}
                                                                onClick={() => {
                                                                    if (
                                                                        handleSubscribe()
                                                                    ) {
                                                                        subscribe(
                                                                            {
                                                                                EMAIL: email,
                                                                                FNAME: firstName,
                                                                                LNAME: lastName,
                                                                                //industry
                                                                                MMERGE6:
                                                                                    selectedValue,
                                                                                //details
                                                                                MMERGE3:
                                                                                    undefined,
                                                                                MMERGE11:
                                                                                    language ===
                                                                                    'pt'
                                                                                        ? 'Portuguese'
                                                                                        : 'English',
                                                                                //newsletter
                                                                                'group[2089][1]': true,
                                                                                //marketing
                                                                                'group[2089][2]':
                                                                                    isCheckboxSelectedForMarketing
                                                                                        ? true
                                                                                        : undefined,
                                                                                //contact us
                                                                                'group[2089][4]':
                                                                                    undefined,
                                                                                //freebies
                                                                                'group[2089][8]':
                                                                                    undefined,
                                                                                //freebies de join email list
                                                                                'group[2089][16]':
                                                                                    undefined,
                                                                                'group[2089][32]':
                                                                                    undefined
                                                                            }
                                                                        );
                                                                        handleSendEmail(
                                                                            firstName,
                                                                            lastName,
                                                                            email,
                                                                            selectedValue
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '10rem' }}
                            ></div>
                            <div className={'col-7 px-0'}>
                                <VideoPlayer
                                    src={
                                        'https://www.youtube.com/embed/THFoQ7FQyLw'
                                    }
                                    captions={[
                                        {
                                            src: captionsNewsletter,
                                            label: 'English'
                                        }
                                    ]}
                                    thumbnail={newsletterThumbnail}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!isWidthGreaterEqualThan600 && (
                    <div className="col px-0">
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['newsletter-container__title']}`}
                                        >
                                            {t('newsletter.title')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div
                                className={'col px-0'}
                                style={{
                                    margin: '4rem 0',
                                    marginBottom: '6rem'
                                }}
                            >
                                <VideoPlayer
                                    src={
                                        'https://www.youtube.com/embed/THFoQ7FQyLw'
                                    }
                                    captions={[
                                        {
                                            src: captionsNewsletter,
                                            label: 'English'
                                        }
                                    ]}
                                    thumbnail={newsletterThumbnail}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['newsletter-container__subTitle']}`}
                                        >
                                            {t('newsletter.subTitle')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <MailchimpSubscribe
                                    url={
                                        'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                                    }
                                    render={({ subscribe, status }) => (
                                        <>
                                            {isSubscribed && (
                                                <>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            maxWidth: '50rem',
                                                            marginBottom: '1rem'
                                                        }}
                                                    >
                                                        <div className="col px-0 d-flex">
                                                            <span
                                                                className={`${styles['newsletter-container__thank-you']}`}
                                                            >
                                                                {t(
                                                                    'home.subscribe.subTitle4'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {!isSubscribed && (
                                                <>
                                                    {showWarning && (
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                maxWidth:
                                                                    '50rem',

                                                                marginBottom:
                                                                    '1rem'
                                                            }}
                                                        >
                                                            <div className="col px-0 d-flex">
                                                                <span
                                                                    className={`${styles['newsletter-container__warning']}`}
                                                                >
                                                                    {
                                                                        showWarning
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={
                                                                    'firstName'
                                                                }
                                                                label={t(
                                                                    'bookingModal.inputs.firstName'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={
                                                                    firstName
                                                                }
                                                                onChangeInput={
                                                                    firstNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={
                                                                    'lastName'
                                                                }
                                                                label={t(
                                                                    'bookingModal.inputs.lastName'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={lastName}
                                                                onChangeInput={
                                                                    lastNameSetValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col px-0"
                                                            style={{
                                                                width: '30rem'
                                                            }}
                                                        >
                                                            <SelectInput
                                                                label={t(
                                                                    'bookingModal.inputs.industry'
                                                                )}
                                                                name={
                                                                    'industry'
                                                                }
                                                                onChangeInput={(
                                                                    value
                                                                ) =>
                                                                    setSelectedValue(
                                                                        value
                                                                    )
                                                                }
                                                                options={[
                                                                    'Banking',
                                                                    'Insurance',
                                                                    'Healthcare',
                                                                    'Retail',
                                                                    'Manufacturing',
                                                                    'Other'
                                                                ]}
                                                                value={
                                                                    selectedValue
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col px-0"
                                                            style={{
                                                                width: '30rem'
                                                            }}
                                                        >
                                                            <SelectInput
                                                                label={t(
                                                                    'bookingModal.inputs.language'
                                                                )}
                                                                name={
                                                                    'language'
                                                                }
                                                                onChangeInput={(
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        value ===
                                                                        'English'
                                                                    ) {
                                                                        setLanguage(
                                                                            'en'
                                                                        );
                                                                    } else if (
                                                                        value ===
                                                                        'Portuguese'
                                                                    ) {
                                                                        setLanguage(
                                                                            'pt'
                                                                        );
                                                                    }
                                                                }}
                                                                options={[
                                                                    'English',
                                                                    'Portuguese'
                                                                ]}
                                                                value={
                                                                    language ===
                                                                    'en'
                                                                        ? 'English'
                                                                        : 'Portuguese'
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <TypeInput
                                                                name={'email'}
                                                                label={t(
                                                                    'bookingModal.inputs.email'
                                                                )}
                                                                isRequired={
                                                                    true
                                                                }
                                                                value={email}
                                                                onChangeInput={
                                                                    setEmail
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 justify-content-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 justify-content-center align-items-center">
                                                            <span
                                                                className={`${styles['newsletter-container__subscribeSubSubTitle']}`}
                                                            >
                                                                {t(
                                                                    'home.subscribe.subSubTitle'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'newsletter-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelected
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelected(
                                                                        !isCheckboxSelected
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms1'
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                        style={{
                                                                            color: 'rgba(6, 164, 231, 1)',
                                                                            cursor: 'pointer',
                                                                            textDecoration:
                                                                                'underline'
                                                                        }}
                                                                        onClick={() => {
                                                                            window.open(
                                                                                privacyPolicy,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms2'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex align-items-start"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                marginTop:
                                                                    '0.4rem'
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className={
                                                                    styles[
                                                                        'newsletter-container__checkbox'
                                                                    ]
                                                                }
                                                                src={
                                                                    isCheckboxSelectedForMarketing
                                                                        ? icoCheckboxSelected
                                                                        : icoCheckboxEmpty
                                                                }
                                                                onClick={() =>
                                                                    setIsCheckboxSelectedForMarketing(
                                                                        !isCheckboxSelectedForMarketing
                                                                    )
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['newsletter-container__terms']}`}
                                                                    >
                                                                        {t(
                                                                            'bookingModal.terms8'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mx-0 w-100">
                                                        <div className="col-auto px-0">
                                                            <SubscribeButton
                                                                text={t(
                                                                    'button.subscribe'
                                                                )}
                                                                onClick={() => {
                                                                    if (
                                                                        handleSubscribe()
                                                                    ) {
                                                                        subscribe(
                                                                            {
                                                                                EMAIL: email,
                                                                                FNAME: firstName,
                                                                                LNAME: lastName,
                                                                                //industry
                                                                                MMERGE6:
                                                                                    selectedValue,
                                                                                //details
                                                                                MMERGE3:
                                                                                    undefined,
                                                                                //newsletter
                                                                                MMERGE11:
                                                                                    language ===
                                                                                    'pt'
                                                                                        ? 'Portuguese'
                                                                                        : 'English',
                                                                                'group[2089][1]': true,
                                                                                //marketing
                                                                                'group[2089][2]':
                                                                                    isCheckboxSelectedForMarketing
                                                                                        ? true
                                                                                        : undefined,
                                                                                //contact us
                                                                                'group[2089][4]':
                                                                                    undefined,
                                                                                //freebies de rai
                                                                                'group[2089][8]':
                                                                                    undefined,
                                                                                //freebies de join email list
                                                                                'group[2089][16]':
                                                                                    undefined,
                                                                                'group[2089][32]':
                                                                                    undefined
                                                                            }
                                                                        );
                                                                        handleSendEmail(
                                                                            firstName,
                                                                            lastName,
                                                                            email,
                                                                            selectedValue
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col px-0">
                    <hr
                        style={{
                            color: '#f0f0f0',
                            opacity: '1',
                        }}
                    />
                </div>
            </div> */}
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{
                    padding: '10rem 0',
                    paddingBottom: isWidthGreaterEqualThan600
                        ? '10rem'
                        : '10rem',
                    background: `url(${backgroundSection})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="col px-0">
                    <SolutionsCarousel solutions={solutions} noIcon />
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{
                    padding: '0rem 2rem'
                }}
                ref={phoneRef}
            >
                <div className="col px-0" style={{ maxWidth: '128rem' }}>
                    <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                        <div
                            className={
                                isWidthGreaterEqualThan600
                                    ? 'col-8 px-0 h-100 d-flex justify-content-end align-items-end'
                                    : 'col-12 px-0 h-100 d-flex justify-content-center align-items-end'
                            }
                        >
                            <img
                                loading="lazy"
                                src={imageNewsletterNew}
                                alt=""
                                style={{
                                    width: isWidthGreaterEqualThan600
                                        ? '100%'
                                        : '100%',
                                    transform: 'scale(1.2)'
                                }}
                            />
                        </div>
                        <div className="col px-0">
                            <ul
                                style={{
                                    padding: 0,
                                    marginBottom: isWidthGreaterEqualThan600
                                        ? '0'
                                        : '4rem'
                                }}
                            >
                                {inStore.map((el, index) => (
                                    <li
                                        key={index}
                                        style={{
                                            marginBottom: '4rem',
                                            listStyle: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            opacity: 0,
                                            animation: phoneVisible
                                                ? `appearTextFromRight 0.3s ease-in-out forwards ${
                                                      index * 0.3
                                                  }s`
                                                : undefined
                                        }}
                                    >
                                        {/* <div
                                            style={{
                                                width: '1rem',
                                                height: '1rem',
                                                borderRadius: '50%',
                                                backgroundColor: '#06A4E7',
                                                marginRight: '1rem',
                                            }}
                                        ></div> */}
                                        <span
                                            className={`${styles['newsletter-container__inStore']}`}
                                        >
                                            {el.emoji} {el.title}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
