import React, { useCallback } from 'react'
import { has } from 'lodash'
import styles from './CookiesConfigureBanner.module.scss'
import icoAdd from '../../../assets/images/ico-open.svg'
import icoClose from '../../../assets/images/ico-close.svg'
import icoCorrect from '../../../assets/images/icoCorrect.svg'
import cookiePolicy from '../../../assets/documents/cookiePolicy.pdf'
import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { SecondaryButton } from '../SecondaryButton/SecondaryButton'
import { ToggleButton } from '../ToggleButton/ToggleButton'
import Scrollable from '../../navigation/Scrollable'
import { useTranslation } from 'react-i18next'
import { useCurrentSize } from '../../../providers/current-size-context'

export const CookiesConfigureBanner = ({ onAcceptAll, onSavePreferences }) => {
    const { t } = useTranslation()
    const { isWidthGreaterEqualThan800 } = useCurrentSize()
    const [cookieCategories, setCookieCategories] = React.useState([
        {
            key: 'essential',
            isOpen: false,
            isAlwaysActive: true,
            isActive: true,
        },
        {
            key: 'analytics',
            isOpen: false,
            isAlwaysActive: false,
            isActive: false,
        },
        {
            key: 'functional',
            isOpen: false,
            isAlwaysActive: false,
            isActive: false,
        },
        {
            key: 'marketing',
            isOpen: false,
            isAlwaysActive: false,
            isActive: false,
        },
    ])

    const handleToggleCategory = useCallback(
        (index) => {
            const newCookieCategories = [...cookieCategories]
            newCookieCategories[index].isActive =
                !newCookieCategories[index].isActive
            setCookieCategories(newCookieCategories)
        },
        [cookieCategories]
    )

    const handleOpenCategory = useCallback(
        (index) => {
            const newCookieCategories = [...cookieCategories]
            newCookieCategories[index].isOpen =
                !newCookieCategories[index].isOpen
            setCookieCategories(newCookieCategories)
        },
        [cookieCategories]
    )

    return (
        <div
            className={`${styles['cookies-configure-banner-container']}`}
            style={{
                width: isWidthGreaterEqualThan800 ? '50rem' : '100%',
            }}
        >
            <div
                className="row mx-0 w-100"
                style={{
                    height: 'calc(100% - 12rem)',
                }}
            >
                <Scrollable isMainScroll>
                    <div className="col h-100" style={{ padding: '2rem' }}>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['cookies-configure-banner-container__title']}`}
                                >
                                    {t('cookies.title')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['cookies-configure-banner-container__text']}`}
                                >
                                    {t('cookies.summary')}
                                </span>
                                <span
                                    className={`${styles['cookies-configure-banner-container__text']}`}
                                    style={{
                                        color: 'rgb(6, 164, 231)',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() =>
                                        window.open(cookiePolicy, '_blank')
                                    }
                                >
                                    {t('cookies.cookiePolicyText')}
                                </span>
                                .
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t('cookies.acceptAll')}
                                    onClick={() => onAcceptAll()}
                                />
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['cookies-configure-banner-container__title']}`}
                                >
                                    {t('cookies.selectText')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                {cookieCategories.map((category, index) => {
                                    return (
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                            key={index}
                                        >
                                            <div className="col px-0">
                                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                                    <div className="col-auto px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                                <img
                                                                    loading="lazy"
                                                                    src={
                                                                        category.isOpen
                                                                            ? icoClose
                                                                            : icoAdd
                                                                    }
                                                                    className={`${styles['cookies-configure-banner-container__icon']}`}
                                                                    onClick={() => {
                                                                        handleOpenCategory(
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    width: '1rem',
                                                                }}
                                                            ></div>
                                                            <div className="col-auto px-0">
                                                                <span
                                                                    className={`${styles['cookies-configure-banner-container__title']}`}
                                                                >
                                                                    {t(
                                                                        `cookies.${category.key}.title`
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 d-flex justify-content-end align-items-center">
                                                        {has(
                                                            category,
                                                            'isAlwaysActive'
                                                        ) &&
                                                            category.isAlwaysActive && (
                                                                <>
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoCorrect
                                                                        }
                                                                    />
                                                                    <div
                                                                        className="col-auto px-0"
                                                                        style={{
                                                                            width: '1rem',
                                                                        }}
                                                                    ></div>
                                                                    <span
                                                                        className={`${styles['cookies-configure-banner-container__always-active-title']}`}
                                                                    >
                                                                        {t(
                                                                            `cookies.alwaysActive`
                                                                        )}
                                                                    </span>
                                                                </>
                                                            )}
                                                        {(!has(
                                                            category,
                                                            'isAlwaysActive'
                                                        ) ||
                                                            !category.isAlwaysActive) && (
                                                            <ToggleButton
                                                                bool={
                                                                    category.isActive
                                                                }
                                                                index={index}
                                                                onClick={
                                                                    handleToggleCategory
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {category.isOpen && (
                                                    <div className="row mx-0">
                                                        <div className="col-auto px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            category.isOpen
                                                                                ? icoClose
                                                                                : icoAdd
                                                                        }
                                                                        style={{
                                                                            visibility:
                                                                                'hidden',
                                                                        }}
                                                                        className={`${styles['cookies-configure-banner-container__icon']}`}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-auto px-0"
                                                                    style={{
                                                                        width: '1rem',
                                                                    }}
                                                                ></div>
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['cookies-configure-banner-container__description']}`}
                                                                    >
                                                                        {t(
                                                                            `cookies.${category.key}.description`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Scrollable>
            </div>
            <div
                className="row mx-0 w-100"
                style={{ height: '12rem', padding: '4rem 2rem' }}
            >
                <div className="col px-0">
                    <SecondaryButton
                        text={t('cookies.savePreferences')}
                        onClick={() =>
                            onSavePreferences(
                                cookieCategories[0].isActive,
                                cookieCategories[1].isActive,
                                cookieCategories[2].isActive,
                                cookieCategories[3].isActive
                            )
                        }
                    />
                </div>
            </div>
        </div>
    )
}
