import React, { useState } from 'react';
import icoCheckboxEmpty from '../../../assets/images/icoCheckboxEmpty.svg';
import icoCheckboxSelected from '../../../assets/images/icoCheckboxSelected.svg';
import privacyPolicy from '../../../assets/documents/starkdataPrivacyPolicy.pdf';
import icoPortugal from '../../../assets/images/icoPortugal.png';
import icoGreatBritain from '../../../assets/images/icoGreatBritain.png';
import styles from './SubscribeNewsletter.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SubscribeButton } from '../SubscribeButton/SubscribeButton';
import { emailRegex } from '../../../utils/error';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useCurrentSize } from '../../../providers/current-size-context';
import axios from 'axios';
import { useToggle } from '../../../hooks/useToggle';
import icoDropdownArrow from '../../../assets/images/dropdown-arrow.svg';

export const SubscribeNewsletter = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isWidthGreaterEqualThan600 } = useCurrentSize();
    const [openIconDropdown, toggleOpenIconDropdown] = useToggle(false);
    const [languageValue, setLanguageValue] = useState('en');
    const [inputValue, setInputValue] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isCheckboxSelectedForMarketing, setIsCheckboxSelectedForMarketing] =
        useState(false);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubscribe = () => {
        if (!inputValue.trim()) {
            setShowWarning('Fill the email field');
            return false;
        } else if (!emailRegex.test(inputValue)) {
            setShowWarning('Invalid email');
            return false;
        } else if (!isCheckboxSelected) {
            setShowWarning('Accept the terms and conditions');
            return false;
        }
        setShowWarning(false);
        setIsSubscribed(true);
        setTextForButton(t('home.subscribe.action2'));
        return true;
    };

    const [showWarning, setShowWarning] = useState(false);
    const [textForButton, setTextForButton] = useState(
        t('home.subscribe.action')
    );

    const handleSendEmail = async (email) => {
        var bodyFormData = new FormData();
        const msg = {
            to: 'info@starkdata.ai', // Change to your recipient
            from: 'youremail@example.com', // Change to your sender
            form: 'Newsletter Subscription',
            text: `Hi, my email is ${email}, and i just subscribed to the newsletter`
        };
        bodyFormData.append('email', email);
        bodyFormData.append('message', msg.text);
        bodyFormData.append('form', msg.form);
        await axios.post(
            'https://formsubmit.co/ajax/info@starkdata.ai',
            bodyFormData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
    };

    return (
        <div className={`${styles['subscribe-newsletter-container']}`}>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col px-0">
                    <hr
                        style={{
                            marginTop: 0,
                            color: '#f0f0f0',
                            opacity: '1'
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ padding: '5rem 2rem' }}>
                <div
                    className={
                        'col px-0 d-flex flex-column justify-content-center align-items-center'
                    }
                >
                    <div
                        className="row mx-0 w-100 justify-content-center"
                        style={{
                            marginBottom: '3rem'
                        }}
                    >
                        <div
                            className="col-auto px-0 justify-content-center align-items-center"
                            style={{ textAlign: 'center' }}
                        >
                            <span
                                className={`${styles['subscribe-newsletter-container__subscribeSubTitle']}`}
                            >
                                {t('home.subscribe.subTitle1')}
                                <span
                                    style={{
                                        color: '#06a4e7',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        navigate('/ai-talks-newsletter');
                                    }}
                                >
                                    {t('home.subscribe.subTitle2')}
                                </span>
                                <span>{t('home.subscribe.subTitle3')}</span>
                            </span>
                        </div>
                    </div>
                    <MailchimpSubscribe
                        url={
                            'https://starkdata.us21.list-manage.com/subscribe/post?u=871f0742d11c1be9662635b94&amp;id=532c5edff6&amp;f_id=001fe7e6f0'
                        }
                        render={({ subscribe }) => (
                            <>
                                {isSubscribed && (
                                    <>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span
                                                    className={`${styles['subscribe-newsletter-container__thank-you-title']}`}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitleThx3'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-center">
                                            <div
                                                className="col-auto px-0"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <span
                                                    className={`${styles['subscribe-newsletter-container__thank-you']}`}
                                                >
                                                    {t(
                                                        'home.subscribe.subTitle33'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!isSubscribed && (
                                    <>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                maxWidth: '50rem',
                                                padding: '0rem',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            <div className="col px-0">
                                                <div
                                                    className={
                                                        inputValue === ''
                                                            ? `${styles['subscribe-newsletter-container__subscribeInput']} ${styles['subscribe-newsletter-container__subscribeInput--noInput']}`
                                                            : `${styles['subscribe-newsletter-container__subscribeInput']}`
                                                    }
                                                    style={{
                                                        animation: isSubscribed
                                                            ? `${styles['subscribed1']} 0.3s ease-in-out forwards`
                                                            : undefined,
                                                        animationFillMode:
                                                            'forwards'
                                                    }}
                                                >
                                                    <div
                                                        value={languageValue}
                                                        className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input']}`}
                                                        onClick={() =>
                                                            toggleOpenIconDropdown()
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                languageValue ===
                                                                'en'
                                                                    ? icoGreatBritain
                                                                    : icoPortugal
                                                            }
                                                            className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input__icon']}`}
                                                            alt=""
                                                        />
                                                        <img
                                                            src={
                                                                icoDropdownArrow
                                                            }
                                                            className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input__icon-arrow']}`}
                                                            style={{
                                                                transform:
                                                                    openIconDropdown
                                                                        ? 'rotate(180deg)'
                                                                        : undefined
                                                            }}
                                                            alt=""
                                                        />
                                                    </div>
                                                    {
                                                        openIconDropdown && (
                                                            <div
                                                                className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input__dropdown']}`}
                                                            >
                                                                <div
                                                                    className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input__dropdown__icon']}`}
                                                                    onClick={() => {
                                                                        setLanguageValue(
                                                                            'en'
                                                                        );
                                                                        toggleOpenIconDropdown();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            icoGreatBritain
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`${styles['subscribe-newsletter-container__subscribeInput__icon-input__dropdown__icon']}`}
                                                                    onClick={() => {
                                                                        setLanguageValue(
                                                                            'pt'
                                                                        );
                                                                        toggleOpenIconDropdown();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            icoPortugal
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) /* eslint-disable-line */
                                                    }
                                                    <input
                                                        type="text"
                                                        value={inputValue}
                                                        style={{
                                                            pointerEvents:
                                                                isSubscribed
                                                                    ? 'none'
                                                                    : undefined,
                                                            animation:
                                                                isSubscribed
                                                                    ? `${styles['subscribed2']} 0.6s ease-in-out 0.3s forwards`
                                                                    : undefined,
                                                            animationFillMode:
                                                                'forwards'
                                                        }}
                                                        placeholder={t(
                                                            'home.subscribe.emailPlaceholder'
                                                        )}
                                                        className={`${styles['subscribe-newsletter-container__subscribeInput__input']}`}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            width: '20rem',
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <SubscribeButton
                                                            onClick={
                                                                !isSubscribed
                                                                    ? () => {
                                                                          if (
                                                                              handleSubscribe()
                                                                          ) {
                                                                              subscribe(
                                                                                  {
                                                                                      EMAIL: inputValue,
                                                                                      FNAME: undefined,
                                                                                      LNAME: undefined,
                                                                                      MMERGE6:
                                                                                          undefined,
                                                                                      MMERGE3:
                                                                                          undefined,
                                                                                      MMERGE11:
                                                                                          languageValue ===
                                                                                          'en'
                                                                                              ? 'English'
                                                                                              : 'Portuguese',
                                                                                      'group[2089][1]': true,
                                                                                      'group[2089][2]':
                                                                                          isCheckboxSelectedForMarketing
                                                                                              ? true
                                                                                              : undefined,
                                                                                      'group[2089][4]':
                                                                                          undefined,
                                                                                      'group[2089][8]':
                                                                                          undefined,
                                                                                      'group[2089][16]':
                                                                                          undefined,
                                                                                      'group[2089][32]':
                                                                                          undefined
                                                                                  }
                                                                              );
                                                                              handleSendEmail(
                                                                                  inputValue
                                                                              );
                                                                          }
                                                                      }
                                                                    : undefined
                                                            }
                                                            disappearCondition={
                                                                isSubscribed
                                                            }
                                                            text={textForButton}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {showWarning && (
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    maxWidth: '50rem',
                                                    padding: '0rem',
                                                    marginBottom: '1rem'
                                                }}
                                            >
                                                <div className="col px-0 d-flex justify-content-center">
                                                    <span
                                                        className={`${styles['subscribe-newsletter-container__warning']}`}
                                                    >
                                                        {showWarning}
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            className="row mx-0 w-100 justify-content-center"
                                            style={{
                                                marginBottom: '2rem'
                                            }}
                                        >
                                            <div className="col-auto px-0 justify-content-center align-items-center">
                                                <span
                                                    className={`${styles['subscribe-newsletter-container__subscribeSubSubTitle']}`}
                                                >
                                                    {t(
                                                        'home.subscribe.subSubTitle'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 d-flex justify-content-start align-items-center"
                                            style={{
                                                width: isWidthGreaterEqualThan600
                                                    ? '50rem'
                                                    : '100%'
                                            }}
                                        >
                                            <div className="col-auto px-0">
                                                <img
                                                    loading="lazy"
                                                    className={
                                                        styles[
                                                            'subscribe-newsletter-container__checkbox'
                                                        ]
                                                    }
                                                    src={
                                                        isCheckboxSelected
                                                            ? icoCheckboxSelected
                                                            : icoCheckboxEmpty
                                                    }
                                                    onClick={() =>
                                                        setIsCheckboxSelected(
                                                            !isCheckboxSelected
                                                        )
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['subscribe-newsletter-container__terms']}`}
                                                >
                                                    {t('home.terms1')}
                                                </span>
                                                <span
                                                    className={`${styles['subscribe-newsletter-container__terms']}`}
                                                    style={{
                                                        color: 'rgba(6, 164, 231, 1)',
                                                        cursor: 'pointer',
                                                        textDecoration:
                                                            'underline'
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            privacyPolicy,
                                                            '_blank'
                                                        );
                                                    }}
                                                >
                                                    {t('home.terms2')}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 d-flex justify-content-center align-items-center"
                                            style={{
                                                marginTop: '1rem',
                                                width: isWidthGreaterEqualThan600
                                                    ? '50rem'
                                                    : '100%'
                                            }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{ marginTop: '0.4rem' }}
                                            >
                                                <img
                                                    loading="lazy"
                                                    className={
                                                        styles[
                                                            'subscribe-newsletter-container__checkbox'
                                                        ]
                                                    }
                                                    src={
                                                        isCheckboxSelectedForMarketing
                                                            ? icoCheckboxSelected
                                                            : icoCheckboxEmpty
                                                    }
                                                    onClick={() =>
                                                        setIsCheckboxSelectedForMarketing(
                                                            !isCheckboxSelectedForMarketing
                                                        )
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['subscribe-newsletter-container__terms']}`}
                                                        >
                                                            {t(
                                                                'bookingModal.terms8'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    ></MailchimpSubscribe>
                </div>
            </div>
        </div>
    );
};
