import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

// Custom hook to manage cookies
function useCookie(key, initialValue) {
    const [value, setValue] = useState(() => {
        const cookieValue = Cookies.get(key)
        return cookieValue ? cookieValue : initialValue
    })

    useEffect(() => {
        // Set the cookie whenever the value changes
        Cookies.set(key, value, {
            secure: false,
            sameSite: 'strict',
            expires: 365,
        })

        // Clean up
        return () => {
            // Optionally, you can remove the cookie on unmount
            // Cookies.remove(key);
        }
    }, [key, value])

    return [value, setValue]
}

export default useCookie
