import React from 'react'
import styles from './MinCardCarousel.module.scss'
import Scrollable from '../../navigation/Scrollable'
import { CardResponsibleAI } from '../CardResponsibleAI/CardResponsibleAI'

export const MinCardCarousel = ({ cards, justifyCenter }) => {
    return (
        <div className={`${styles['min-card-carousel-container']}`}>
            <Scrollable direction={'horizontal'} isDraggable hideScroll>
                <div className="w-100 h-100 d-flex justify-content-center">
                    <div
                        className={'row d-flex w-100 justify-content-center'}
                        style={{ padding: '2rem 0' }}
                    >
                        {cards.map((card, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`col-auto ${styles['min-card-carousel-container__card']}`}
                                    style={{
                                        minWidth: '35rem',
                                        width: '35rem',
                                        padding: '2rem',
                                    }}
                                >
                                    <CardResponsibleAI
                                        title={card.title}
                                        text={card.text}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Scrollable>
        </div>
    )
}
