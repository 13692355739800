import React from 'react'
import styles from './NumberCard.module.scss'
import useNumberCounter from '../../../hooks/useNumberCounter'
import useOnScreen from '../../../hooks/useOnScreen'
import { useCurrentSize } from '../../../providers/current-size-context'
import { useTranslation } from 'react-i18next'

export const NumberCard = ({ targetNumber, text }) => {
    const { t } = useTranslation()
    const { isWidthGreaterEqualThan600 } = useCurrentSize()
    const numberRef = React.useRef()
    const numberVisible = useOnScreen(numberRef)
    const number = useNumberCounter(targetNumber, numberVisible)
    return (
        <div
            className={
                isWidthGreaterEqualThan600
                    ? `col ${styles['number-card-container']} h-100`
                    : `col-7 ${styles['number-card-container']} d-block`
            }
            style={{
                marginTop: isWidthGreaterEqualThan600 ? '0' : '5rem',
            }}
        >
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span
                        className={`${styles['number-card-container__number']}`}
                        ref={numberRef}
                    >
                        {number}
                    </span>
                    <span
                        className={`${styles['number-card-container__numberPercentage']}`}
                    >
                        %
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span
                        className={`${styles['number-card-container__text']}`}
                    >
                        {text}
                    </span>
                </div>
            </div>
        </div>
    )
}
